import React from "react";

import {config, getUserData,getListTypeMail}  from '../config'
import ReactToolTip from 'react-tooltip'

import Bouton from "../gadgets/bouton/bouton"

import sendmail from "./assets/sendmail.png"

import '../users/styles/styles.css';
import './styles/styles.css';




class TestMail extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = {
            email:"",
        }


        this.mailInput = React.createRef();


        this.SendMail = this.SendMail.bind(this)
    }


    checkvalidemail()
    {
    var cmail = this.mailInput.current.value
    if (!cmail.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) 
    {
        return false;
    }        
    return true;
    }

      SendMail(event)
      {
        if (event.detail.value)
        {
         
            if (!this.checkvalidemail())
            {
                this.mailInput.current.focus();
                return;
            }

            this.writeMail(this.state.email)

            
        }

      }



      writeMail(email)
      {

        var intparam=0;

        var datas =
        {
            id:4, //
            intparam: intparam,
            charparam: email
        }

        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'PUT',
                        headers: myHeaders,
                        mode: 'cors',
                        cache: 'default',
                        body: JSON.stringify(datas)
                        };
  
        var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/4"
        fetch(apiurl,myInit)
        .then((response) => {
            if (!response.ok) {
            throw new Error(
                `This is an HTTP error: The status is ${response.status}`
            );
            }
            
            return response.json();
        })
        .then((actualData) => {
  
            try{

                var datas=
                {
                    email: this.state.email
                }
                this.props.onsend (datas)
                
            }
            catch(e)
            {
                console.log(e)
            }

        })
        .catch((err) => {
            console.log(err);
        });
  
  }




    componentDidMount()
    {
        var myHeaders = new Headers();
    
        var myInit = { method: 'GET',
                        headers: myHeaders,
                        mode: 'cors',
                        cache: 'default' };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/4"
        fetch(apiurl,myInit)
        .then((response) => {
            if (!response.ok) {
            throw new Error(
                `This is an HTTP error: The status is ${response.status}`
            );
            }
            
            return response.json();
        })
        .then((actualData) => {
    
            console.log("Get Param Email 2: ",actualData)


            if (actualData.datas.length > 0)
            {
            
                this.setState(
                    {
                    email: actualData.datas[0].charparam,
                    })
                }
                
            
        })
        .catch((err) => {
            console.log(err);
        });


    
    }



    render ()
    {
        return(
        <>

            
            

            <table width="100%">
                <tr >
                    <td  style={{textAlign:"right"}} width="50%">
                        Envoyer un email de test à :
                    </td>
                    <td>

                    
                        <input className="formListMail-input" 
                        name="mail"
                        ref={this.mailInput}
                        type="text"
                        onChange={e => this.setState({email:e.target.value})}
                        value={this.state.email}
                        required
                        />
                    </td>
                    <td style={{textAlign:"left"}}>
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                    <span data-tip="Envoyer un mail de test">

                    <Bouton 
                            width="40" height="40" top="0"
                            image={sendmail} 
                            disable= {this.state.disable}
                            key = {"valide"}
                            onButtonClick={this.SendMail}
                            buttonName="bsendmail"/>
                    </span>
                </td>
                </tr>

            </table>
        </>
        )
    }

}

export default TestMail
