import React from "react";
import PageTitle from "../gadgets/pagetitle/pagetitle"
import Bouton from "../gadgets/bouton/bouton"
import Pagination from "../gadgets/pagination/pagination";
import SearchUpload from "./searchupload";
import UploadForm from "./uploadform";

import stylo64 from "../users/assets/stylo64.png"
import poubelle from "../users/assets/poubelle.png"


import listeupload from "./assets/cappellaupload.png"
import addupload from "./assets/addupload.png"

import newversion from "./assets/newversion.png"
import oldversion from "./assets/oldversion.png"
import stableversion from "./assets/stableversion.png"
import debugversion from "./assets/debug.png"
import cappellaLogoSmalll from "./assets/cappellaLogoSmalll.png"
import setup from "./assets/setup.png"
import plugin from "./assets/plugin.png"
import doc from "./assets/doc.png"
import complement from "./assets/complement.png"

import {config, getUserData}  from '../config'
import ReactToolTip from 'react-tooltip'



import '../users/styles/styles.css';
import '../licences/styles/styles.css';

//  ****************************************************************************************
//  ListUploadsDatas
//  ****************************************************************************************


class ListUploadDatas extends React.Component{
    constructor(props)
    {
        super(props)
    }


    displayOneChampUpload(iupload,ichamp)
    {
        var champ = this.props.columms[ichamp].zone
        var yellow = "#B0B000";
        if (this.props.disable) yellow = "gray";

        if (champ == "file")
        {
            var retour = [];
            retour.push(this.props.datas[iupload][this.props.columms[ichamp].zone]);
            if (this.props.detail.toString() != "false")
            {
                retour.push(
                    <>
                    <br/>
                    <span style={{color:yellow,fontSize:"10px", fontWeight: "bold"}}>
                        {this.props.datas[iupload].sha256}
                    </span>
                    
                    </>)
            }
            return retour;
        }


        if (champ == "title")
        {
            var retour = [];
            retour.push(this.props.datas[iupload][this.props.columms[ichamp].zone]);
            if (this.props.detail.toString() != "false")
            {
                retour.push(
                    <>
                    <br/>
                    <span style={{color:yellow,fontSize:"10px", fontWeight: "bold"}}>
                        {this.props.datas[iupload].fullpath}
                    </span>
                    
                    </>)
            }
            return retour;
        }



        if (champ == "typefile")
        {
       
            var styleimage="";
            if (this.props.disable) styleimage="imggrayed";

            var retour = [];
            if (this.props.datas[iupload].typefile==0) // 0= exe, 1 doc, 2 complement
            {
                retour.push (
                    <span  data-tip={"Programme d'installation"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={setup} />

                    </span>  )

            }
            if (this.props.datas[iupload].typefile==1)
            {
                retour.push (
                    <span  data-tip={"Documentation"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={doc} />

                    </span>  )
            }

            if (this.props.datas[iupload].typefile==2)
            {
                retour.push (
                    <span  data-tip={"Programme complémentaire"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={complement} />

                    </span>  )
            }
            return retour;
        }


        if (champ == "typesoftware")
        {
       
            var styleimage="";
            if (this.props.disable) styleimage="imggrayed";

            var retour = [];
            if (this.props.datas[iupload].typesoftware==0) // 0= cappellahd, 1 = plugin
            {
                retour.push (
                    <span  data-tip={"Cappella HD"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={cappellaLogoSmalll} />

                    </span>  )

            }
            if (this.props.datas[iupload].typesoftware==1)
            {
                retour.push (
                    <span  data-tip={"Cappella VO Word Plugin"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={plugin} />

                    </span>  )

            }

            return retour;
        }


        if (champ == "status")
        {
       
            var styleimage="";
            if (this.props.disable) styleimage="imggrayed";

            var retour = [];
            if (this.props.datas[iupload].status==0) // 0=old, 1=new, 2=stable, 3 = for debug
            {
                retour.push (
                    <span  data-tip={"Ancienne version"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={oldversion} />

                    </span>  )

            }
            if (this.props.datas[iupload].status==1)
            {
                retour.push (
                    <span  data-tip={"Nouvelle version"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={newversion} />

                    </span>  )

            }

            if (this.props.datas[iupload].status==2)
            {
                retour.push (
                    <span  data-tip={"Version stable"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={stableversion} />

                    </span>  )

            }

            if (this.props.datas[iupload].status==3)
            {
                retour.push (
                    <span  data-tip={"Pour tests"}>
                    <img className={styleimage}
                    width="40" height="40"
                    src={debugversion} />

                    </span>  )

            }

            return retour;
        }


        if (champ == "action")
        {
            var retour = [];
            var datasButtons = 
            {
                upload: iupload,
                idupload: this.props.datas[iupload]["id"],
            }
                
            var datatedit="Modifier le fichier"
            var datatsup="supprimer le fichier";
            if (this.props.disable) 
            {
                datatedit=""
                datatsup=""
            }
            retour.push (
                    <span  data-tip={datatedit}>
                    <Bouton 
                    width="40" height="40"
                    image={stylo64} 
                    disable={this.props.disable}
                    buttonName="bedit"
                    datas={datasButtons}
                    onButtonClick={this.props.onmodify}/>
                    </span>  )

            retour.push(
                        <span  data-tip={datatsup}>
                        <Bouton 
                        width="40" height="40" 
                        image={poubelle} 
                        disable={this.props.disable}
                        onButtonClick={this.props.ondelete}
                        datas={datasButtons}
                        buttonName="bSuppression"/>
                    </span>)
        
            return retour;
        }

        
        
        return (this.props.datas[iupload][this.props.columms[ichamp].zone])
    }

    displayOneUploadList(iupload)
    {
        var retour = []

        

        for (var i=0; i<this.props.columms.length; i++)
        {
            var width = this.props.columms[i].width + "%"
            var key="uploaditem" + iupload+"_"+ i;
            var useritemclass = "userItemListcenter"
            if (iupload & 1)
                var useritemclass = "userItemList2center"

            if (this.props.disable) var useritemclass = "userItemListDisable"
            retour.push(
              
                <td width={width}  className={useritemclass} key={key} >
                        <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    
                    {this.displayOneChampUpload(iupload,i)}
              </td>)
        }
        return ( retour)
            
    }

    displayAllUploadList()
    {



        var retour=[];
        var maxi = this.props.datas.length;
        if (maxi === undefined) maxi=0;
        
        var firstline = this.props.firstline;
        var lastline = this.props.lastline;
        if (firstline<0) firstline=0;
        if (lastline>maxi) lastline=maxi;

        console.log("from", firstline," to ",lastline , maxi);

        for (var i=firstline; i <= lastline;i++)
        
        {
            {
                var key = "uploaditem" + i;
                var retl =  (<tr heightkey={key}>{this.displayOneUploadList(i) } </tr>)
                retour.push(retl)
            }
        }
        return retour;
    }


    render()
    {
        
        return (
            
            <>
            
            {this.displayAllUploadList()}
            </>
            )
    }
}



//  ****************************************************************************************
//  ListUploadHeader
//  ****************************************************************************************
class ListUploadHeader extends React.Component
{
    constructor(props)
    {
        super(props);


    }

createAllHeaderColumns()
{
    var retour = []
    var styleh = "headerList"
    if (this.props.disable)
        styleh = "headerList-disable"
    for (var i=0; i<this.props.columms.length; i++)
    {
    var width = this.props.columms[i].width + "%"
    
    
    var key="uploadheader" + i;
      retour.push(
          
          <td width={width} className={styleh} key={key} >
          
              {this.props.columms[i].text}
          </td>)
    }
    return ( retour)
        
    
}


render()
{
    return (
        <>
                {this.createAllHeaderColumns()}
        </>
    )
}

}



//  ****************************************************************************************
class ListUpload extends React.Component
{
    constructor(props)
    {
        super(props);
        this.columns = [
            {text:"Id", width:4, zone: "id"},
            {text:"Titre", width:28, zone: "title"},
            {text:"Fichier", width:26, zone: "file"},
            {text:"version", width:8, zone: "version"},
            {text:"Status", width:6, zone: "status"},
            {text:"Logiciel", width:6, zone: "typesoftware"},
            {text:"Type", width:6, zone: "typefile"},
            {text:"Date", width:8, zone: "madate"},
            {text:"Actions", width:8, zone: "action"}
        ]

    }

    render()
    {
    
        return (
            <>
                <table className="tableList" cellSpacing={5} cellPadding={0}>
                    
                    
                    <thead>
                    <tr>
                    <ListUploadHeader columms = {this.columns}  disable={this.props.disable}/>          
                    </tr>
                    </thead>
                    <tbody>
                    <ListUploadDatas columms = {this.columns} datas={this.props.liste} 
                        firstline = {this.props.firstline}
                        lastline = {this.props.lastline}
                        disable={this.props.disable}
                        detail = {this.props.detail}
                        onmodify={this.props.onmodify}
                        ondelete={this.props.ondelete}
                    />                    
    
                    </tbody>
                </table>
            </>
            
    
    
        )
    }
    


}
//  ****************************************************************************************



//  ****************************************************************************************
class Upload  extends React.Component 
{


    constructor(props)
    {
        super(props);

        this.state = 
        {
            liste:{},
            nbrows:0,
            nbdisplay: 4,
            currentPage: 1,
            gotolastpage: false,
            nbPerPage: 10,
            listeDisable: false,

            editmode: false,
            createmode: false,
            supmode: false,
            currentUploadRow: 0,
            detail: false,


        }


        this.searchParams=
        {
            title: "",
            status: "1",
            typefile: "1",
            typesoftware:"1",
            tri: "1",
            
        };

        this.onClick = this.onClick.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onChangeDetail = this.onChangeDetail.bind(this);

        this.addUpload = this.addUpload.bind(this)
        this.editUpload = this.editUpload.bind(this);
        this.deleteUpload = this.deleteUpload.bind(this);



        this.cancelForm = this.cancelForm.bind(this);
        this.validForm = this.validForm.bind(this);



    }






    onChangeDetail(value)
    {
        console.log("change detail ", value)
        this.setState({detail: value});
    }
    onChangePage(value)
    {
        
        if (value == "all")
            this.setState({nbPerPage: this.state.liste.length})
        else
            this.setState({nbPerPage: parseInt(value)})


    }

    onSearch(params)
    {

        console.log("Search params", params)
        this.searchParams = params;
        this.getUpload();

    }

    onClick(event)
    {

        if ((event.detail.value) && (event.detail.datas !== undefined))
        {
            var text = event.detail.datas;



            if (this.state.nbrows != 0)
            {
                var nbpages = Math.ceil( this.state.nbrows / this.state.nbPerPage);
                var currentPage = this.state.currentPage;


                switch (text)
                {
                case "next":
                    {
                        if (currentPage==0)
                            currentPage=1;
                        else
                        {
                            if (currentPage < nbpages)
                                currentPage++;
                        }
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "previous":
                    {
                        if (currentPage==0)
                            currentPage=1;
                        else
                        {
                            if (currentPage >1)
                                currentPage--;
                        }
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "first":
                    {
                        currentPage=1;
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "last":
                    {
                        currentPage=nbpages;
                        this.setState({currentPage: currentPage})
                    }
                    break;
                default:
                    currentPage = parseInt(text)
                    this.setState({currentPage: currentPage})
                    break;
                }
            }
        }
    }

    
    
    renderPaneLeft()
    {
        var classdiv="div-licences-search";
        if ((this.state.editmode) || (this.state.supmode) || (this.state.createmode))
        {
            classdiv = "div-licences-search-null";
            return (

                    <div className={classdiv} disabled={this.state.listeDisable}/>
            
            )
        }
        // ****************** Critère de tri des upload
        return(
        <div className={classdiv}>
        <SearchUpload
                    disabled={this.state.listeDisable}
                    onSearch = {this.onSearch}
                    onChangePage = {this.onChangePage}
                    onChangeDetail = {this.onChangeDetail}
                    
                  
                    title = {this.searchParams.title}
                    status = {this.searchParams.status}
                    typefile = {this.searchParams.typefile}
                    typesoftware = {this.searchParams.typesoftware}
                    tri = {this.searchParams.tri}
                            
                    nb = {this.state.nbPerPage}
                    detail = {this.state.detail}


        

                />            
        
        
        </div>
        );
    }



    addUpload(event)
    {
        if (event.detail.value)
        {
            this.setState({
                listeDisable: true,
                editmode: false,
                createmode: true,
                supmode: false,
                currentUploadRow: -1})
        }
    }
    

    editUpload(event)
    {
        if (event.detail.value)
        {
            var indexUpload = event.detail.datas.upload;
            this.setState({
                listeDisable: true,
                editmode: true,
                createmode: false,
                supmode: false,
                currentUploadRow: indexUpload})
        }
    }


    deleteUpload(event)
    {
        if (event.detail.value)
        {
            var indexupload = event.detail.datas.upload;
            var upload = event.detail.datas.id;
            this.setState({
                listeDisable: true,
                editmode: false,
                createmode: false,
                supmode: true,
                currentUploadRow: indexupload})
        }
    }

    cancelForm()
    {
        this.setState({
            listeDisable: false,
            editmode: false,
            createmode: false,
            supmode: false,
            currentUploadRow: 0})
}

    validForm(indexupload,datas)
    {
        console.log("valide form : ",indexupload, datas)

        if (this.state.supmode)
        {
            this.setState(
                {
                listeDisable: false,
                editmode: false,
                createmode: false,
                supmode: false,
                currentUploadRow: 0})
            
            this.supUpload(datas.id)

        }

        if (this.state.editmode)
        {
            this.setState(
                {
                listeDisable: false,
                editmode: false,
                createmode: false,
                supmode: false,
                currentUploadRow: 0})
            
            this.updateUpload(indexupload,datas)
        }


        if (this.state.createmode)
        {
            this.setState(
                {
                listeDisable: false,
                editmode: false,
                createmode: false,
                supmode: false,
                currentUploadRow: 0})
            

            console.log("creation de ", datas)
            this.createUpload(datas)
        }



    }


    renderPaneCenter()
    {
        console.log("render center .............................")

        
        var firstline= ((this.state.currentPage-1) * this.state.nbPerPage) ;
        var lastline=firstline + this.state.nbPerPage-1;
        if (lastline >= this.state.nbrows) lastline = this.state.nbrows -1;

        var classdiv="div-licences-center-all";
        if ((this.state.editmode) || (this.state.supmode) || (this.state.createmode) )
            classdiv = "div-licences-center";

        var overflow={"overflow-y":"scroll"};
        if (this.state.listeDisable) overflow  = {overflow:"hidden"};

        return (
        <div className={classdiv} style={overflow}>

            <ListUpload
                        liste={this.state.liste} 
                        disable={this.state.listeDisable}
                        current = {this.state.currentPage}
                        firstline ={firstline}
                        lastline ={lastline}
                        ondelete = {this.deleteUpload}
                        onmodify={this.editUpload}
                        detail = {this.state.detail}

                        />            

        
        </div>);
    }
    renderPaneRight()
    {
        var classdiv="div-licences-right-null";

        if ((this.state.editmode) ||(this.state.supmode) || (this.state.createmode))
        {
            classdiv = "div-licences-right";
            return (
                <div className={classdiv}>
                    <UploadForm 
                        searchParams = {this.searchParams}
                        uploadindex ={this.state.currentUploadRow}
                        datas = {this.state.liste[this.state.currentUploadRow]}
                        onclose = {this.cancelForm}
                        onvalid = {this.validForm}
                        editmode = {this.state.editmode}
                        supmode = {this.state.supmode}
                        createmode = {this.state.createmode}
                        />
                
                </div>);

        }
        return (
            <div className={classdiv}>
            
            
            </div>);
    }
  
    renderPageListe()
    {
        

        var nbrow = this.state.nbrows;
        if (nbrow === undefined) nbrow = 0;
        var classdiv="div-licences-pagination-all";
        if ((this.state.editmode) || (this.state.supmode) || (this.state.createmode) )
            classdiv = "div-licences-pagination";
        return (
        <div className={classdiv}>
                <Pagination disable = {this.state.listeDisable} current={this.state.currentPage} 
                onClick ={this.onClick} key={this.state.currentPage.toString()} 
                count={this.state.nbrows} nbdisplay={this.state.nbdisplay} nbbypage = {this.state.nbPerPage}/>
        </div>
        )
    }

    componentDidMount()
    {
        this.getUpload();
    }


getUpload()
{
    var datas = this.searchParams;
    console.log("Search upload with ", datas)
    var myHeaders = new Headers();

    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "uploads"
    console.log(apiurl);
    console.log(datas);
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        console.log("Actual datas:", actualData, actualData.datas.length)

        var nbrows = actualData.datas.length;
        

        if (this.state.gotolastpage)
        {

            
            var nbpages = Math.ceil( nbrows / this.state.nbPerPage);
            var currentPage = this.state.currentPage;

            if (this.searchParams.tri == 1) currentPage=1; // tri par date
            if (this.searchParams.tri == 4) currentPage=nbpages; // tri par ID

            console.log("create current page ", nbpages, currentPage)

            this.setState({liste:actualData.datas, 
                nbrows: nbrows, currentPage: currentPage, gotolastpage: false});
        }

        else

            this.setState({liste:actualData.datas, 
                nbrows: nbrows, currentPage:1});
    
    
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });

}

render () 
{

    
    console.log("render.............................")
    var userData = getUserData()
    var priv = userData.user.priv;
    if (((priv & 8) == 0) && (priv & 1)==0)
    {
        this.props.navigate ('/')
        return (null)
    }
    return (
    <div className="div-container">
        <div className="div-entete">
            <div className="div-boutonup">
            <span data-tip="Ajouter un téléchargement">
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    

                <Bouton 
                    width="96" height="96" 
                    image={addupload} 
                    disable={this.state.listeDisable}
                    onButtonClick={this.addUpload}
                    buttonName="addUpload"/>
            </span>
            </div>
            <div className="div-titre">
                <PageTitle image={listeupload} title="Gestion des téléchargements"  {...this.props}/>
            </div>
        </div>
        <div className="div-bodylicences">
            {this.renderPaneLeft()}
            {this.renderPaneCenter()}
            {this.renderPaneRight()}
            
        </div>

        {this.renderPageListe()}


    </div>
    
    )
}


createUpload(datas)
{
    console.log("create  ",datas)
    
    var myHeaders = new Headers();
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
                   headers: myHeaders,
                   mode: 'cors',
                   cache: 'default',
                   body: JSON.stringify(datas)
                    };

    var apiurl = config[process.env.NODE_ENV].api_url + "addupload"
    fetch(apiurl,myInit)
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      
      return response.json();
    })
    .then((actualData) => {


        // ici on a créé un upload, on se met sur la dernière page



        this.setState({gotolastpage: true});
        this.getUpload();
    })
    .catch((err) => {
      console.log(err);
    });

}

updateUpload(indexUpload, datas)
{

    console.log( "update upload", indexUpload, datas)
    
    var myHeaders = new Headers();
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'PUT',
                   headers: myHeaders,
                   mode: 'cors',
                   cache: 'default',
                   body: JSON.stringify(datas)
                    };

            
    var apiurl = config[process.env.NODE_ENV].api_url + "upload"+"/"+datas.id
    fetch(apiurl,myInit)
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      
      return response.json();
    })
    .then((actualData) => {

        try{

            this.getUpload();


            

        }
        catch(e)
            {
                console.log(e)
            }

        
    })
    .catch((err) => {
      console.log(err);
    });

}
supUpload(upload)
    {
        console.log("delete  ",upload)
        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'DELETE',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                        };

        var apiurl = config[process.env.NODE_ENV].api_url + "upload" +"/" + upload ;
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            this.getUpload();
        })
        .catch((err) => {
          console.log(err);
        });

    }



}

export default Upload

