// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagetitle
{
    
    left:95%;
    
    margin-top: 2px;
    
    float:right;
    -webkit-user-select: none;
            user-select: none; 

}


.pagetitle-picture
{
    margin-top: 10px;
    vertical-align: middle;
    margin-right: 5px;
    width: 86px;
    height: 86px;
}

.pagetitle-text
{
    
    font-size: 27px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
    white-space:nowrap;
    -webkit-user-select: none;
            user-select: none; 
}

.pagetitle-div-licence

{

    left:25%;
    
    margin-top: 2px;
    margin-left:0%;
    margin-top: 20px;
    position: absolute;
    -webkit-user-select: none;
            user-select: none; 
    text-align: left;
    font-size: 12px;
    line-height: 1.5;
    border-color: blue;
    
    

}
    `, "",{"version":3,"sources":["webpack://./src/gadgets/pagetitle/styles.css"],"names":[],"mappings":"AAAA;;;IAGI,QAAQ;;IAER,eAAe;;IAEf,WAAW;IACX,yBAAiB;YAAjB,iBAAiB;;AAErB;;;AAGA;;IAEI,gBAAgB;IAChB,sBAAsB;IACtB,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;;AAEA;;;IAGI,eAAe;IACf,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;;;;IAII,QAAQ;;IAER,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;;;;AAItB","sourcesContent":[".pagetitle\n{\n    \n    left:95%;\n    \n    margin-top: 2px;\n    \n    float:right;\n    user-select: none; \n\n}\n\n\n.pagetitle-picture\n{\n    margin-top: 10px;\n    vertical-align: middle;\n    margin-right: 5px;\n    width: 86px;\n    height: 86px;\n}\n\n.pagetitle-text\n{\n    \n    font-size: 27px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    margin-right: 10px;\n    white-space:nowrap;\n    user-select: none; \n}\n\n.pagetitle-div-licence\n\n{\n\n    left:25%;\n    \n    margin-top: 2px;\n    margin-left:0%;\n    margin-top: 20px;\n    position: absolute;\n    user-select: none; \n    text-align: left;\n    font-size: 12px;\n    line-height: 1.5;\n    border-color: blue;\n    \n    \n\n}\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
