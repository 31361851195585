import React from "react";

import {config, getUserData,getListTypeMail}  from '../config'
import ReactToolTip from 'react-tooltip'

import Bouton from "../gadgets/bouton/bouton"

import imgback from "../licences/assets/back.png"
import imgvalide from "../licences/assets/valider.png"

import '../users/styles/styles.css';
import './styles/styles.css';




class ImageMail extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = {
            image:null,
            lastimage:null,
            disable:true,
        }


        this.iconImage = React.createRef();
        this.iconInput = React.createRef();

        this.onimageloaded = this.onimageloaded.bind(this)

        this.abortEdit = this.abortEdit.bind(this)
        this.validEdit = this.validEdit.bind(this)
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      }

      validEdit(event)
      {
        if (event.detail.value)
        {
            this.setState({lastimage: this.state.image, disable: true});
            this.writeImage();
            
        }

      }


      abortEdit(event)
      {
        if (event.detail.value)
        {
            this.setState({image: this.state.lastimage, disable: true});
        }

      }

      writeImage()
      {

        var intparam=0;

        var datas =
        {
            id:2, //
            intparam: intparam,
            charparam: this.state.image
        }

        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'PUT',
                        headers: myHeaders,
                        mode: 'cors',
                        cache: 'default',
                        body: JSON.stringify(datas)
                        };
  
        var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/"+this.props.id
        fetch(apiurl,myInit)
        .then((response) => {
            if (!response.ok) {
            throw new Error(
                `This is an HTTP error: The status is ${response.status}`
            );
            }
            
            return response.json();
        })
        .then((actualData) => {
  
            try{

                
            }
            catch(e)
            {
                console.log(e)
            }

        })
        .catch((err) => {
            console.log(err);
        });
  
  }



    onimageloaded = async (event) => {
        const file = event.target.files[0]
        const base64 = await this.convertBase64(file)
        
        this.setState ( {image : base64, disable: false})
        
        }

    componentDidMount()
    {
        var myHeaders = new Headers();
    
        var myInit = { method: 'GET',
                        headers: myHeaders,
                        mode: 'cors',
                        cache: 'default' };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/"+this.props.id
        fetch(apiurl,myInit)
        .then((response) => {
            if (!response.ok) {
            throw new Error(
                `This is an HTTP error: The status is ${response.status}`
            );
            }
            
            return response.json();
        })
        .then((actualData) => {
    
            console.log("Get Param Email 2: ",actualData)


            if (actualData.datas.length > 0)
            {
            
                this.setState(
                    {
                    image: actualData.datas[0].charparam,
                    lastimage: actualData.datas[0].charparam,

                    })
                }
                
            
        })
        .catch((err) => {
            console.log(err);
        });


    
    }

    renderImage()
    {
        if (this.state.image == null) return (null);

        return (
            <img className="imagemail-image"
            ref={this.iconImage}
            src={this.state.image}
        />

        )
    }


    render ()
    {
        return(
        <>

            
            <div className="imagemail-titre">{this.props.titre}</div>

            <div className="imagemail-body">
            <table width="100%">
                <tr >
                <td style={{width:"3%", textAlign:"left"}}></td>
                <td style={{width:"30%", textAlign:"left"}}>
                <div className="imagemail-image-div">
                    {this.renderImage()}
                </div>
                </td>
                <td style={{width:"25%",textAlign:"left"}}>
                <input 


                        name="icon"
                        type="file"
                        ref={this.iconInput}

                        onChange={e=> this.onimageloaded(e)}
                        id = "userimage"

                        required />

                </td>
                <td width="30%">
                <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                    <span data-tip="Retour à l'ancienne image">
                    <Bouton 
                            width="40" height="40" top="0"
                            image={imgback} 
                            disable= {this.state.disable}
                            key = {"abort"}
                            onButtonClick={this.abortEdit}
                            buttonName="bAbort"/>
                    </span>
                    &nbsp;&nbsp;
                    <span data-tip="Valider la nouvelle image">

                    <Bouton 
                            width="40" height="40" top="0"
                            image={imgvalide} 
                            disable= {this.state.disable}
                            key = {"valide"}
                            onButtonClick={this.validEdit}
                            buttonName="bValid"/>
                    </span>

                </td>
                </tr>

            </table>
            </div>
        </>
        )
    }

}

export default ImageMail
