import React from "react";


import logocappella from './assets/cappellasmall.png'
import logoplugin from './assets/pluginsmall.png'
import download from "./assets/download.png"
import ReactToolTip from 'react-tooltip'


import Bouton from "../gadgets/bouton/bouton";

class HomeDownload extends React.Component 
{
    constructor (props)
    {
        super (props);
        this.oncappella=this.oncappella.bind(this)
        this.onplugin=this.onplugin.bind(this)
    }


    oncappella()
    {
        this.props.navigate ('/cappella')
    }

    onplugin()
    {
        this.props.navigate ('/plugin')
    }

    render()
    {


        if (this.props.software == "cappella")
        {

            if (this.props.user == 0)
            {
                return(
                    <>
                    <div className="homedownload-image">
                    <img src={logocappella}/>
                    </div>

                    <div className="homedownload-text">
                        Pour télécharger la dernière version de Cappella HD,
                        <br/>
                        connectez vous avec votre adresse email, le mot de passe est votre numéro de licence sur 6 chiffres.
                    </div>

                    </>
                )
            }
            else
            {
                return (
                    <>
                    <div className="homedownload-image">
                    <img src={logocappella}/>
                    </div>
    
                    <div className="homedownload-text2">
                        Cliquez sur le bouton à droite pour télécharger la dernière version de Cappella HD
                    </div>
                    <div className="homedownload-button">
                    
                    <div data-tip="Télécharger Cappella HD" >
                    <Bouton image={download} width={48} height={48} onButtonClick={this.oncappella}/>
                  </div>
                  
    
                    </div>
                    </>
                )
    
            }
        }
        else
        {
            return (
                <>
                <div className="homedownload-image">
                <img src={logoplugin}/>
                </div>

                <div className="homedownload-text2">
                    Cliquez sur le bouton à droite pour télécharger la dernière version du plugin Cappella Voice Over
                </div>
                <div className="homedownload-button">
                
                <div data-tip="Télécharger le plugin Cappella Voice Over" >
                <Bouton image={download} width={48} height={48} onButtonClick={this.onplugin}/>
              </div>
              

                </div>
                </>
            )
        }
        
    }

}
export default HomeDownload
