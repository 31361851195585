import React from "react";

import ReactToolTip from 'react-tooltip'
import Bouton from "../gadgets/bouton/bouton"
import search from "../licences/assets/search.png"
import balais from "../licences/assets/balais.png"

//  ****************************************************************************************
//  Search Upload
//  ****************************************************************************************


class SearchUpload extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            //firstname: this.props.firstname,
            title: this.props.title,
            detail: this.props.detail,
            status: this.props.status,
            typefile: this.props.typefile,
            typesoftware: this.props.typesoftware,
            tri: this.props.tri,
            nb: this.props.nb,
        }



        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleValide = this.handleValide.bind(this)
        this.titleInput = React.createRef();
        this.statusInput = React.createRef();
        this.typefileInput = React.createRef();
        this.typesoftwareInput = React.createRef();
        this.triInput = React.createRef();
        this.nbPageInput = React.createRef();


        this.handleChangeNbPage = this.handleChangeNbPage.bind(this);

    }


    handleChangeDetail(e)
    {
        this.setState({detail:e.target.checked});
        this.props.onChangeDetail(e.target.checked)

    }

    handleChangeNbPage(e)
    {
        this.setState({nb: e.target.value});
        


        this.props.onChangePage(e.target.value)
    }

    handleSubmit (event)
    {
        event.preventDefault();
        var searchParams = 
        {
            title: this.state.title,
            status: this.state.status,
            typefile: this.state.typefile,
            typesoftware: this.state.typesoftware,
            tri: this.state.tri ,


        }
        this.props.onSearch(searchParams);

    }

    handleReset(event)
    {
        if (event.detail.value)
        {

            var searchParams = 
            {
                title: "",
                status: "1",
                typefile: "1",
                typesoftware:"1",
                tri: "1",
    
            }
            this.props.onSearch(searchParams);
            
            this.setState(
                {
                    title: "",
                    status: "1",
                    typefile: "1",
                    typesoftware:"1",
                    tri: "1",
                }
            );
        }

    }
    handleValide(event)
    {
        if (event.detail.value)
        {

            var searchParams = 
            {
                title: this.state.title,
                status: this.state.status,
                typefile: this.state.typefile,
                typesoftware: this.state.typesoftware,
                tri: this.state.tri ,
    

            }
            this.props.onSearch(searchParams);
        }
    }


    render()
    {
        return (

            <>
            <form className="formSearchLicence" onSubmit={this.handleSubmit}>

            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
            <span className="formSearch-titre">
            <img   src={search} height="20px"/>&nbsp;&nbsp;Filtres et tri
            </span>    

                <label className="formSearch-label">
                    Titre
                    <input className="formSearch-input" autoFocus={true}
                    disabled= {this.props.disabled}
                    name="Titre"
                    ref={this.titleInput}
                    type="text"
                    onChange={e => this.setState({title:e.target.value})}
                    value={this.state.title}
                    />
                </label>
                
                <label className= "formSearch-label">
                        <br/>Version :
                        <select className = "formSearch-select-status"
                            ref={this.statusInput}
                            name="status"
                            disabled= {this.props.disabled}
                            onChange={e => this.setState({status:e.target.value})}
                            value={this.state.status}  >
                            <option value={1}>Toutes versions</option>
                            <option value={2}>Anciennes versions</option>
                            <option value={3}>Nouvelles versions</option>
                            <option value={4}>Versions stables</option>
                            <option value={5}>Versions pour tests</option>
                            

                        </select>
                        
                </label>

                <label className= "formSearch-label">
                        <br/>Logiciel :
                        <select className = "formSearch-select-status"
                            ref={this.typesoftwareInput}
                            name="typesoftware"
                            disabled= {this.props.disabled}
                            onChange={e => this.setState({typesoftware:e.target.value})}
                            value={this.state.typesoftware}  >
                            <option value={1}>Tous les logiciels</option>
                            <option value={2}>Cappella HD</option>
                            <option value={3}>Cappella Voice Over</option>

                        </select>
                </label>

                <label className= "formSearch-label">
                        <br/>Type de fichier :
                        <select className = "formSearch-select-status"
                            ref={this.typefileInput}
                            name="typefile"
                            disabled= {this.props.disabled}
                            onChange={e => this.setState({typefile:e.target.value})}
                            value={this.state.typefile}  >
                            <option value={1}>Tous les fichiers</option>
                            <option value={2}>Fichiers Setup</option>
                            <option value={3}>Documentations</option>
                            <option value={4}>Fichiers Complementaires</option>

                        </select>
                </label>

                <label className= "formSearch-label">
                        <br/>Tri :
                        <select className = "formSearch-select-status"
                            ref={this.triInput}
                            name="tri"
                            disabled= {this.props.disabled}
                            onChange={e => this.setState({tri:e.target.value})}
                            value={this.state.tri}  >
                            <option value={1}>Date</option>
                            <option value={2}>Titre</option>
                            <option value={3}>Version</option>
                            <option value={4}>ID</option>

                        </select>
                </label>


                <table className="formUser-tablebutton"><tbody>
                    <tr>
                    <td width="38%"></td>
                    <td width="30%">
                    <span data-tip="Annuler tous les critères">
                    <Bouton 
                        width="48" height="48" 
                        image={balais} 
                        disable={this.props.disabled}
                        buttonName="breset"
                        onButtonClick={this.handleReset}
                    />
                    </span>
                    </td><td width="30%">
                    <span data-tip="Lancer la recherche">
                    <Bouton 
                    width="48" height="48" 
                    image={search} 
                    disable={this.props.disabled}
                    buttonName="bvalide"
                    onButtonClick={this.handleValide}
                    />
                    </span>
                    </td>
                    <td width="2%"></td>
                    </tr>
                    </tbody></table>


            </form>
            
            <div className="formSearch-check">
            <input 
                        
                        disabled= {this.props.disabled}
                        name="detail"
                        type="checkbox"
                        onChange={e => this.handleChangeDetail(e)}
                        
                        checked={this.state.detail}
                        required />
                        &nbsp;Afficher les détails<br /><br />
            </div>
            
                <div className= "formSearch-label">
                        Fichiers par page :
                        <br />
                        <select className="formSearch-select-status"
                            ref={this.nbPageInput}
                            name="nbperpage"
                            disabled= {this.state.disabled}
                            onChange={e => this.handleChangeNbPage(e)}
                            value={this.state.nb}  >
                            <option value={10}>10 par pages</option>
                            <option value={20}>20 par pages</option>
                            <option value={30}>30 par pages</option>

                        </select>
                </div>

            </>


        )
    }

}

export default SearchUpload