

var usehttps = true;

var domainename = "vsi.paris";
var basepath ="/var/www/";

var allowedOrigins =    "https://cappella."+ domainename;
var headerAllowOrigin = "https://cappella."+ domainename;


if (!usehttps)
{
    allowedOrigins =  "http://cappella."+ domainename;
    headerAllowOrigin ="http://cappella."+ domainename;

}



var site =
{
    domainname : domainename,
    localip : "91.134.90.108",
    apis : "cappellaapis",
    app: "cappella",
    localmode : false, // false pour tests uniquement
    https : usehttps, 

    domaincert: "/etc/letsencrypt/live/cappella.vsi.paris/fullchain.pem",
    domainkey: "/etc/letsencrypt/live/cappella.vsi.paris/privkey.pem",


    localcert: "/etc/letsencrypt/live/91.134.90.108/fullchain.pem",
    localkey: "/etc/letsencrypt/live/91.134.90.108/privkey.pem",


    basepath : basepath,
    uploadpath : basepath + "cappelladatas/upload",

    downloadpath: "https://cappelladatas.vsi.paris/upload/", // Obsololète

    allowedOrigins :   allowedOrigins,
    headerAllowOrigin: headerAllowOrigin,


    mail:
    {
        password: "Grenouille-verte-lap1",
        email: "noreply-fr@vsi.tv",
        imap: {
            port: 993,
            imap: "mail.easydirect.fr",
        },
        smtp: {
            port: 25,
            smtp: "vsi-tv.mail.protection.outlook.com",
        }

        
    },


    mail_easydirect:
    {
        password: "MachinBidule59!",
        email: "cappella@easydirect.fr",
        imap: {
            port: 993,
            imap: "mail.easydirect.fr",
        },
        smtp: {
            port: 465,
            smtp: "mail.easydirect.fr",
        }

        
    },
    
    ssl:
    {
        pwd: "IZRT87pongup", // pour mémoire
        user: "debian",
        port: 63254
    },

    ports :
        {
            postgres : 5432,
            app : 2040,
            app_external : 2040,
            apis : 2041,
            apis_internal : 2041,
            webSocketPort : 4050,
        },
    
    postgres :
        {
            host: "localhost",
            database: "cappella",
            user : "cappellaclient",
            password : "cappella2024",
        }

}

module.exports = {site}
