import React from "react";
import PageTitle from "../gadgets/pagetitle/pagetitle"
import Bouton from "../gadgets/bouton/bouton"
import Pagination from "../gadgets/pagination/pagination";
import SearchLicences from "./searchlicences";
import ListeActivity from "./listeactivity";
import LicenceForm from "./licenceform";
import ListeSelected from "./listeselected";
import EditMailLicence from "./editmaillicence";

import stylo64 from "../users/assets/stylo64.png"
import poubelle from "../users/assets/poubelle.png"


import listelicence from "./assets/cappellalicence.png"
import addlicence from "./assets/addlicence.png"

import valider from "./assets/valider.png"
import nonvalider from "./assets/nonvalider.png"
import student from "./assets/student.png"
import remove from "./assets/remove.png"
import add from "./assets/add.png"
import activity from "./assets/activity.png"

import {config, getUserData}  from '../config'
import ReactToolTip from 'react-tooltip'

import Modal from "../gadgets/modal/modal"

import '../users/styles/styles.css';
import './styles/styles.css';

//  ****************************************************************************************
//  ListLicencesDatas
//  ****************************************************************************************


class ListLicencesDatas extends React.Component{
    constructor(props)
    {
        super(props)
    }


    displayOneChampUser(ilicence,ichamp)
    {
        var champ = this.props.columms[ichamp].zone

        if (champ == "status")
        {
        
            var styleimage="";
            if (this.props.disable) styleimage="imggrayed";

            var retour = [];
            if (this.props.datas[ilicence].status==1)
            {
                retour.push (
                    <span  data-tip={"Licence valide"}>
                    <img className={styleimage}
                    width="24" height="24"
                    src={valider} />

                    </span>  )

            }
            if (this.props.datas[ilicence].status==0)
            {
                retour.push (
                    <span  data-tip={"Licence non valide"}>
                    <img className={styleimage}
                    width="24" height="24"
                    src={nonvalider} />

                    </span>  )

            }

            if (this.props.datas[ilicence].etudiant==1)
            {
                retour.push (
                    <span  data-tip={"Licence étudiant"}>
                    &nbsp;&nbsp;<img className={styleimage}
                    width="32" height="32"
                    src={student} />

                    </span>  )

            }



            if (this.props.detail.toString() != "false")
            {
                retour.push(
                <>
                <br/><span style={{fontSize:"11px"}}>Version :</span>
                <span style={{fontSize:"11px", fontWeight: "bold"}}>
                    {this.props.datas[ilicence].version}
                </span>
                <br/><span style={{fontSize:"11px"}}>Précédente:</span>
                <span style={{fontSize:"11px", fontWeight: "bold"}}>
                    {this.props.datas[ilicence].versionprecedente}
                </span>
                
                </>)

            }

            return retour;


        }


        if (champ == "nom")
        {



            if (this.props.detail.toString() == "false")
                return (
                <>
                
                {this.props.datas[ilicence].nom + " " +this.props.datas[ilicence].prenom}
                </>
                );

            else
            {

                var datasButtons = 
                {
                    licence: ilicence,
                    idlicence: this.props.datas[ilicence]["licence"],
                }
    
                return (
                <>
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/> 
                    {this.props.datas[ilicence].nom + " " +this.props.datas[ilicence].prenom}<br/>
                    <span style={{fontSize:"11px"}}>Serial 1 :</span>
                    <span style={{fontSize:"11px", fontWeight: "bold"}}>
                        {this.props.datas[ilicence].serialhd1}


                    <span data-tip="supprimer le serial 1">
                        <Bouton 
                        width="20" height="20" 
                        top="5px"
                        left="5px"
                        image={poubelle} 
                        disable={this.props.disable}
                        
                        onButtonClick={this.props.ondeleteserial1}
                        datas={datasButtons}
                        buttonName="bDeleteSerial1"/>
                    </span>


                    
                    </span><br/>
                    <span style={{fontSize:"11px"}}>Serial 2 :</span>
                    <span style={{fontSize:"11px", fontWeight: "bold"}}>
                        {this.props.datas[ilicence].serialhd2}

                        <span data-tip="supprimer le serial 2">
                        <Bouton 
                        width="20" height="20" 
                        top="5px"
                        left="5px"
                        image={poubelle} 
                        disable={this.props.disable}
                        
                        onButtonClick={this.props.ondeleteserial2}
                        datas={datasButtons}
                        buttonName="bDeleteSerial2"/>
                    </span>



                    </span><br/>
                </>
                )
            }
        }
        if (champ == "action")
        {
            var retour = [];
            var datasButtons = 
            {
                licence: ilicence,
                idlicence: this.props.datas[ilicence]["licence"],
            }
                
            var datatedit="Modifier la licence"
            var datatsup="supprimer la licence";
            if (this.props.disable) 
            {
                datatedit=""
                datatsup=""
            }
            retour.push (
                    <span  data-tip={datatedit}>
                    <Bouton 
                    width="40" height="40"
                    image={stylo64} 
                    disable={this.props.disable}
                    buttonName="bedit"
                    datas={datasButtons}
                    onButtonClick={this.props.onmodify}/>
                    </span>  )

            retour.push(
                        <span  data-tip={datatsup}>
                        <Bouton 
                        width="40" height="40" 
                        image={poubelle} 
                        disable={this.props.disable}
                        onButtonClick={this.props.ondelete}
                        datas={datasButtons}
                        buttonName="bSuppression"/>
                    </span>)
        
            if (!this.props.datas[ilicence].selected)
                retour.push(
                    <span  data-tip="sélectionner la licence">
                    <Bouton 
                    width="40" height="40" 
                    image={add} 
                    key = {"add"+ilicence.toString()}
                    disable={this.props.disable}
                    onButtonClick={this.props.onselectlicence}
                    datas={datasButtons}
                    buttonName="bSelect"/>
                </span>)
            else
                retour.push(
                    <span  data-tip="désélectionner la licence">
                    <Bouton 
                    width="40" height="40" 
                    image={remove} 
                    key = {"remove"+ilicence.toString()}
                    disable={this.props.disable}
                    onButtonClick={this.props.onselectlicence}
                    datas={datasButtons}
                    buttonName="bUnselect"/>
                </span>)


        retour.push(
            <span  data-tip="Activité....">
            <Bouton 
            width="40" height="40" 
            image={activity} 
            disable={this.props.disable}
            onButtonClick={this.props.ondetail}
            datas={datasButtons}
            buttonName="bActivite"/>
        </span>)
                    
            
            return retour;
        }

        
        
        return (this.props.datas[ilicence][this.props.columms[ichamp].zone])
    }

    displayOneUserList(iuser)
    {
        var retour = []

        

        for (var i=0; i<this.props.columms.length; i++)
        {
        var width = this.props.columms[i].width + "%"
        var key="useritem" + iuser+"_"+ i;
        var useritemclass = "userItemList"
        if (iuser & 1)
            var useritemclass = "userItemList2"

        if (this.props.disable) var useritemclass = "userItemListDisable"
          retour.push(
              
              <td width={width}  className={useritemclass} key={key}>
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    
                  {this.displayOneChampUser(iuser,i)}
              </td>)
        }
        return ( retour)
            
    }

    displayAllUserList()
    {



        var retour=[];
        var maxi = this.props.datas.length;
        if (maxi === undefined) maxi=0;
        
        var firstline = this.props.firstline;
        var lastline = this.props.lastline;
        if (firstline<0) firstline=0;
        if (lastline>maxi) lastline=maxi;

        console.log("from", firstline," to ",lastline , maxi);

        for (var i=firstline; i <= lastline;i++)
        
        {
            {
                var key = "useritem" + i;
                var retl =  (<tr heightkey={key}>{this.displayOneUserList(i)} </tr>)
                retour.push(retl)
            }
        }
        return retour;
    }


    render()
    {
        
        return (
            
            <>
            
            {this.displayAllUserList()}
            </>
            )
    }
}



//  ****************************************************************************************
//  ListLicencesHeader
//  ****************************************************************************************
class ListLicencesHeader extends React.Component
{
    constructor(props)
    {
        super(props);


    }

createAllHeaderColumns()
{
    var retour = []
    var styleh = "headerList"
    if (this.props.disable)
        styleh = "headerList-disable"
    for (var i=0; i<this.props.columms.length; i++)
    {
    var width = this.props.columms[i].width + "%"
    
    
    var key="userheader" + i;
      retour.push(
          
          <td width={width} className={styleh} key={key}>
          
              {this.props.columms[i].text}
          </td>)
    }
    return ( retour)
        
    
}


render()
{
    return (
        <>
                {this.createAllHeaderColumns()}
        </>
    )
}

}



//  ****************************************************************************************
class ListLicences extends React.Component
{
    constructor(props)
    {
        super(props);
        this.columns = [
            {text:"Licence", width:6, zone: "licence"},
            {text:"Nom", width:15, zone: "nom"},
            {text:"Mail", width:10, zone: "mail"},
            {text:"Status", width:8, zone: "status"},
            {text:"Date Fin", width:10, zone: "madatefin"},
            {text:"Connexion", width:10, zone: "madatecoonect"},
            {text:"Actions", width:12, zone: "action"}
        ]

    }

    render()
    {
    
        return (
            <>
                <table className="tableList" cellSpacing={5} cellPadding={0}>
                    
                    
                    <thead>
                    <tr>
                    <ListLicencesHeader columms = {this.columns}  disable={this.props.disable}/>          
                    </tr>
                    </thead>
                    <tbody>
                    <ListLicencesDatas columms = {this.columns} datas={this.props.liste} 
                        firstline = {this.props.firstline}
                        lastline = {this.props.lastline}
                        disable={this.props.disable}
                        detail = {this.props.detail}
                        onselectlicence={this.props.onselectlicence}
                        ondetail ={this.props.ondetail}
                        onmodify={this.props.onmodify}
                        ondelete={this.props.ondelete}
                        ondeleteserial1={this.props.ondeleteserial1}
                        ondeleteserial2={this.props.ondeleteserial2}
                    />                    
    
                    </tbody>
                </table>
            </>
            
    
    
        )
    }
    


}
//  ****************************************************************************************



//  ****************************************************************************************
class Licences  extends React.Component 
{


    constructor(props)
    {
        super(props);

        this.state = 
        {
            liste:{},
            nbrows:0,
            nbdisplay: 4,
            currentPage: 1,
            gotolastpage: false,
            nbPerPage: 10,
            listeDisable: false,

            editmode: false,
            createmode: false,
            supmode: false,
            currentLicenceRow: 0,


            displayActivite: false,
            licenceActivite:"",
            listeActivite:{},

            detail: false,
            editmail: false,

            modalValues : {
                title: "",
                text: "",
                display: false,
                oncancel: null,
                buttons: []
            },
    

            selectedLicences: []
        }


        this.searchParams=
        {
            firstname: "",
            status: "1",
            type: "1",
            tri: "1",
            
        };

        this.onClick = this.onClick.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onAlert = this.onAlert.bind(this)
        this.onChangePage = this.onChangePage.bind(this);
        this.onChangeDetail = this.onChangeDetail.bind(this);
        this.detailLicence = this.detailLicence.bind(this);
        this.closeActivity = this.closeActivity.bind(this);

        this.addLicence = this.addLicence.bind(this)
        this.editLicence = this.editLicence.bind(this);
        this.deleteLicence = this.deleteLicence.bind(this);

        this.selectLicence = this.selectLicence.bind(this);


        this.cancelForm = this.cancelForm.bind(this);
        this.validForm = this.validForm.bind(this);

        this.clearAllSelected = this.clearAllSelected.bind(this);
        this.clearOneSelected = this.clearOneSelected.bind(this);
        this.actionGroupee = this.actionGroupee.bind(this);
        this.AppliqueActionGroupee = this.AppliqueActionGroupee.bind(this);
        this.SendMailGroupir = this.SendMailGroupir.bind(this);

        this.ondeleteserial1 = this.ondeleteserial1.bind(this);
        this.ondeleteserial2 = this.ondeleteserial2.bind(this);


        this.onvalideditmail = this.onvalideditmail.bind(this);

    }


    onAlert()
    {
        this.getLicencesAlert();
    }

    clearAllSelected(event)
    {
        if (event.detail.value)
        {
            var listelic = this.state.liste;
            for (var i=0; i < listelic.length; i++)
                listelic[i].selected=false;
            this.setState({liste: listelic})
            var newliste=[];
            this.getDatasSelectedLicences(newliste);
        }

    }
    clearOneSelected(event)
    {
        
        if (event.detail.value)
        {
            var licence = event.detail.datas.licence;

            var newliste = []
            for (var i=0; i < this.state.selectedLicences.length; i++)
                if (this.state.selectedLicences[i].licence !=  licence)
                    newliste.push(this.state.selectedLicences[i]);

            // on regarde dans la liste en cours si elle est selectionnée
            var listelic = this.state.liste;
            for (i=0; i < listelic.length; i++)
            {
                if (listelic[i].licence == licence)
                listelic[i].selected=false;
            }
            this.setState({liste: listelic})
            this.getDatasSelectedLicences(newliste);



        }

    }



    oncancelGroupir()
    {

        const modalValues = {
            title: "",
            text: "",
            display: false,
            oncancel: null,
            buttons: []
        }
        this.setState({modalValues: modalValues})
    }

    onValideGroupir()
    {
        
        if (this.state.actionmultiple.action=="sendmail")
            this.SendMailGroupir(this.state.actionmultiple)
        else
            if (this.state.actionmultiple.action=="editmail")
                this.setState({editmail: false});
            else
                this.AppliqueActionGroupee(this.state.actionmultiple);

        const modalValues = {
            title: "",
            text: "",
            display: false,
            oncancel: null,
            buttons: []
        }
        this.setState({modalValues: modalValues})
    }


    actionGroupee(event)
    {

        
        
        if (event.detail.value)
        {
            console.log(event);
            var action =  event.detail.datas.action;
            if (action == "editmail")
            {
                this.setState({editmail: true});
                return;
            }



            var texte ="Etes-vous sûr de vouloir ";
            switch (action)
            {
                case "activer": texte = texte + "activer "; break;
                case "deactiver": texte = texte + "désactiver "; break;
                case "datefin": texte = texte +"changer la date sur "; break;
                case "sendmail": texte = texte+"envoyer un mail à "; break;
            }

            var n = this.state.selectedLicences.length;

            if (action != "sendmail")
            {

                if (n<2)
                    texte = texte + "cette licence ?";
                else
                    texte = texte + "ces " +n+" licences ?";
            }
            else
            {

                if (n<2)
                    texte = texte + "cet auteur ?";
                else
                    texte = texte + "ces " +n+" auteurs ?";
            }



            const modalValues = {
                title: "Actions multiples !",
                text: texte,
                display: true,
                oncancel: this.oncancelGroupir.bind(this),
                buttons: [
                    {text:"Oui", onvalid: this.onValideGroupir.bind(this)},
                    {text:"Non", onvalid: this.oncancelGroupir.bind(this)}
                ]
            }
            var datefin="";
            if (action == "datefin") datefin=event.detail.datas.date;
            // on peut envoyer une requete multi 
            var datas = {action : action, datefin: datefin, licences: this.state.selectedLicences}
            this.setState({modalValues: modalValues, actionmultiple: datas})



            // ici on va mettre un popup



            

        }

    }


    oneSelected()
    {
        //if (this.state.selectedLicences.length === undefined) return false;
        return (this.state.selectedLicences.length != 0);
    }

    getIsSelected(licence)
    {
        console.log("test if selected ",licence)
        //if (this.state.selectedLicences.length === undefined) return false;
        if (this.state.selectedLicences.length == 0) return false;
        for (var i=0; i < this.state.selectedLicences.length; i++)
            if (this.state.selectedLicences[i].licence == licence) return true;

        return false;
    
    }
    
    selectLicence(event)
    {
        if (event.detail.value)
        {
            var indexlicence = event.detail.datas.licence;
            var licence = event.detail.datas.idlicence;

            console.log("select/unselect ", indexlicence, licence)

            if (this.getIsSelected(licence))
            {
                // licence déjà sélectionée......
                console.log("unselect licence ", licence, this.state.selectedLicences)
                var newliste = []
                for (var i=0; i < this.state.selectedLicences.length; i++)
                    if (this.state.selectedLicences[i].licence !=  licence)
                        newliste.push(this.state.selectedLicences[i]);

                var listelic = this.state.liste;
                listelic[indexlicence].selected = false;
                this.setState({liste: listelic})
                this.getDatasSelectedLicences(newliste);

            }
            else
            {
                var newsel = {
                            licence: licence,
                            firstname: "",
                            mail: ""
                            }
                var listes = this.state.selectedLicences;
                listes.push (newsel);

                var listelic = this.state.liste;
                listelic[indexlicence].selected = true;

                
                this.setState({liste: listelic})
                this.getDatasSelectedLicences(listes);
            }

            
        }
    }


    onChangeDetail(value)
    {
        console.log("change detail ", value)
        this.setState({detail: value});
    }
    onChangePage(value)
    {
        
        if (value == "all")
            this.setState({nbPerPage: this.state.liste.length})
        else
            this.setState({nbPerPage: parseInt(value)})


    }

    onSearch(params,select)
    {

        console.log("Search params", params)
        this.searchParams = params;
        
        this.getLicences(select);

    }

    onClick(event)
    {

        if ((event.detail.value) && (event.detail.datas !== undefined))
        {
            var text = event.detail.datas;



            if (this.state.nbrows != 0)
            {
                var nbpages = Math.ceil( this.state.nbrows / this.state.nbPerPage);
                var currentPage = this.state.currentPage;


                switch (text)
                {
                case "next":
                    {
                        if (currentPage==0)
                            currentPage=1;
                        else
                        {
                            if (currentPage < nbpages)
                                currentPage++;
                        }
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "previous":
                    {
                        if (currentPage==0)
                            currentPage=1;
                        else
                        {
                            if (currentPage >1)
                                currentPage--;
                        }
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "first":
                    {
                        currentPage=1;
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "last":
                    {
                        currentPage=nbpages;
                        this.setState({currentPage: currentPage})
                    }
                    break;
                default:
                    currentPage = parseInt(text)
                    this.setState({currentPage: currentPage})
                    break;
                }
            }
        }
    }

    closeActivity(event)
    {
        if (event.detail.value)
        {
            this.setState(
                {
                displayActivite: false,
                licenceActivite:"",
                listeActivite:{}
                }
            )

        }
    }

    
    
    renderPaneLeft()
    {
        var classdiv="div-licences-search";
        if ((this.state.editmode) || (this.state.supmode) || (this.state.createmode))
        {
            classdiv = "div-licences-search-null";
            return (

                    <div className={classdiv} disabled={this.state.listeDisable}/>
            
            )
        }
        // ****************** Critère de tri des licences
        return(
        <div className={classdiv}>
        <SearchLicences
                    disabled={this.state.listeDisable}
                    onSearch = {this.onSearch}
                    onChangePage = {this.onChangePage}
                    onChangeDetail = {this.onChangeDetail}
                    onAlert ={this.onAlert}
                    
                  
                    firstname = {this.searchParams.firstname}
                    status = {this.searchParams.status}
                    type = {this.searchParams.type}
                    tri = {this.searchParams.tri}
                            
                    nb = {this.state.nbPerPage}
                    detail = {this.state.detail}


        

                />            
        
        
        </div>
        );
    }

    addLicence(event)
    {
        if (event.detail.value)
        {
            this.setState({
                listeDisable: true,
                editmode: false,
                createmode: true,
                supmode: false,
                currentLicenceRow: -1})
        }
    }
    

    editLicence(event)
    {
        if (event.detail.value)
        {
            var indexlicence = event.detail.datas.licence;
            var licence = event.detail.datas.idlicence;
            this.setState({
                listeDisable: true,
                editmode: true,
                createmode: false,
                supmode: false,
                currentLicenceRow: indexlicence})
        }
    }


    ondeleteserial1(event)
    {
        if (event.detail.value)
        {
            var indexlicence = event.detail.datas.licence;
            var licence = event.detail.datas.idlicence;

            var datas ={licence: licence, serial:1}
            this.deleteserial(datas)
        }

    }
    ondeleteserial2(event)
    {
        if (event.detail.value)
        {
            var indexlicence = event.detail.datas.licence;
            var licence = event.detail.datas.idlicence;

            var datas ={licence: licence, serial:2}
            this.deleteserial(datas)

        }

    }


    deleteLicence(event)
    {
        if (event.detail.value)
        {
            var indexlicence = event.detail.datas.licence;
            var licence = event.detail.datas.idlicence;
            this.setState({
                listeDisable: true,
                editmode: false,
                createmode: false,
                supmode: true,
                currentLicenceRow: indexlicence})
        }
    }
    detailLicence(event)
    {
        if (event.detail.value)
        {
            var indexlicence = event.detail.datas.licence;
            var licence = event.detail.datas.idlicence;

            console.log("activite ", indexlicence, licence)
            if ((!this.state.displayActivite) || (this.state.licenceActivite!=licence))
                this.getActivite(licence);
            else
                this.setState({displayActivite:false, licenceActivite: "", indexActivite:0});


        }

    }


    cancelForm()
    {
        this.setState({
            listeDisable: false,
            editmode: false,
            createmode: false,
            supmode: false,
            currentLicenceRow: 0})
}

    validForm(indexlicence,datas)
    {
        console.log("valide form : ",indexlicence, datas)

        if (this.state.supmode)
        {
            this.setState(
                {
                listeDisable: false,
                editmode: false,
                createmode: false,
                supmode: false,
                currentLicenceRow: 0})
            
            this.supLicence(datas.licence)

        }

        if (this.state.editmode)
        {
            this.setState(
                {
                listeDisable: false,
                editmode: false,
                createmode: false,
                supmode: false,
                currentLicenceRow: 0})
            
            this.updateLicence(indexlicence,datas)
        }


        if (this.state.createmode)
        {
            this.setState(
                {
                listeDisable: false,
                editmode: false,
                createmode: false,
                supmode: false,
                currentLicenceRow: 0})
            
            this.createLicence(datas)
        }



    }


    renderPaneCenter()
    {
        console.log("render center .............................")

        
        var firstline= ((this.state.currentPage-1) * this.state.nbPerPage) ;
        var lastline=firstline + this.state.nbPerPage-1;
        if (lastline >= this.state.nbrows) lastline = this.state.nbrows -1;

        var classdiv="div-licences-center-all";
        if ((this.state.editmode) || (this.state.supmode) || (this.state.createmode) )
            classdiv = "div-licences-center";
        else
            if( (this.oneSelected()) || (this.state.displayActivite))
                classdiv = "div-licences-center-min";

        var overflow={"overflow-y":"scroll"};
        if (this.state.listeDisable) overflow  = {overflow:"hidden"};

        return (
        <div className={classdiv} style={overflow}>

            <ListLicences
                        liste={this.state.liste} 
                        disable={this.state.listeDisable}
                        current = {this.state.currentPage}
                        firstline ={firstline}
                        lastline ={lastline}
                        onselectlicence = {this.selectLicence}
                        ondelete = {this.deleteLicence}
                        onmodify={this.editLicence}
                        ondetail={this.detailLicence}
                        detail={this.state.detail.toString()}
                        ondeleteserial1={this.ondeleteserial1}
                        ondeleteserial2={this.ondeleteserial2}


                        />            

        
        </div>);
    }
    renderPaneRight()
    {
        var classdiv="div-licences-right-null";
        if ((this.state.editmode) ||(this.state.supmode) || (this.state.createmode) || (this.oneSelected()) || (this.state.displayActivite))
            classdiv = "div-licences-right";

        if ((this.state.editmode) ||(this.state.supmode) || (this.state.createmode))
        {

            return (
                <div className={classdiv}>
                    <LicenceForm 
                        licenceindex ={this.state.currentLicenceRow}
                        datas = {this.state.liste[this.state.currentLicenceRow]}
                        onclose = {this.cancelForm}
                        onvalid = {this.validForm}
                        editmode = {this.state.editmode}
                        supmode = {this.state.supmode}
                        createmode = {this.state.createmode}
                        />
                
                </div>);

        }


        if (this.oneSelected())
        {

            return (
                <div className={classdiv}>
                <ListeSelected
                    licences = {this.state.selectedLicences}
                    onClearAll = {this.clearAllSelected}
                    onClearOne = {this.clearOneSelected}
                    onAction = {this.actionGroupee}
                />
                </div>
            )
        }

        if (this.state.displayActivite)
        {
            return (
                <div className={classdiv}>
                    <ListeActivity 
                        licence={this.state.licenceActivite}
                        liste={this.state.listeActivite}
                        onclose = {this.closeActivity}
                        />
                
                </div>);
    
        }

        return (
            <div className={classdiv}>
            
            
            </div>);
    }
  
    renderPageListe()
    {
        

        var nbrow = this.state.nbrows;
        if (nbrow === undefined) nbrow = 0;
        var classdiv="div-licences-pagination-all";
        if ((this.state.editmode) || (this.state.supmode) || (this.state.createmode) || (this.oneSelected()) || (this.state.displayActivite))
            classdiv = "div-licences-pagination";
        return (
        <div className={classdiv}>
                <Pagination disable = {this.state.listeDisable} current={this.state.currentPage} 
                onClick ={this.onClick} key={this.state.currentPage.toString()} 
                count={this.state.nbrows} nbdisplay={this.state.nbdisplay} nbbypage = {this.state.nbPerPage}/>
        </div>
        )
    }

    componentDidMount()
    {
        this.getLicences(false);
    }

deleteserial(datas)
{

    var userData = getUserData()
    var lastname = userData.user.lastname;
    datas.lastname = lastname;

    console.log("delete serial ", datas)
    var myHeaders = new Headers();
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "deleteserial"
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        this.getLicences(false);
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });

}


SendMailGroupir(datas)
{
    console.log("send mail groupees pour ", datas)
    var myHeaders = new Headers();
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "groupirmail"
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        this.getLicences(false);
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });
}


AppliqueActionGroupee(datas)
{
    console.log("send actions groupees pour ", datas)
    var myHeaders = new Headers();
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "groupir"
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        this.getLicences(false);
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });
}


getDatasSelectedLicences(liste)    
{
    console.log("get datas selected licences for ", liste)
    var myHeaders = new Headers();
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(liste)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "selected"
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        console.log("Actual datas selected licences:", actualData, actualData.datas.length)

        var newliste = [];
        for (var i=0; i <actualData.datas.length; i++)
        {
            var newitem = {
                licence : actualData.datas[i].licence,
                firstname : actualData.datas[i].nom+" "+actualData.datas[i].prenom,
                mail: actualData.datas[i].mail
            }
            newliste.push(newitem);
        }
        console.log("recu ", newliste)
        this.setState({selectedLicences:newliste}) ;
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });

}
            
getActivite(licence)
{
    console.log("get Activity for ", licence)
    var datas = {licence: licence};
    var myHeaders = new Headers();
    this.setState({licenceActivite:licence});

    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "activite"
    console.log(apiurl);
    console.log(datas);
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        console.log("Actual datas activity:", actualData, actualData.datas.length)

        this.setState({listeActivite:actualData.datas, 
            displayActivite: true, 
            });
        
    
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });

}

getLicences(select)
{
    var datas = this.searchParams;
    console.log("Search licences with ", datas)
    var myHeaders = new Headers();

    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "licences"
    console.log(apiurl);
    console.log(datas);
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        console.log("Actual datas:", actualData, actualData.datas.length)

        var nbrows = actualData.datas.length;
        


        if (select)
        {
            var listes = this.state.selectedLicences;
            for (var i=0; i < nbrows; i++)
            {

                if (!this.getIsSelected(actualData.datas[i].licence))
                {
                    // on l'ajoute à la liste des licences
                    var newsel = 
                    {
                        licence: actualData.datas[i].licence,
                        firstname: actualData.datas[i].nom+" "+actualData.datas[i].prenom,
                        mail: actualData.datas[i].mail
                    }
                    listes.push (newsel);
                }
                actualData.datas[i].selected = true;

            }
            this.setState({currentPage:1,selectedLicences:listes})
        }
        else
        {
            for (var i=0; i < nbrows; i++)
                actualData.datas[i].selected = this.getIsSelected(actualData.datas[i].licence)
        }

        if (this.state.gotolastpage)
        {

            
            var nbpages = Math.ceil( nbrows / this.state.nbPerPage);
            var currentPage = nbpages;


            console.log("create current page ", nbpages, currentPage)

            this.setState({liste:actualData.datas, 
                nbrows: nbrows, currentPage: currentPage, gotolastpage: false});
        }

        else

            this.setState({liste:actualData.datas, 
                nbrows: nbrows, currentPage:1});
    

        if (this.state.displayActivite)                
        {
            this.getActivite(this.state.licenceActivite)
        };
        
    
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });

}

getLicencesAlert()
{
    var datas = this.searchParams;
    console.log("Search licences with ", datas)
    var myHeaders = new Headers();

    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "lastmail"
    console.log(apiurl);
    console.log(datas);
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        console.log("Actual datas:", actualData, actualData.datas.length)

        var nbrows = actualData.datas.length;
        


        var listes = [];
        for (var i=0; i < nbrows; i++)
        {
            var newsel = 
            {
                licence: actualData.datas[i].licence,
                firstname: actualData.datas[i].nom+" "+actualData.datas[i].prenom,
                mail: actualData.datas[i].mail
            }
            listes.push (newsel);
            actualData.datas[i].selected = true;

        }
    
        this.setState({liste:actualData.datas ,selectedLicences:listes,
            nbrows: nbrows, currentPage:1});
    

        if (this.state.displayActivite)                
        {
            this.getActivite(this.state.licenceActivite)
        };
        
    
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });

}



render () 
{

    
    console.log("render.............................")
    var userData = getUserData()
    var priv = userData.user.priv;
    if (((priv & 4) == 0) && (priv & 1)==0)
    {
        this.props.navigate ('/')
        return (null)
    }
    return (
        <>
    <div className="div-container">
        <div className="div-entete">
            <div className="div-boutonup">
            <span data-tip="Ajouter une licence">
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    

                <Bouton 
                    width="96" height="96" 
                    image={addlicence} 
                    disable={this.state.listeDisable}
                    onButtonClick={this.addLicence}
                    buttonName="addLicence"/>
            </span>
            </div>
            <div className="div-titre">
                <PageTitle licence="yes" image={listelicence} title="Licences Cappella HD"  {...this.props}/>
            </div>
        </div>
        <div className="div-bodylicences">
            {this.renderPaneLeft()}
            {this.renderPaneCenter()}
            {this.renderPaneRight()}

            
            
        </div>

        {this.renderPageListe()}


    </div>
    <Modal value = {this.state.modalValues}/>
    <EditMailLicence display = {this.state.editmail}  onvalid={this.onvalideditmail}
        />
    
    </>
    )
}

onvalideditmail()
{
    this.setState({editmail:false});
}

createLicence(datas)
{
    console.log("crate  ",datas)

    var userData = getUserData()
    var lastname = userData.user.lastname;
    datas.lastname = lastname;

    
    var myHeaders = new Headers();
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
                   headers: myHeaders,
                   mode: 'cors',
                   cache: 'default',
                   body: JSON.stringify(datas)
                    };

    var apiurl = config[process.env.NODE_ENV].api_url + "addlicences"
    fetch(apiurl,myInit)
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      
      return response.json();
    })
    .then((actualData) => {


        // ici on a créé une licence, on se met sur la dernière page



        this.setState({gotolastpage: true});
        this.getLicences();
    })
    .catch((err) => {
      console.log(err);
    });

}

updateLicence(indexLicence, datas)
{

    console.log( "update licence", indexLicence, datas)

    var userData = getUserData()
    var lastname = userData.user.lastname;
    datas.lastname = lastname;
    
    
      

    var myHeaders = new Headers();
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'PUT',
                   headers: myHeaders,
                   mode: 'cors',
                   cache: 'default',
                   body: JSON.stringify(datas)
                    };

            
    var apiurl = config[process.env.NODE_ENV].api_url + "licences"+"/"+datas.licence
    fetch(apiurl,myInit)
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      
      return response.json();
    })
    .then((actualData) => {

        try{

                // ici on met a jour notre user

                if (this.state.displayActivite)                
                {
                    this.getActivite(datas.licence)
                };
    

            

        }
        catch(e)
            {
                console.log(e)
            }

        
    })
    .catch((err) => {
      console.log(err);
    });

}
supLicence(licence)
    {
        console.log("delete  ",licence)
        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'DELETE',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                        };

        var apiurl = config[process.env.NODE_ENV].api_url + "licences" +"/" + licence ;
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            this.getLicences();
        })
        .catch((err) => {
          console.log(err);
        });

    }



}

export default Licences

