import React from "react";
import ReactToolTip from 'react-tooltip'
import Bouton from "../gadgets/bouton/bouton"
import DatePicker, { DateObject } from "react-multi-date-picker"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import Icon from "react-multi-date-picker/components/icon";

import imgback from "./assets/back.png"
import imgvalide from "./assets/valider.png"

import './styles/styles.css';

const months = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]
const weekDays = ["Dim","Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]


//  ****************************************************************************************
//  Form Licences
//  ****************************************************************************************


class LicenceForm extends React.Component
{
    constructor(props)
    {
        super(props)

        if (this.props.createmode)
        {
            this.state =
            {
                nom: "",
                prenom: "",
                mail: "",
                serialhd1: "",
                serialhd2: "",
                datefin: "",
                status: 1,
                etudiant: 0,
                sendmail: true,

                errorMsg:""
            }

        }
        else
        {
            this.state =
            {
                nom: this.props.datas.nom,
                prenom: this.props.datas.prenom,
                mail: this.props.datas.mail,
                serialhd1: this.props.datas.serialhd1,
                serialhd2: this.props.datas.serialhd2,
                datefin: this.props.datas.madatefin,
                status: this.props.datas.status,
                etudiant: this.props.datas.etudiant,
                sendmail: this.props.editmode,

                errorMsg:""

            }
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleValide = this.handleValide.bind(this)


        this.nomInput = React.createRef();
        this.prenomInput = React.createRef();
        this.mailInput = React.createRef();
        this.serialhd1Input = React.createRef();
        this.serialhd2Input = React.createRef();
        this.datefinInput = React.createRef();
        this.statusfinInput = React.createRef();
        this.etudiantInput = React.createRef();
        this.datediffusionPicker = React.createRef();
        this.sendmailInput = React.createRef();
    }



    handleSubmit (event)
    {
        event.preventDefault();
    }


    checkvalidemail()
    {
        var cmail = this.mailInput.current.value
        if (!cmail.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) 
        {
            return false;
        }        
        return true;
    }


    handleValide(event)
    {
        if (event.detail.value)
        {

            if (this.props.supmode)
            {
                datas = this.props.datas;
                this.props.onvalid(this.props.licenceindex, datas);
                return;
            }

            if ((this.props.editmode) || (this.props.createmode))
            {

                // on test les zones
                this.setState({errorMsg:""})
                if (this.state.nom.trim()=="")  
                { 
                    this.nomInput.current.focus();
                    this.setState({errorMsg:"Zone nom obligatoire."})
                    return;
                }
        
                if (this.state.prenom.trim()=="")  { this.prenomInput.current.focus();
                    this.setState({errorMsg:"Zone prénom obligatoire."})
                        return;}
            
                if (!this.checkvalidemail())
                {
                    this.setState({errorMsg:"Format mail incorrect."})
                    this.mailInput.current.focus();
                    return;
        
                }


                if (this.state.datefin.trim()=="")  
                {
                    this.setState({errorMsg:"Date de fin obligatoire."})
                    this.datefinInput.current.focus();
                    return;
        
                }


                var datas ={};
                if ((this.props.editmode) || (this.props.supmode))
                    datas = this.props.datas;

                datas.nom = this.state.nom;
                datas.prenom = this.state.prenom;
                datas.mail = this.state.mail;
                datas.serialhd1 = this.state.serialhd1;
                datas.serialhd2 = this.state.serialhd2;
                datas.madatefin = this.state.datefin;
                datas.status = this.state.status;
                datas.etudiant = this.state.etudiant;
                datas.sendmail = this.state.sendmail;


                this.props.onvalid(this.props.licenceindex, datas);
            }
        }
    }

    // *************************************************************************************************
    // * date et heures
    // *************************************************************************************************
    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }
      
      formatDate(date) {
        return [
          this.padTo2Digits(date.getDate()),
          this.padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/');
      }    

      formatHeure(date)
      {
          return this.padTo2Digits(date.hour)+":"+this.padTo2Digits(date.minute)
      }


      fromStringDateToDateObject(stringDate)
      {
          var newdate = new Date()
          var jour = parseInt (stringDate.substring(0,2))
          var mois = parseInt (stringDate.substring(3,5))
          var annee = parseInt (stringDate.substring(6,10))

        newdate.setFullYear(annee)
        newdate.setDate(1)
        newdate.setMonth(mois-1)
        newdate.setDate(jour)
        return newdate;
      }

    openCalendarDate()
    {
      this.datediffusionPicker.current.openCalendar()
    }

    renderCheckEmail()
    {
        if (this.props.supmode) return (null);

        return (<>
        <label className="formSearch-label">
        <br/>
        <input 
                        
            disabled= {this.props.disabled}
            name="sendmail"
            type="checkbox"
            ref={this.sendmailInput}
            onChange={e => this.setState({sendmail:e.target.checked})}
            checked={this.state.sendmail}
            />
            <span>&nbsp;Envoyer un mail à l	&lsquo;auteur (Licence active) <br/></span>
            </label>
            </>
        )


    }

    render()
    {

        var titre = "Création d'une licence";
        if (this.props.editmode) titre = "Modification d'une licence";
        if (this.props.supmode) titre = "Suppression d'une licence";


        var msgvalide="Créer licence";
        if (this.props.editmode) msgvalide = "Appliquer les modifications";
        if (this.props.supmode) msgvalide = "Supprimer la licence";
        return (

            <>
            <form className="formLicence" onSubmit={this.handleSubmit}>

            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
            <span className="formSearch-titre">
            {titre}
            </span>    

                <label className="formSearch-label">
                    Nom
                    <input className="formSearch-input" autoFocus={true}
                    disabled= {this.props.supmode}
                    name="nom"
                    ref={this.nomInput}
                    type="text"
                    onChange={e => this.setState({nom:e.target.value})}
                    value={this.state.nom}
                    required
                    />
                </label>
                
                <label className="formSearch-label">
                    Prénom
                    <input className="formSearch-input" autoFocus={true}
                    disabled= {this.props.supmode}
                    name="prenom"
                    ref={this.prenomInput}
                    type="text"
                    onChange={e => this.setState({prenom:e.target.value})}
                    value={this.state.prenom}
                    required
                    />
                </label>

                <label className="formSearch-label">
                    Adresse EMail
                    <input className="formSearch-input" autoFocus={true}
                    disabled= {this.props.supmode}
                    name="mail"
                    ref={this.mailInput}
                    type="text"
                    onChange={e => this.setState({mail:e.target.value})}
                    value={this.state.mail}
                    required
                    />
                </label>

                <label className="formSearch-label">
                    Serial 1
                    <input className="formSearch-input" autoFocus={true}
                    disabled= {this.props.supmode}
                    name="serialhd1"
                    ref={this.serialhd1Input}
                    type="text"
                    onChange={e => this.setState({serialhd1:e.target.value})}
                    value={this.state.serialhd1}
                    />
                </label>

                <label className="formSearch-label">
                    Serial 2
                    <input className="formSearch-input" autoFocus={true}
                    disabled= {this.props.supmode}
                    name="serialhd2"
                    ref={this.serialhd2Input}
                    type="text"
                    onChange={e => this.setState({serialhd2:e.target.value})}
                    value={this.state.serialhd2}
                    />
                </label>

                <table>
                <tr>
                <td align="left" width="50%">
                <label className="formSearch-label">
                    Date de fin
                    <input className="formSearch-input-date" autoFocus={true}
                    disabled= {this.props.supmode}
                    name="datefin"
                    readOnly
                    ref={this.datefinInput}
                    type="text"
                    onClick={this.openCalendarDate.bind(this)}
                    value={this.state.datefin}
                    required
                    />

                </label>
                </td>
                <td align="left">
                
                <DatePicker   render={<Icon/>} 
                        className="bg-dark red"
                        format="DD/MM/YYYY"
                        weekDays={weekDays}
                        disabled={this.props.supmode}
                        ref = {this.datediffusionPicker}
                        calendarPosition="bottom-end"
                        months={months}
                        value = {this.fromStringDateToDateObject(this.state.datefin)}
                        onChange = { (date) => {
                            var d = this.formatDate(date.toDate())
                            this.setState({datefin:d})
                        }}
                    />                
                </td>
                </tr>
                </table>
                <label className= "formSearch-label">
                        <br/>Type de licence :
                        <select className = "formSearch-select-status"
                            ref={this.etudiantInput}
                            name="etudiant"
                            disabled = {this.props.supmode}
                            onChange={e => this.setState({etudiant:e.target.value})}
                            value={this.state.etudiant}  >
                            <option value={0}>Licences Normales</option>
                            <option value={1}>Licences Etudiant</option>

                        </select>
                </label>

                <label className= "formSearch-label">
                        <br/>Status :
                        <select className = "formSearch-select-status"
                            ref={this.statusInput}
                            name="status"
                            disabled= {this.props.supmode}
                            onChange={e => this.setState({status:e.target.value})}
                            value={this.state.status}  >
                            <option value={1}>Licence Active</option>
                            <option value={0}>Licence Inactive</option>

                        </select>
                </label>


                {this.renderCheckEmail()}

                <table className="formUser-tablebutton"><tbody>
                    <tr>
                    <td width="10%">&nbsp;</td>
                    <td width="65%" className="formUser-errormsg">{this.state.errorMsg}&nbsp;</td>
                    <td width="7%">
                    <span data-tip="Abandon">
                    <Bouton 
                        width="40" height="40" 
                        image={imgback} 
                        disable={false}
                        buttonName="bback"
                        onButtonClick={this.props.onclose}
                    />
                    </span>
                    </td><td width="7%">
                    <span data-tip={msgvalide}>
                    <Bouton 
                    width="40" height="40" 
                    image={imgvalide} 
                    disable={false}
                    buttonName="bvalide"
                    onButtonClick={this.handleValide}
                    />
                    </span>
                    </td>
                    <td width="5%"></td></tr>
                    </tbody></table>


            </form>
            
            </>


        )
    }

}

export default LicenceForm