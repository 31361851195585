// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-pagination 
{
    width: 100%;
    height: 100%;
}
  
.div-pagination-bouton
{
    
    border-color:burlywood;

}

.standard-page {
    position: relative;
  
      
}
  `, "",{"version":3,"sources":["webpack://./src/gadgets/pagination/styles.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;;;IAGI,sBAAsB;;AAE1B;;AAEA;IACI,kBAAkB;;;AAGtB","sourcesContent":[".div-pagination \n{\n    width: 100%;\n    height: 100%;\n}\n  \n.div-pagination-bouton\n{\n    \n    border-color:burlywood;\n\n}\n\n.standard-page {\n    position: relative;\n  \n      \n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
