import React from "react";

import EditMail from "../mails/editmail";


import "../gadgets/modal/stylespopup.css";
import "../mails/styles/styles.css"



class EditMailLicence extends React.Component
{

    constructor(props)
    {
        super(props)
    }





    render()
    {

        console.log ("render edit mail value ", this.props.display)
        if (!this.props.display)
            return (null)
        return (
            <>
                <div className="darkBG"/>
                <div className="centered">
                <div className="mailseditor_licence">
                
                 
                    <EditMail
                        onvalid ={this.props.onvalid}
                        oncancel={this.props.onvalid}
                        disable = {false}
                        id ={16}
                        lib = ""
                        index = {-1} />
                </div>
                </div>
                
            
            
            </>
        )
    }
}
export default EditMailLicence


