import React from "react";
import ReactToolTip from 'react-tooltip'
import Bouton from "../gadgets/bouton/bouton"

import imgback from "../licences/assets/back.png"
import imgvalide from "../licences/assets/valider.png"

import {config, getUserData}  from '../config'

import '../licences/styles/styles.css';


//  ****************************************************************************************
//  Form Upload
//  ****************************************************************************************


class UploadForm extends React.Component
{
    constructor(props)
    {
        super(props)

        if (this.props.createmode)
        {


            var typefile = 0;
            var typesoftware = 0;


            if (this.props.searchParams.typefile == 2 ) typefile=0;
            if (this.props.searchParams.typefile == 3 ) typefile=1;
            if (this.props.searchParams.typefile == 4 ) typefile=2;


            if (this.props.searchParams.typesoftware == 2 ) typesoftware=0;
            if (this.props.searchParams.typesoftware == 3 ) typesoftware=1;

            this.state =
            {
                file: "",
                fullpath: "",
                title: "",
                version:"1.0.0",
                date: "",
                status: 1,
                //typefile: this.props.typefile,
                //typesoftware: this.props.typesoftware,
                typefile: typefile,
                typesoftware: typesoftware,
                errorMsg:"",
                videoLoaded:false,
                sendMail: false,
            }

        }
        else
        {
            this.state =
            {

                file: this.props.datas.file,
                fullpath: this.props.datas.fullpath,
                version: this.props.datas.version,
                title: this.props.datas.title,
                date: this.props.datas.date,
                status: this.props.datas.status,
                typefile: this.props.datas.typefile,
                typesoftware: this.props.datas.typesoftware,
                errorMsg:"",
                sendMail: false,
                internalusers:[],


            }
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleValide = this.handleValide.bind(this)


        this.titleInput = React.createRef();
        this.versionInput = React.createRef();
        this.statusInput = React.createRef();
        this.typesoftwareInput = React.createRef();
        this.typefileInput = React.createRef();
        this.fileInput = React.createRef();
        this.sendmailInput = React.createRef();

        this.onVideoloaded = this.onVideoloaded.bind(this);


        this.getInternalUserList();

    }

    getInternalUserList()
    {
        var myHeaders = new Headers();
    
        var myInit = { method: 'GET',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default' };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "internalusers"
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            this.setState ({internalusers : actualData.datas})
        })
        .catch((err) => {
          console.log(err);
        });
    
    }




    handleSubmit (event)
    {
        event.preventDefault();
    }


    handleValide(event)
    {
        if (event.detail.value)
        {

            if (this.props.supmode)
            {
                datas = this.props.datas;
                this.props.onvalid(this.props.uploadindex, datas);
                return;
            }

            if ((this.props.editmode) || (this.props.createmode))
            {

                // on test les zones
                this.setState({errorMsg:""})
                if (this.state.title.trim()=="")  
                { 
                    this.titleInput.current.focus();
                    this.setState({errorMsg:"Zone titre obligatoire."})
                    return;
                }
        




                var datas ={};
                if ((this.props.editmode) || (this.props.supmode))
                    datas = this.props.datas;


                datas.file = this.state.file;
                datas.fullpath = this.state.fullpath;
                datas.version = this.state.version;
                datas.title = this.state.title;
                datas.date = this.state.date;
                datas.status = this.state.status;
                datas.typefile = this.state.typefile;
                datas.typesoftware = this.state.typesoftware;

                
                var sendM = this.state.sendMail;
                if (this.props.supmode) sendM = false;
                if ((this.state.status != 1) && (this.state.status != 3)) sendM=false;
                if (this.state.typesoftware != 0) sendM=false;
                if (this.state.typefile != 0 ) sendM=false;
                datas.sendMail = sendM;


                console.log("validation datas ", datas," state ", this.state)
    

                this.props.onvalid(this.props.uploadindex, datas);
            }
        }
    }

    ListeInternalUsers(dis)
    {
        if ((dis) || (!this.state.sendMail))
            return (null)
        else
        {


            var listusertable = [];
            for (var i=0; i < this.state.internalusers.length; i++)
            {   
                listusertable.push(
                    <tr>
                        <td>{this.state.internalusers[i].email}</td>
                        <td>{this.state.internalusers[i].firstname +" "+ this.state.internalusers[i].lastname}</td>
                    </tr>
                )

            }

            return(
                <div>

                    <table width="100%" >
                        <tr>
                        <td bgcolor='black' >Email</td>
                        <td bgcolor='black' >Utilisateur</td>
                        </tr>
                        {listusertable}
                    </table>
                </div>
            )
        }

    }


    renderCheckEmail()
    {

        var dis = this.state.disable;
        if ((this.state.status != 1) && (this.state.status != 3)) dis=true;
        if (this.state.typesoftware != 0) dis=true;
        if (this.state.typefile != 0 ) dis=true;


        var valuemail = this.state.sendMail;
        if (dis) valuemail=false;

        console.log("render check mail : the.props.action.type");
        
        
        return (<>
        <label className="formSearch-label">
        <br/>
        <input 
                        
            disabled= {dis}
            name="sendmail"
            type="checkbox"
            ref={this.sendmailInput}
            onChange={e => this.setState({sendMail:e.target.checked})}
            checked={valuemail}
            />
            <span>&nbsp;Envoyer un mail aux utilisateurs internes (n°17)<br/></span>
            </label>

            {this.ListeInternalUsers(dis)}

            </>
        )


    }

    render()
    {

        var disableValide = false;
        if (this.props.createmode)
            disableValide = !this.state.valideEnabled;

        var titre = "Création d'un téléchargement";
        if (this.props.editmode) titre = "Modification d'un téléchargement";
        if (this.props.supmode) titre = "Suppression d'un téléchargement";


        var msgvalide="Création";
        if (this.props.editmode) msgvalide = "Appliquer les modifications";
        if (this.props.supmode) msgvalide = "Supprimer le téléchargement";
        return (

            <>
            <form className="formLicence" onSubmit={this.handleSubmit}>

            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
            <span className="formSearch-titre">
            {titre}


            </span>    

                <label className="formSearch-label">
                    Titre
                    <input className="formSearch-input" autoFocus={true}
                    disabled= {this.props.supmode}
                    name="title"
                    ref={this.titleInput}
                    type="text"
                    onChange={e => this.setState({title:e.target.value})}
                    value={this.state.title}
                    required
                    />
                </label>
                
                <label className="formSearch-label">
                    Version
                    <input className="formSearch-input" 
                    disabled= {this.props.supmode}
                    name="versioning"
                    ref={this.versionInput}
                    type="text"
                    onChange={e => this.setState({version:e.target.value})}
                    value={this.state.version}
                    required
                    
                    />
                </label>


                <label className= "formSearch-label">
                        <br/>Logiciel :
                        <select className = "formSearch-select-status"
                            ref={this.typesoftwareInput}
                            name="typesoftware"
                            disabled = {this.props.supmode}
                            onChange={e => this.setState({typesoftware:e.target.value})}
                            value={this.state.typesoftware}  >
                            <option value={0}>Cappella HD</option>
                            <option value={1}>Cappella Voice Over</option>

                        </select>
                </label>

                <label className= "formSearch-label">
                        <br/>Type de fichier :
                        <select className = "formSearch-select-status"
                            ref={this.typefileInput}
                            name="typefile"
                            disabled = {this.props.supmode}
                            onChange={e => this.setState({typefile:e.target.value})}
                            value={this.state.typefile}  >
                            <option value={0}>Setup</option>
                            <option value={1}>Documentation</option>
                            <option value={2}>Complément</option>

                        </select>
                </label>

                <label className= "formSearch-label">
                        <br/>Status :
                        <select className = "formSearch-select-status"
                            ref={this.statusInput}
                            name="status"
                            disabled= {this.props.supmode}
                            onChange={e => this.setState({status:e.target.value})}
                            value={this.state.status}  >
                            <option value={0}>Ancienne version</option>
                            <option value={1}>Nouvelle version</option>
                            <option value={2}>Version stable</option>
                            <option value={3}>Version pour tests</option>

                        </select>
                </label>


                <label className="formSearch-label">
                    Fichier
                    {this.getInputFile()}
                </label>

                {this.renderCheckEmail()}
                <table className="formUser-tablebutton"><tbody>
                    <tr>
                    <td width="10%">&nbsp;</td>
                    <td width="65%" className="formUser-errormsg">{this.state.errorMsg}&nbsp;</td>
                    <td width="7%">
                    <span data-tip="Abandon">
                    <Bouton 
                        width="40" height="40" 
                        image={imgback} 
                        disable={false}
                        buttonName="bback"
                        onButtonClick={this.props.onclose}
                    />
                    </span>
                    </td><td width="7%">
                    <span data-tip={msgvalide}>
                    <Bouton 
                    width="40" height="40" 
                    image={imgvalide} 
                    disable={disableValide}
                    buttonName="bvalide"
                    onButtonClick={this.handleValide}
                    />
                    </span>
                    </td>
                    <td width="5%"></td></tr>
                    </tbody></table>


            </form>
            
            </>


        )
    }


    onVideoloaded = async (event) => {
        const file = event.target.files[0]
        this.setState({file: file.name})

        
        const formData = new FormData();
        formData.append ('file', file);
     



        if (this.state.title.trim() =="")
        {
            this.setState (
                {title: file.name.split('.').slice(0, -1).join('.')}
            )
        }
        

        
        this.setState ( 
          {   videoLoaded : true,
                valideEnabled : false,
                errorMsg: "Chargement en cours..."
        })

        const response = await fetch(config[process.env.NODE_ENV].api_url+"files/upload", 
        {
            method: 'POST',
            body: formData,
            //headers: {"Content-Type":"multipart/form-data"}
          })
          console.log("fin upload")
          if (response) 
          {
            if (response.status == 200)
            {
                const reader = response.body.getReader();
                reader.read().then (({done,value}) =>
                {

                    var string = new TextDecoder().decode(value);
                    var jvalue = JSON.parse(string)

                    if (jvalue.datas.error)
                    {
                        console.log("Erreur !!!!", jvalue.datas.error,jvalue.datas.errorcode)
                        var errorMsg="Erreur !";

                        switch (jvalue.datas.errorcode)
                        {
                            case 1: errorMsg = "Erreur en téléchargement."; break;
                            case 2: errorMsg = "Erreur lecture table."; break;
                            case 3: errorMsg = "Fichier déjà éxistant."; break;
                        }
                        this.setState ( 
                            {valideEnabled : false,
                                videoLoaded: false,
                                errorMsg: errorMsg
                            })
                    }
                    else
                    {

                        var title = this.state.title.trim();
                        if (title=="") title = jvalue.datas.uploadfile;
                        console.log("Fichier final ",jvalue.datas.uploadfile)
                        this.setState ( 
                            {valideEnabled : true,
                                title, title,
                                file: jvalue.datas.uploadfile,
                                fullpath: jvalue.datas.path,
                                errorMsg: "Chargement terminé."
                            })
                    }
                });
            }
            else
            {
                this.setState ( 
                    {valideEnabled : false,
                        videoLoaded: false,
                        errorMsg: "Erreur en chargement."
                    })

            }
          }
      }



    getInputFile()
    {

        if ((this.props.createmode) && (!this.state.videoLoaded))
        {
            return (
                    <>
                    <input className="formUser-input-drop"

                    disabled= {false }
                    name="filename"
                    type="file"
                    id="file"
                    ref={this.fileInput}
                    
                    onChange={e=> this.onVideoloaded(e)}
                    
                    required />
                    <label className="formUser-label-small">Cliquez ou glissez un fichier.</label>
                    </>
            
            )
        }
        else
        {
            return(
            
                    <label className="formUser-label-white">
                    <br/>
                    {this.state.file}
                    </label>

            )

        }
    }   
}    



export default UploadForm