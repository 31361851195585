import React from "react";

import onep from "./assets/onep.png"
import onepon from "./assets/onepon.png"
import nextp from "./assets/nextp.png"
import prevp from "./assets/prevp.png"
import lastp from "./assets/lastp.png"
import firstp from "./assets/firstp.png"



import OnePage from "./onepage";
import Bouton from "../bouton/bouton";



import './styles.css';

class Pagination extends React.Component 
{
constructor(props)
{
    super(props)

}

componentDidUpdate()
{
    
}

createButtons()
{
    var nbdisplay = this.props.nbdisplay;
    var count = this.props.count;
    var current = this.props.current;
    var nbbypage = this.props.nbbypage;

    var nbpages = Math.ceil( count / nbbypage);
    
    if (current < 1) current=1;
    if (current > nbpages) current = nbpages;


    


    var allbuttons=[];

    
    

    //console.log("count ", count, "nbdisplay", nbdisplay,"nbpages ", nbpages, "current ", current);
    //allbuttons.push(<td>C{count},N{nbbypage},CU{current},NB{nbpages}</td>)
    
    if (nbpages == 0)
        allbuttons.push (<></>);
    else
    {
        

        if (nbpages == 1)
            allbuttons.push (<td><Bouton width="40" height="40" image={onepon} onButtonClick = {this.props.onClick} key="1SEL" text="1" caption="1" disable={this.props.disable} selected={true}/></td>);
        else
        {

            if (current > 2)
            {
                allbuttons.push (<td><Bouton width="40" height="40" image={firstp} onButtonClick = {this.props.onClick}  key="first" text="first" disable={this.props.disable} /></td>);
                allbuttons.push (<td><Bouton width="40" height="40" image={prevp} onButtonClick = {this.props.onClick}  key="previous" text="previous" disable={this.props.disable} /></td>);
            }
            if (current == 2)
                allbuttons.push (<td><Bouton width="40" height="40" image={prevp} onButtonClick = {this.props.onClick}  key="previous" text="previous" disable={this.props.disable} /></td>);
            
            
            
            

            var start = current - Math.floor(nbdisplay/2);
            if (start < 1) start=1;
            var end = start + nbdisplay;
            if (end > nbpages) 
            {
                end = nbpages;
                start = end - nbdisplay;
                if (start <1) start = 1;
            }

            
            for (var p=start; p <= end; p++)
            
            {
                if (p == current)
                    allbuttons.push (<td><Bouton width="40" height="40" image={onepon} onButtonClick = {this.props.onClick} key={p.toString()+"SEL"} text={p.toString()} caption={p.toString()} disable={this.props.disable} selected={true}/></td>);
                else
                    allbuttons.push (<td><Bouton width="40" height="40" image={onep} onButtonClick = {this.props.onClick} key={p.toString()+"SEL"} text={p.toString()} caption={p.toString()} disable={this.props.disable} /></td>);
                    
            }
            
            if (end < (nbpages-1))
                allbuttons.push (<td><Bouton width="40" height="40" image={nextp} onButtonClick = {this.props.onClick}  key="next" text="next" disable={this.props.disable} /></td>);
                
            if (end != nbpages)
                allbuttons.push (<td><Bouton width="40" height="40"  image={lastp} onButtonClick = {this.props.onClick}  key="last" text="last" disable={this.props.disable} /></td>);


        }

        
        

    }
    
    return allbuttons;

    
    

}


createButtonsOld()
{
    var nbdisplay = this.props.nbdisplay;
    var count = this.props.count;
    var current = this.props.current;
    var nbbypage = this.props.nbbypage;

    var nbpages = Math.ceil( count / nbbypage);
    
    if (current < 1) current=1;
    if (current > nbpages) current = nbpages;


    


    var allbuttons=[];

    
    

    console.log("count ", count, "nbdisplay", nbdisplay,"nbpages ", nbpages, "current ", current);

    
    if (nbpages == 0)
        allbuttons.push (<></>);
    else
    {
        if (nbpages == 1)
            allbuttons.push (<OnePage onClick = {this.props.onClick} key="1SEL" type="1" disable={this.props.disable} selected={true}/>);
        else
        {
            // on fait les boutons avant
            if (current > 2)
            {
                allbuttons.push (<OnePage image = {firstp} onClick = {this.props.onClick} disable={this.props.disable} key="first" type="first"/>);
                allbuttons.push (<OnePage image = {prevp} onClick = {this.props.onClick} disable={this.props.disable} key="previous" type="previous"/>);
            }
            if (current == 2)
                allbuttons.push (<OnePage onClick = {this.props.onClick} disable={this.props.disable} key="previous" type="previous"/>);
            
            
            
            

            var start = current - Math.floor(nbdisplay/2);
            if (start < 1) start=1;
            var end = start + nbdisplay;
            if (end > nbpages) 
            {
                end = nbpages;
                start = end - nbdisplay;
                if (start <1) start = 1;
            }

            /*
            for (var p=start; p <= end; p++)
            {
                if (p == current)
                    allbuttons.push (<OnePage image={onepon}  onClick = {this.props.onClick} disable={this.props.disable} key={p.toString()+"SEL"} type={p.toString()} selected={true}/>);
                else
                    allbuttons.push (<OnePage image={onep}  onClick = {this.props.onClick} disable={this.props.disable} key={p.toString()} type={p.toString()} />);
            }
            */
            if (end < (nbpages-1))
                allbuttons.push (<OnePage image = {nextp} onClick = {this.props.onClick} disable={this.props.disable} key="next" type="next"/>);
            if (end != nbpages)
                allbuttons.push (<OnePage image = {lastp} onClick = {this.props.onClick} disable={this.props.disable} key="last" type="last"/>);

        }

        


    }
    
    return allbuttons;

    
    

}



render()
{
    
    
    return (
        <div className = "div-pagination">
            <table align="center"><tr>
            {this.createButtons()}
            </tr></table>

        </div>
    )
}
}

export default Pagination
