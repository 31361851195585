import React from "react";

import removeemail from "./assets/removeemail.png"
import addemail from "./assets/addemail.png"
import editemail from "./assets/editemail.png"


import imgback from "../licences/assets/back.png"
import imgvalide from "../licences/assets/valider.png"


import ReactToolTip from 'react-tooltip'
import Bouton from "../gadgets/bouton/bouton"

import {config, getUserData}  from '../config'


import './styles/styles.css';




class ListListMail extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
        }
        
    }



    render()
    {
        var liste =[];
        for (var i=0; i < this.props.liste.length; i++)
        {
            liste.push(
                <tr >
                    <td className="listmail-itemList">
                        {this.props.liste[i].name}
                    </td>
                    <td className="listmail-itemList">
                    {this.props.liste[i].mail}
                    </td>
                    <td className="listmail-itemListC" >

                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                    <span data-tip="Modifier le mail">
                    <Bouton 
                    width="32" height="32" top="0"
                    image={editemail} 
                    disable= {this.props.disable}
                    key = {"edit"+this.props.liste[i].id}
                    onButtonClick={this.props.onModifOne}
                    datas={{id:this.props.liste[i].id, index:i}}
                    buttonName="bModif"/>
                    </span>


                    <span data-tip="Supprimer le mail">

                    <Bouton 
                    width="32" height="32" top="0"
                    image={removeemail} 
                    disable= {this.props.disable}
                    key = {"remove"+this.props.liste[i].id}
                    onButtonClick={this.props.onDeleteOne}
                    datas={{id:this.props.liste[i].id, index:i}}
                    buttonName="bDelete"/>
                    </span>
                    </td>
                </tr>
            )

        }

        return (
            <>
            
            <table  cellSpacing={5} cellPadding={0} className="tableList">
            <thead>
            <tr >
                <td width='42%' className="listmail-headerList">&nbsp;Nom</td>
                <td width='42%'className="listmail-headerList" >&nbsp;Email</td>
                <td width='16%'className="listmail-headerListC">&nbsp;Action</td>
            </tr>
            </thead>
                

            <tbody>
                
                {liste}
            </tbody>

            </table>
            </>

        )
    }

}




class ListeMail extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = 
        {
            liste : [],
            disable: false,
            createmod : false,
            editmod: false,
            id:0,
            name:"",
            mail:""
        }


        this.onDeleteOne = this.onDeleteOne.bind(this);
        this.onModifOne = this.onModifOne.bind(this);
        this.onAddEmail = this.onAddEmail.bind (this);

        this.onclose = this.onclose.bind(this);
        this.handleValide = this.handleValide.bind(this)



        this.mailInput = React.createRef();
        this.nameInput = React.createRef();


    }


componentDidMount()
{
    this.getMails();
}

onDeleteOne(event)
{
    if (event.detail.value)
    {
        var id = event.detail.datas.id;
        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'DELETE',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                        };


    
        var apiurl = config[process.env.NODE_ENV].api_url + "mails" +"/" + id ;
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            this.getMails();
        })
        .catch((err) => {
          console.log(err);
        });

    }

}

checkvalidemail()
{
    var cmail = this.mailInput.current.value
    if (!cmail.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) 
    {
        return false;
    }        
    return true;
}




onModifOne(event)
{
    if (event.detail.value)
    {

        console.log(event)
        var id = event.detail.datas.id;
        var index = event.detail.datas.index;

        var name = this.state.liste[index].name;
        var mail = this.state.liste[index].mail;

        this.setState( {createmod:false, editmod: true, id:id, name:name,mail:mail, disable:true})
    }
}


updateMail(id, datas)
    {

        console.log( "update mail", id, datas)
        
        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'PUT',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                       body: JSON.stringify(datas)
                        };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "mails"+"/"+id
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            try{

                this.getMails();
            }
            catch(e)
            {
                console.log(e)
            }

            this.getUsers();
        })
        .catch((err) => {
          console.log(err);
        });
    
    }





createMail(datas)
{

    {

        console.log("create  ",datas)
        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'POST',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                       body: JSON.stringify(datas)
                        };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "mails"
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            this.getMails();
        })
        .catch((err) => {
          console.log(err);
        });
    
    }


}

handleValide(event)
{
    if (event.detail.value)
    {

        if (this.state.name.trim()=="")  
        { 
            this.nameInput.current.focus();
            return;
        }
    
        if (!this.checkvalidemail())
        {
            this.mailInput.current.focus();
            return;

        }


        var datas =
        {
            mail: this.mailInput.current.value,
            name: this.nameInput.current.value,
        }

        if (this.state.createmod)
            this.createMail(datas)
        else
            this.updateMail(this.state.id,datas)


    }

}

onclose(event)
{
    if (event.detail.value)
    {
        this.setState( {createmod:false, editmod:false, id:0, name:"",mail:"", disable:false})
    }
}

onAddEmail(event)
{

    if (event.detail.value)
    {
        this.setState( {createmod:true, editmod: false, id:0, name:"",mail:"", disable:true})
    }


}
getMails()
    {
        var myHeaders = new Headers();
    
        var myInit = { method: 'GET',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default' };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "mails"
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            console.log(actualData)
            this.setState ({liste : actualData.datas, editmod:false, createmod: false, disable:false})
        })
        .catch((err) => {
          console.log(err);
        });
    
    }



    renderButtonEdit()
    {
        if ((!this.state.createmod) && (!this.state.editmod))
            return (null);

        var msgvalide = "Valider l'ajout de l'email";
        if (this.state.editmod) msgvalide="Valider la modification de l'email"

        return(
            <>
            <div style={{textAlign:"left"}}>

                    &nbsp;&nbsp;&nbsp;&nbsp;Nom<br/>
                    <input className="formListMail-input" autoFocus={true}
                    name="name"
                    ref={this.nameInput}
                    type="text"
                    onChange={e => this.setState({name:e.target.value})}
                    value={this.state.name}
                    required
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;Email<br/>
                    <input className="formListMail-input" 
                    name="mail"
                    ref={this.mailInput}
                    type="text"
                    onChange={e => this.setState({mail:e.target.value})}
                    value={this.state.mail}
                    required
                    />

                    
            </div>




            <table className="formUser-tablebutton"><tbody>
            <tr>
            <td width="10%">&nbsp;</td>
            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
            <td width="65%" className="formUser-errormsg">{this.state.errorMsg}&nbsp;</td>
            <td width="7%">
            <span data-tip="Abandon">
            <Bouton 
                width="40" height="40" 
                image={imgback} 
                disable={false}
                buttonName="bback"
                onButtonClick={this.onclose}
            />
            </span>
            </td><td width="7%">
            <span data-tip={msgvalide}>
            <Bouton 
            width="40" height="40" 
            image={imgvalide} 
            disable={false}
            buttonName="bvalide"
            onButtonClick={this.handleValide}
            />
            </span>
            </td>
            <td width="5%"></td></tr>
            </tbody></table>
            
            </>
        )



    }

    
    renderButtonAdd()
    {

        if ((!this.state.createmod) && (!this.state.editmod))
        return(
            <div style={{marginBottom:"10px"}}>
            <span  data-tip="Ajouter un Email">
                <Bouton 
                width="48" height="48" 
                image={addemail} 
                key = {"addemail"}
                onButtonClick={this.onAddEmail}
                buttonName="bAddEmail"/>
            </span>
            
        </div>
        )
        return (null);
    }


    render()
    {
        ReactToolTip.hide();
        return(
            <div className='formListMailAll'>
                <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                <div className="formEmails-titre">
                    Adresses mail pour les notifications journalières.
                </div>
                <div className="formListMail">
                    <ListListMail liste={this.state.liste}
                        onDeleteOne = {this.onDeleteOne} 
                        onModifOne = { this.onModifOne}
                        disable ={this.state.disable}/>
                </div>


                {this.renderButtonEdit()}
                {this.renderButtonAdd()}


            
            </div>
        )

    }

}
export default ListeMail