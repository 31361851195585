import React from "react";

import remove from "./assets/remove.png"
import valider from "./assets/valider.png"
import nonvalider from "./assets/nonvalider.png"
import setdate from "./assets/setdate.png"
import sendmail from "../mails/assets/sendmail.png"
import editemail from "../mails/assets/editemail.png"

import ReactToolTip from 'react-tooltip'
import Bouton from "../gadgets/bouton/bouton"

import DatePicker, { DateObject } from "react-multi-date-picker"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import Icon from "react-multi-date-picker/components/icon";

import './styles/styles.css';


const months = ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]
const weekDays = ["Dim","Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]


class DisplayListeSelected extends React.Component
{
    constructor(props)
    {
        super(props)
        
    }



    render()
    {
        var liste =[];
        for (var i=0; i < this.props.licences.length; i++)
        {
            liste.push(
                <tr >
                    <td className="selected-itemList">
                        {this.props.licences[i].licence}
                    </td>
                    <td className="selected-itemList">
                    {this.props.licences[i].firstname}
                    </td>
                    <td className="selected-itemList">
                    {this.props.licences[i].mail}
                    </td>
                    <td className="selected-itemList" data-tip="désélectionner la licence">

                    
                    <Bouton 
                    width="22" height="22" top="0"
                    image={remove} 
                    key = {"remove"+this.props.licences[i].licence}
                    onButtonClick={this.props.onClearOne}
                    datas={{licence:this.props.licences[i].licence}}
                    buttonName="bUnselect"/>

                    

                        
                    </td>
                </tr>
            )

        }

        return (
            

            <table  cellSpacing={5} cellPadding={0} className="tableList">
            <thead>
            <tr >
                <td width='10%' className="selected-headerList">Licence</td>
                <td width='40%'className="selected-headerList" >Nom</td>
                <td width='40%'className="selected-headerList">Mail</td>
                <td width='5%'className="selected-headerList">A.</td>

            </tr>
            </thead>
                

            <tbody>
                {liste}
            </tbody>

            </table>
            

        )
    }

}




class ListeSelected extends React.Component
{
    constructor(props)
    {
        super(props)
        this.datediffusionPicker = React.createRef();
        this.datefinInput = React.createRef();

        this.state = {datefin: ""}


        this.setDate = this.setDate.bind(this);

    }

    setDate(event)
    {
        if (event.detail.value)
        {
            var newdate = this.datefinInput.current.value;
            if (newdate.trim()=="")  { this.datefinInput.current.focus(); return;}

            var datas = {  detail:  {value: true,  datas: {action:"datefin", date:newdate}   } }
            this.props.onAction(datas);
        }
    }


    // *************************************************************************************************
    // * date et heures
    // *************************************************************************************************
    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }
      
      formatDate(date) {
        return [
          this.padTo2Digits(date.getDate()),
          this.padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('/');
      }    

      formatHeure(date)
      {
          return this.padTo2Digits(date.hour)+":"+this.padTo2Digits(date.minute)
      }


      fromStringDateToDateObject(stringDate)
      {
          var newdate = new Date()
          var jour = parseInt (stringDate.substring(0,2))
          var mois = parseInt (stringDate.substring(3,5))
          var annee = parseInt (stringDate.substring(6,10))

        newdate.setFullYear(annee)
        newdate.setDate(1)
        newdate.setMonth(mois-1)
        newdate.setDate(jour)
        return newdate;
      }


    openCalendarDate()
    {
      this.datediffusionPicker.current.openCalendar()
    }
    

    render()
    {
        ReactToolTip.hide();
        return(
            <div className='formSelectedLicence'>
                <div className="formTitreSelectedLicence">
                <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                    <span className="formSearch-titre">
                    Actions multiples
                    </span>    

                </div>
                <div className="formListeSelectedLicence">
                    <DisplayListeSelected licences={this.props.licences}
                        onClearOne = {this.props.onClearOne} />
                </div>
                <div className="formActionSelectedLicence">
                    <table width="100%">
                        <tr >
                            <td width="80%" className="selected_buttontext">
                                <span>Activer les licences</span>
                            </td>
                            <td data-tip="Activer les licences" className="selected_button">
                            <Bouton 
                                width="32" height="32" top="0"
                                image={valider} 
                                key = {"valider"}
                                onButtonClick={this.props.onAction}
                                datas={{action:"activer"}}
                                buttonName="bactiver"/>

                            </td>
                        </tr>
                        <tr >
                            <td width="80%" className="selected_buttontext">
                                <span >Désactiver les licences</span>
                            </td>
                            <td data-tip="Désactiver les licences" className="selected_button">
                            <Bouton 
                                width="32" height="32" top="0"
                                image={nonvalider} 
                                key = {"devalider"}
                                onButtonClick={this.props.onAction}
                                datas={{action:"deactiver"}}
                                buttonName="bactiver"/>

                            </td>
                        </tr>
                        <tr>

                        <td width="80%" className="selected_buttontext">    

                            <table width="100%">
                                <tr >
                                    <td align="right" width="100%">
                                        Date de fin &nbsp;&nbsp;
                                        <input  autoFocus={true} className="select-input-date"
                                        readOnly
                                        ref={this.datefinInput}
                                        type="text"
                                        onClick={this.openCalendarDate.bind(this)}
                                        value={this.state.datefin}
                                        required/>
                            
                                        </td>


                                    <td align="left">
                                        <DatePicker   render={<Icon/>} 
                                                className="bg-dark red"
                                                format="DD/MM/YYYY"
                                                weekDays={weekDays}
                                                ref = {this.datediffusionPicker}
                                                calendarPosition="bottom-end"
                                                months={months}
                                                onChange = { (date) => {
                                                    var d = this.formatDate(date.toDate())
                                                    this.setState({datefin:d})}}
                                                        
                                            />                
                                    </td>
                                </tr>
                            </table>
                        </td>
                        <td data-tip="Définir la nouvelle date de fin" className="selected_button">


                            <Bouton 
                                    width="32" height="32" top="0"
                                    image={setdate} 
                                    key = {"setdate"}
                                    onButtonClick={this.setDate}
                                    buttonName="bsetdate"/>
                        </td>
                        </tr>
                        

                        <tr >

                            <td width="80%" className="selected_buttontext">
                                    Envoyer un mail (n° 16)
                            </td>
                            <td className="selected_button">


                            <span data-tip="Envoyer un mail">
                            <Bouton 
                                width="32" height="32" top="0"
                                image={sendmail} 
                                key = {"sendmail"}
                                onButtonClick={this.props.onAction}
                                datas={{action:"sendmail"}}
                                buttonName="sendmail"/>
                            </span>
                            <span data-tip="Modifier le mail">
                            &nbsp;
                            <Bouton 
                                        
                                        width="32" height="32" top="0"
                                        image={editemail} 
                                        key = {"editmail"}
                                        onButtonClick={this.props.onAction}
                                        datas={{action:"editmail"}}
                                        buttonName="editmail"/>
                            </span>
                            </td>
                            
                        </tr>
                


                    </table>
                    
                </div>
                <div className="formButtonSelectedLicence">
                    <span  data-tip="désélectionner toutes les licences">
                        <Bouton 
                        width="40" height="40" 
                        image={remove} 
                        key = {"removeall"}
                        onButtonClick={this.props.onClearAll}
                        buttonName="bUnselectall"/>
                    </span>
                    
                </div>

            
            </div>
        )

    }

}
export default ListeSelected