import logo from './logo.svg';
import './App.css';
import Home from './home/home'
import Users from "./users/users"
import Licences from "./licences/licences"
import Upload from './upload/upload';
import Activity from './activity/activity';
import Cappella from './cappella/cappella';
import Plugin from './cappella/pugin';
import Email from './mails/emails';

import SideBar from './sidebar'
import React from 'react'


import {config} from "./config"

import { BrowserRouter , useNavigate , Route, Routes } from "react-router-dom";


import './home/styles/styles.css';
import './users/styles/styles.css';


function Root() {
  const navigate = useNavigate();

  

  return (
    <div id='App'>
    <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"}  navigate={navigate} width="300px"/>
    <div id="page-wrap">
    <Routes>
        <Route path="/" element={<Home navigate={navigate}/>} />
        <Route path="/users" element={<Users navigate={navigate}/>} />
        <Route path="/licences" element={<Licences navigate={navigate}/>} />
        <Route path="/upload" element={<Upload navigate={navigate}/>} />
        <Route path="/activity" element={<Activity navigate={navigate}/>} />
        <Route path="/cappella" element={<Cappella navigate={navigate}/>} />
        <Route path="/plugin" element={<Plugin navigate={navigate}/>} />
        <Route path="/emails" element={<Email navigate={navigate}/>} />


    </Routes>
    </div>
    </div>

  );
}




function App() 

{

  
  console.log(config);
  return (
    <BrowserRouter>
      <Root/>
    </BrowserRouter>
    )


}

export default App;
