import React from "react";
import PageTitle from "../gadgets/pagetitle/pagetitle"
import Pagination from "../gadgets/pagination/pagination";
import imgactivity from "./assets/imgactivity.png"
import valider from "../licences/assets/valider.png"
import nonvalider from "../licences/assets/nonvalider.png"

/*
SELECT licence, ip, action,username , dateaction ,(select nom from cappellahd where cappellahd.licence=cappellahd_history.licence) ,(select prenom from cappellahd where cappellahd.licence=cappellahd_history.licence) ,(select status from cappellahd where cappellahd.licence=cappellahd_history.licence) FROM "public"."cappellahd_history" ORDER BY dateaction DESC LIMIT 1000
SELECT serial,to_char(dateaction, 'DD/MM/YYYY HH24:MI') as madate,licence, ip, action,username , dateaction, (select to_char(datefin,'DD/MM/YYYY') as madatefin from cappellahd where cappellahd.licence=cappellahd_history.licence) ,(select nom from cappellahd where cappellahd.licence=cappellahd_history.licence) ,(select prenom from cappellahd where cappellahd.licence=cappellahd_history.licence) ,(select status from cappellahd where cappellahd.licence=cappellahd_history.licence) FROM "public"."cappellahd_history" ORDER BY dateaction DESC LIMIT 1000
*/


import {config, getActionLib, getUserData}  from '../config'
import ReactToolTip from 'react-tooltip'



import '../users/styles/styles.css';
import '../licences/styles/styles.css';

//  ****************************************************************************************
//  ListActivityDatas
//  ****************************************************************************************


class ListActivityDatas extends React.Component{
    constructor(props)
    {
        super(props)
    }


    displayOneChampActivity(iactivity,ichamp)
    {
        var champ = this.props.columms[ichamp].zone

        
        if (champ == "action")
        {
            var action= getActionLib(this.props.datas[iactivity].action)

            return (<>{action}</>);

        }

        if (champ == "nom")
        {
            return (<>{this.props.datas[iactivity].nom+" "+this.props.datas[iactivity].prenom}</>);
        }

        if (champ == "status")
        {
       
            var styleimage="";
            if (this.props.disable) styleimage="imggrayed";

            var retour = [];
            if (this.props.datas[iactivity].status==0) // 0=old, 1=new, 2=stable
            {
                retour.push (
                    <span  data-tip={"Licence non active"}>
                    <img className={styleimage}
                    width="30" height="30"
                    src={nonvalider} />

                    </span>  )

            }
            if (this.props.datas[iactivity].status==1)
            {
                retour.push (
                    <span  data-tip={"Licence active"}>
                    <img className={styleimage}
                    width="30" height="30"
                    src={valider} />

                    </span>  )

            }

            return retour;
        }



        
        
        return (this.props.datas[iactivity][this.props.columms[ichamp].zone])
    }

    displayOneActivityList(iactivity)
    {
        var retour = []

        

        for (var i=0; i<this.props.columms.length; i++)
        {
            var width = this.props.columms[i].width + "%"
            var key="upactivityitem" + iactivity+"_"+ i;
            var useritemclass = "userItemListcenter"
            if (iactivity & 1)
                var useritemclass = "userItemList2center"

            if (this.props.disable) var useritemclass = "userItemListDisable"
            retour.push(
              
                <td width={width}  className={useritemclass} key={key} >
                        <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    
                    {this.displayOneChampActivity(iactivity,i)}
              </td>)
        }
        return ( retour)
            
    }

    displayAllActivityList()
    {



        var retour=[];
        var maxi = this.props.datas.length;
        if (maxi === undefined) maxi=0;
        
        var firstline = this.props.firstline;
        var lastline = this.props.lastline;
        if (firstline<0) firstline=0;
        if (lastline>maxi) lastline=maxi;

        console.log("from", firstline," to ",lastline , maxi);

        for (var i=firstline; i <= lastline;i++)
        
        {
            {
                var key = "activityitem" + i;
                var retl =  (<tr heightkey={key}>{this.displayOneActivityList(i) } </tr>)
                retour.push(retl)
            }
        }
        return retour;
    }


    render()
    {
        
        return (
            
            <>
            
            {this.displayAllActivityList()}
            </>
            )
    }
}



//  ****************************************************************************************
//  ListActivityHeader
//  ****************************************************************************************
class ListActivityHeader extends React.Component
{
    constructor(props)
    {
        super(props);


    }

createAllHeaderColumns()
{
    var retour = []
    var styleh = "headerList"
    if (this.props.disable)
        styleh = "headerList-disable"
    for (var i=0; i<this.props.columms.length; i++)
    {
    var width = this.props.columms[i].width + "%"
    
    
    var key="activityheader" + i;
      retour.push(
          
          <td width={width} className={styleh} key={key} >
          
              {this.props.columms[i].text}
          </td>)
    }
    return ( retour)
        
    
}


render()
{
    return (
        <>
                {this.createAllHeaderColumns()}
        </>
    )
}

}



//  ****************************************************************************************
class ListActivity extends React.Component
{
    constructor(props)
    {
        super(props);
        this.columns = [
            {text:"Date", width:5, zone: "madate"},
            {text:"ip", width:5, zone: "ip"},
            {text:"Action", width:15, zone: "action"},
            {text:"Licence", width:3, zone: "licence"},
            {text:"Status", width:3, zone: "status"},
            {text:"Nom", width:10, zone: "nom"},
            {text:"Serial", width:10, zone: "serial"},
            {text:"Date Fin", width:5, zone: "madatefin"},
            {text:"User", width:5, zone: "username"}
        ]

    }

    render()
    {
    
        return (
            <>
                <table className="tableList" cellSpacing={5} cellPadding={0}>
                    
                    
                    <thead>
                    <tr>
                    <ListActivityHeader columms = {this.columns}  disable={this.props.disable}/>          
                    </tr>
                    </thead>
                    <tbody>
                    <ListActivityDatas columms = {this.columns} datas={this.props.liste} 
                        firstline = {this.props.firstline}
                        lastline = {this.props.lastline}
                        disable={this.props.disable}
                        detail = {this.props.detail}
                        onmodify={this.props.onmodify}
                        ondelete={this.props.ondelete}
                    />                    
    
                    </tbody>
                </table>
            </>
            
    
    
        )
    }
    


}
//  ****************************************************************************************



//  ****************************************************************************************
class Activity  extends React.Component 
{


    constructor(props)
    {
        super(props);

        this.state = 
        {
            liste:{},
            nbrows:0,
            nbdisplay: 4,
            currentPage: 1,
            nbPerPage: 12,
            listeDisable: false,

        }



        this.onClick = this.onClick.bind(this);
        this.onChangePage = this.onChangePage.bind(this);


    }






    onChangePage(value)
    {
        
        if (value == "all")
            this.setState({nbPerPage: this.state.liste.length})
        else
            this.setState({nbPerPage: parseInt(value)})


    }

    onClick(event)
    {

        if ((event.detail.value) && (event.detail.datas !== undefined))
        {
            var text = event.detail.datas;



            if (this.state.nbrows != 0)
            {
                var nbpages = Math.ceil( this.state.nbrows / this.state.nbPerPage);
                var currentPage = this.state.currentPage;


                switch (text)
                {
                case "next":
                    {
                        if (currentPage==0)
                            currentPage=1;
                        else
                        {
                            if (currentPage < nbpages)
                                currentPage++;
                        }
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "previous":
                    {
                        if (currentPage==0)
                            currentPage=1;
                        else
                        {
                            if (currentPage >1)
                                currentPage--;
                        }
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "first":
                    {
                        currentPage=1;
                        this.setState({currentPage: currentPage})
                    }
                    break;
                case "last":
                    {
                        currentPage=nbpages;
                        this.setState({currentPage: currentPage})
                    }
                    break;
                default:
                    currentPage = parseInt(text)
                    this.setState({currentPage: currentPage})
                    break;
                }
            }
        }
    }

    
    



    renderPaneCenter()
    {
        console.log("render center .............................")

        
        var firstline= ((this.state.currentPage-1) * this.state.nbPerPage) ;
        var lastline=firstline + this.state.nbPerPage-1;
        if (lastline >= this.state.nbrows) lastline = this.state.nbrows -1;

        var classdiv="div-activity-center-all";

        var overflow={"overflow-y":"scroll"};
        if (this.state.listeDisable) overflow  = {overflow:"hidden"};

        return (
        <div className={classdiv} style={overflow}>

            <ListActivity
                        liste={this.state.liste} 
                        disable={this.state.listeDisable}
                        current = {this.state.currentPage}
                        firstline ={firstline}
                        lastline ={lastline}

                        />            

        
        </div>);
    }
    renderPageListe()
    {
        

        var nbrow = this.state.nbrows;
        if (nbrow === undefined) nbrow = 0;
        var classdiv="div-licences-pagination-all";
        if ((this.state.editmode) || (this.state.supmode) || (this.state.createmode) )
            classdiv = "div-licences-pagination";
        return (
        <div className={classdiv}>
                <Pagination disable = {this.state.listeDisable} current={this.state.currentPage} 
                onClick ={this.onClick} key={this.state.currentPage.toString()} 
                count={this.state.nbrows} nbdisplay={this.state.nbdisplay} nbbypage = {this.state.nbPerPage}/>
        </div>
        )
    }

    componentDidMount()
    {
        this.getActivity();
    }


getActivity()
{
    var myHeaders = new Headers();
    var datas = {};
    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "allactivity"
    console.log(apiurl);
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        console.log("Actual datas:", actualData, actualData.datas.length)

        var nbrows = actualData.datas.length;
        this.setState({liste:actualData.datas, 
            nbrows: nbrows});
    
    
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });

}

render () 
{

    
    console.log("render.............................")
    var userData = getUserData()
    var priv = userData.user.priv;
    if ( (priv & 1)==0)
    {
        this.props.navigate ('/')
        return (null)
    }
    return (
    <div className="div-container">
        <div className="div-entete">
            <div className="div-boutonup">
            <span data-tip="Ajouter un téléchargement">
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    

            </span>
            </div>
            <div className="div-titre">
                <PageTitle image={imgactivity} title="Dernières Activités"  {...this.props}/>
            </div>
        </div>
        <div className="div-bodylicences">
            {this.renderPaneCenter()}
            
        </div>

        {this.renderPageListe()}


    </div>
    
    )
}




}

export default Activity

