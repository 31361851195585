import React from "react";
import PageTitle from "../gadgets/pagetitle/pagetitle"

import ListeMail from "./listmail";
import Cron from "./cron";

import imgemail from "./assets/imgemail.png"

import {config, getUserData}  from '../config'
import ReactToolTip from 'react-tooltip'

import MailsEditor from "./mailseditor";

import '../users/styles/styles.css';
import './styles/styles.css';



class Email extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = 
        {
            disable: false,
        }
       
        setTimeout(() => 
        {
            var userData = getUserData()
            var priv = userData.user.priv;
            if ( ((priv & 1)==0) && ((priv & 2) == 0))
            {
                this.props.navigate ('/')
            }
                    
        }, (2000));
    

    }



    

    render()
    {

        console.log("render.............................")
        var userData = getUserData()
        var priv = userData.user.priv;
        if ( ((priv & 1)==0) && ((priv & 2) == 0))
        {
            
            return (null)
        }
        return (
        <div className="div-container">
            <div className="div-entete">
                <div className="div-boutonup">
                <span data-tip="Configuration Emails">
                        <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    
    
                </span>
                </div>
                <div className="div-titre">
                    <PageTitle image={imgemail} title="Configuration Emails"  {...this.props}/>
                </div>

                <div className="div-email-left">
                    <MailsEditor/>
                </div>
                <div className="div-email-full-right">
                <div className="div-email-right">
                <ListeMail/>
                </div>
                <div className="div-email-cron-right">
                <Cron/>
                </div>
                </div>
            </div>
    
        </div>
        
        )
    

    }
}

export default Email