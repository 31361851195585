// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-div
{
    float:right;
    width:70px;
    height:70px;
    margin-top: 10px;
    border-style: 1px;
    -webkit-user-select: none;
            user-select: none; 
}

.login-name
{
    font-size: 9px;
    text-align: center;
    float:left;
    margin-top: -3 px;
    width: 70px;
    height: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
    display: inline-block;
    -webkit-user-select: none;
            user-select: none; 


}
.login-datas-text
{
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space:nowrap;
    -webkit-user-select: none;
            user-select: none; 
    margin-bottom: 10px;
}

.login-div-image
{
    width:32px;
    height: 32px;
}
.login-datas
{

    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 70px;
    left: calc(100% - 250px);
    width:200px;
    display:flex;
    flex-direction: column;
    
    margin-top: 2px;
    border-radius: 10px;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    box-shadow: 8px 8px 8px  #252525;
    
    background-color: #Ab95c1;
    z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/gadgets/login/styles.css"],"names":[],"mappings":"AAAA;;IAEI,WAAW;IACX,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;;IAEI,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,yBAAiB;YAAjB,iBAAiB;;;AAGrB;AACA;;IAEI,kBAAkB;IAClB,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;;IAEI,UAAU;IACV,YAAY;AAChB;AACA;;;IAGI,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,wBAAwB;IACxB,WAAW;IACX,YAAY;IACZ,sBAAsB;;IAEtB,eAAe;IACf,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,gCAAgC;;IAEhC,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".login-div\n{\n    float:right;\n    width:70px;\n    height:70px;\n    margin-top: 10px;\n    border-style: 1px;\n    user-select: none; \n}\n\n.login-name\n{\n    font-size: 9px;\n    text-align: center;\n    float:left;\n    margin-top: -3 px;\n    width: 70px;\n    height: 10px;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space:nowrap;\n    display: inline-block;\n    user-select: none; \n\n\n}\n.login-datas-text\n{\n    text-align: center;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space:nowrap;\n    user-select: none; \n    margin-bottom: 10px;\n}\n\n.login-div-image\n{\n    width:32px;\n    height: 32px;\n}\n.login-datas\n{\n\n    font-size: 14px;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    padding-left: 10px;\n    padding-right: 10px;\n    position: absolute;\n    top: 70px;\n    left: calc(100% - 250px);\n    width:200px;\n    display:flex;\n    flex-direction: column;\n    \n    margin-top: 2px;\n    border-radius: 10px;\n    border-color: white;\n    border-style: solid;\n    border-width: 1px;\n    box-shadow: 8px 8px 8px  #252525;\n    \n    background-color: #Ab95c1;\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
