


import {site}  from './site'

var https="https://"; if (!site.https) https="http://";


var config = {
    "production":
    {
    },


    "development":
    {
        "api_url": https + site.apis + "." + site.domainname+"/" ,
        "https" : site.https,
        "downloadpath": site.downloadpath,
    },
}




function getListTypeMail()
{
    return [
        {id:10, lib:"Mail envoyé à l'auteur à la création d'une licence."},
        {id:11, lib:"Mail envoyé à l'auteur à la modification d'une licence."},
        {id:12, lib:"Mail envoyé à l'auteur à l'approche de la date de fin de sa licence."},
        {id:13, lib:"Mail envoyé à l'auteur si sa licence expire dans 1 jour."},
        {id:14, lib:"Mail envoyé à l'auteur si sa licence expire aujourd'hui."},
        {id:15, lib:"Mail envoyé à l'auteur si sa licence est expirée."},
        {id:16, lib:"Mail envoyé aux auteurs à partir de la fenêtre de sélection de licences."},
	{id:17, lib:"Mail envoyé en interne en cas de mise à jour de Cappella HD."},        

    ]
}



var userData = { 
    user: 
      {id: 0,firstname:"", lastname:"", email:"",image:null, priv:0, phone:"", author:false, userLicence:false},
  }






function getUserData()
{
   return userData;
}

function setUserData(name)
{
    userData = name;
}

function getActionLib (actionid)
{
    var action="";
    switch (actionid)
    {
        case 1:
            action= "Création licence.";
            break;
        case 2:
            action="Modification licence.";
            break;
        case 3:
            action="Validation des droits.";
            break;
        case 4:
            action="Droits refusés, licence inactive.";
            break;
        case 5:
            action="Droits refusés, Date dépassée.";
            break;
        case 6:
            action="Droits refusés, Adresse MAC non valide.";
            break;
        case 7:
            action="Droits refusés, Nombre maximum de machine atteint.";
            break;

        case 9:
            action="Validation des droits (Etudiant).";
            break;
        case 10:
            action="Connexion au site web.";
            break;

        case 11:
            action = "Suppression serial 1"; break;

        case 12:
            action = "Suppression serial 2"; break;
        case 13:
            action="Connexion au site refusée (email incorrect)."; break;
    
        case 20:
            action="Téléchargement Cappella HD."; break;
        case 21:
            action="Téléchargement Cappella HD (Doc)."; break;
        case 22:
            action="Téléchargement Cappella HD (complement)."; break;
            
        case 30:
            action="Téléchargement Plugin VO."; break;
        case 31:
            action="Téléchargement Plugin VO (Doc)."; break;
        case 32:
            action="Téléchargement Plugin VO (complement)."; break;
    
        default:
            action="Action inconnue.";
            break;
    }

    return action;



}

export { config , getUserData, setUserData, getActionLib,getListTypeMail}