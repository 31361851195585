import React from "react";
import ReactToolTip from 'react-tooltip'
import Bouton from "../gadgets/bouton/bouton"

import DatePicker, { DateObject } from "react-multi-date-picker"

import imgback from "../licences/assets/back.png"
import imgvalide from "../licences/assets/valider.png"
import imgstart from "./assets/start.png"
import imgrefresh from "./assets/refresh.png"

import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";

import Icon from "react-multi-date-picker/components/icon"


import {config, getUserData}  from '../config'


import './styles/styles.css';



class Cron extends React.Component
{

    constructor(props)
    {
        super(props)

        this.state = 
        {
            enabled : true,
            starttime: "12:00",

            lastenabled: true,
            laststarttime: "12:00",

            disablebutton: true,
            lasttime: "?",
        }

        this.handleChangeEnabled = this.handleChangeEnabled.bind(this)
        this.handleChangeTime = this.handleChangeTime.bind(this)

        this.abortEdit = this.abortEdit.bind(this)
        this.validEdit = this.validEdit.bind(this)
        this.starttimeInput =React.createRef();
        this.starttimePicker = React.createRef();
        this.lance = this.lance.bind (this)
        this.getLastTime = this.getLastTime.bind(this)
        this.refresh = this.refresh.bind(this)

    
    }


    getLastTime()
    {
        var myHeaders = new Headers();
    
        var myInit = { method: 'GET',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default' };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/5"
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            console.log("Get Param Email 0: ",actualData)


            if (actualData.datas.length > 0)
            {
            
                this.setState(
                    {
                    lasttime: actualData.datas[0].charparam,

                    })
                }

                
            
        })
        .catch((err) => {
          console.log(err);
        });


    }

    componentDidMount()
    {
        var myHeaders = new Headers();
    
        var myInit = { method: 'GET',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default' };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/1"
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            console.log("Get Param Email 0: ",actualData)


            if (actualData.datas.length > 0)
            {
            
                this.setState(
                    {
                    disablebutton: true,
                    laststarttime: actualData.datas[0].charparam,
                    lastenabled: actualData.datas[0].intparam != 0,
                    starttime: actualData.datas[0].charparam,
                    enabled: actualData.datas[0].intparam != 0,

                    })
                }

                this.getLastTime();
                
            
        })
        .catch((err) => {
          console.log(err);
        });


    }


    refresh(event)
    {
        if (event.detail.value)
        {
            this.getLastTime();
        }

    }

    lance(event)
    {
        if (event.detail.value)
        {
            var myHeaders = new Headers();
    
            var myInit = { method: 'GET',
                           headers: myHeaders,
                           mode: 'cors',
                           cache: 'default' };
        
            var apiurl = config[process.env.NODE_ENV].api_url + "startcron"
            fetch(apiurl,myInit)
            .then((response) => {
              if (!response.ok) {
                throw new Error(
                  `This is an HTTP error: The status is ${response.status}`
                );
              }
              
              return response.json();
            })
            .then((actualData) => {
        
                this.getLastTime();
                
            })
            .catch((err) => {
              console.log(err);
            });
    
        }
    }



    validEdit(event)
    {
        if (event.detail.value)
        {
                var intparam=0;
                if (this.state.enabled) intparam=1;

                var datas =
                {
                    id:1, //
                    intparam: intparam,
                    charparam: this.state.starttime
                }

                var myHeaders = new Headers();
                myHeaders.append('Accept','application/json');
                myHeaders.append('Content-Type','application/json');
                var myInit = { method: 'PUT',
                               headers: myHeaders,
                               mode: 'cors',
                               cache: 'default',
                               body: JSON.stringify(datas)
                                };
            
                var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/1"
                fetch(apiurl,myInit)
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(
                      `This is an HTTP error: The status is ${response.status}`
                    );
                  }
                  
                  return response.json();
                })
                .then((actualData) => {
            
                    try{
        
                        this.setState(
                            {
                            disablebutton: true,
                            laststarttime: this.state.starttime,
                            lastenabled: this.state.enabled
                            }
                        )
                        
                    }
                    catch(e)
                    {
                        console.log(e)
                    }
        
                    
                })
                .catch((err) => {
                  console.log(err);
                });
            
            }
        
        
        
    }


    abortEdit( event)
    {
        if (event.detail.value)
        {

            this.setState ({enabled:this.state.lastenabled, starttime:this.state.laststarttime, disablebutton: true})

        }
    

    }


    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }


    formatHeure(date)
    {
        return this.padTo2Digits(date.hour)+":"+this.padTo2Digits(date.minute)
    }





    fromStringTimeToDateObject(stringDate)
    {
        var newdate = new Date()
        var heure = parseInt (stringDate.substring(0,2))
        var minute = parseInt (stringDate.substring(3,5))

      newdate.setMinutes(minute)
      newdate.setHours(heure)
      return newdate;
    }


    handleChangeTime(date )
    {
        var disable=false;
        var date0 = this.formatHeure(date);

        if (
            (date0 == this.state.laststarttime) &&
            (this.state.enabled == this.state.lastenabled)
            )
            disable=true;

        this.setState({starttime: date0, disablebutton: disable});
    }


    handleChangeEnabled(e)
    {

        var disable=false;

        if (
            (e.target.checked == this.state.lastenabled) &&
            (this.state.starttime == this.state.laststarttime)
            )
            disable=true;

        this.setState({enabled:e.target.checked,disablebutton:disable});
    }

    openCalendarStartTime()
    {
      this.starttimePicker.current.openCalendar()
    }

    render ()
    {
        ReactToolTip.hide();
        return(
            <div className='formListMailAll'>
                <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                <div className="formEmails-titre">
                    Paramètres envoi journalier des emails
                </div>
            
                <div style={{textAlign:"left", marginLeft:"20px"}}>
                <input 
                        
                        disabled= {this.props.disabled}
                        name="detail"
                        type="checkbox"
                        onChange={e => this.handleChangeEnabled(e)}
                        
                        checked={this.state.enabled}
                        required />
                        &nbsp;Autoriser l'envoi journalier<br /><br />

                </div>


                    {/*  ***************** HEURE DE DEBUT   ******************/}


                    <table><tr>

                    <td>
                    <div style={{textAlign:"left", marginLeft:"20px", marginBottom:"20px"}}>
                        Heure d'envoi des emails :&nbsp;&nbsp;
                        <input className="cron-time-input"
                        readOnly
                        name="starttime"
                        type="text"
                        ref={this.starttimeInput}
                        onClick={this.openCalendarStartTime.bind(this)}
                        value={this.state.starttime}
                        required />
                    </div>

                    </td><td>
                    <div style={{marginTop:"-16px"}}>
                    <DatePicker   render={<Icon/>} 
                        className="bg-dark red"
                        
                        format="HH:mm"
                        calendarPosition="right-end"
                        ref = {this.starttimePicker}
                        onChange = { (date) => {   this.handleChangeTime(date)             }}
                        value = {this.fromStringTimeToDateObject(this.state.starttime)}
                        disableDayPicker
                        plugins={[
                            <TimePicker 
                            hideSeconds
                            />
                        ]} 
                    />
                    </div>
                    </td>
                    <td>
                    <div style={{marginTop:"-16px"}}>
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                    <span data-tip="Annuler les modifications">
                    <Bouton 
                            width="32" height="32" top="0"
                            image={imgback} 
                            disable= {this.state.disablebutton}
                            key = {"abort"}
                            onButtonClick={this.abortEdit}
                            buttonName="bAbort"/>
                    </span>
                    <span data-tip="Valider les modifications">

                    <Bouton 
                            width="32" height="32" top="0"
                            image={imgvalide} 
                            disable= {this.state.disablebutton}
                            key = {"valide"}
                            onButtonClick={this.validEdit}
                            buttonName="bValid"/>
                    </span>
                    </div>
                    </td>
                    <td>
                    <div style={{marginTop:"-16px"}}>
                    <span data-tip="Lancer une fois">
                    &nbsp;&nbsp;&nbsp;
                    <Bouton 
                            width="32" height="32" top="0"
                            image={imgstart} 
                            key = {"lancer"}
                            onButtonClick={this.lance}
                            buttonName="bLance"/>
                    </span>
                    </div>
                    </td>
                        
                    </tr></table>

                    Date du dernier envoi journalier : {this.state.lasttime}<br/>
                    <span data-tip="Reafraichir la date">
                    &nbsp;&nbsp;&nbsp;
                    <Bouton 
                            width="32" height="32" top="0"
                            image={imgrefresh} 
                            key = {"refresh"}
                            onButtonClick={this.refresh}
                            buttonName="bRefresh"/>
                    </span>



            </div>
        )


    }

}

export default Cron;