import React from "react";

import attention from "./assets/attention.png"

import "./stylespopup.css";


// https://www.textstudio.fr/logo/texte-3d-rouge-effet-brillant-297
// black han sans egular

class ImgBoutons extends React.Component
{
    constructor(props)
    {
        super(props)
    }
//<img src={this.props.src} className="modal-image" height={42}/>
    render ()
    {
        if (this.props.buttons.length == 0) return (null)
        var retour = []
        for (var i=0; i < this.props.buttons.length; i++)
        {
            retour.push (
                <div className="modal-button" onClick={this.props.buttons[i].onvalid}>
                <span>{this.props.buttons[i].text}</span>
                </div>
            )
        }
        return (retour)
    }
}

class Modal extends React.Component
{

    constructor(props)
    {
        super(props)
    }

    render()
    {

        if (!this.props.value.display)
            return (null)
        return (

        <>
        <div className="darkBG"/>
        <div className="centered">
            <div className="modal">
            <div className="modalHeader">
                <h5 className="heading">{this.props.value.title}</h5>
            </div>
            <button className="closeBtn" onClick={this.props.value.oncancel}>
                x
            </button>
            <div className="modalContent-icon">
                <img src={attention} height={64} />
                </div>
            <div className="modalContent" >
                <div className="modalContent-text">
                {this.props.value.text}
                </div>
            </div>
            <div className="modal-div-buttons">
            <ImgBoutons buttons = {this.props.value.buttons}/>
            </div>
            
            </div>
        </div>
        </>      
    )
    };

}
export default Modal;