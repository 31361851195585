
import React from "react";
import Bouton from "../gadgets/bouton/bouton"



import newversion from "../upload/assets/newversion.png"
import oldversion from "../upload/assets/oldversion.png"
import stableversion from "../upload/assets/stableversion.png"
import testversion from "../upload/assets/debug.png"
import setup from "../upload/assets/setup.png"
import plugin from "../upload/assets/plugin.png"
import doc from "../upload/assets/doc.png"
import complement from "../upload/assets/complement.png"


import download from "../home/assets/download.png"


import {config, getUserData}  from '../config'
import ReactToolTip from 'react-tooltip'



import '../users/styles/styles.css';

import "./styles/styles.css"


class OneSoft extends React.Component
{
    constructor(props)
    {
        super(props)
        this.startDownLoad = this.startDownLoad.bind(this)
    }


    async download3(idupload, reellicence) 
    {
    
        const link = document.createElement("a");
        link.href = config[process.env.NODE_ENV].api_url + "download/"+idupload+"/"+reellicence;
        //link.download = dataurl;
        link.click();
    }



    startDownLoad(event)
    {

        if (event.detail.value)
        {

            
            var userData = getUserData()
            var licence = userData.user.licence;

            if (licence === undefined) licence=0;
            var fullpath = config[process.env.NODE_ENV].downloadpath+ event.detail.datas.file;

            var idupload = event.detail.datas.id;
            var iduser = userData.user.id;
            var userLicence = userData.user.userLicence;


            //console.log("iduser: ", iduser," idupload: ", idupload, " userlicence: ",userLicence, "licence: ",licence)



            var reelLicence = 0;

            if ((userLicence) && (licence != 0)) reelLicence = licence;

            this.download3 (idupload, reelLicence);

        }


    }

    renderStatus(status, typefile)
    {
        if (typefile != 0) return (null)
        if (status == 0) return (null)

        if (status ==1) return (<img src={newversion} height="100%"/>)
        if (status ==2) return (<img src={stableversion} height="100%"/>)
        if (status ==3) return (<img src={testversion} height="100%"/>)
        return (null)

    }


    renderTexteVO(status, typefile,sha256)
    {



        var titre="";
        var texte1="";
        var texte2="";
        switch (typefile)
        {
            case 0: // setup
            {
                titre = "Programme d'installation du Plugin Word CappellaVO"
                if (status == 1)
                {
                    texte1 = "Programme d'installation de la dernière version du Plugin Word Cappella VO.";
                    texte2 = "VERSION RECOMMANDEE";

                }
                if (status == 2)
                {
                    texte1 = "Programme d'installation de la version stable précédente du Plugin Word Cappella VO.";
                }
                if (status == 3)
                {
                    texte1 = "Programme d'installation en test non validée du Plugin Word Cappella VO.";
                }
            }
            break;

            case 1: // Documentation
            {
                titre = "Documentation du Plugin Word Cappella VO"
                texte1 = "Documentation de la dernière version du Plugin Word Cappella VO.";
                texte2 = "Format PDF";

            }
            break;

            case 2: // Complement
            {
                titre = "Logiciel complémentaire"
                texte1 = "Programme d'installation de QUICK TIME PLAYER.";
                texte2 = "Installation obligatoire";
            }

            break;
        }

        return (
            <>
                <span className= "span-onedoc-titre">{titre}<br/></span>
                <span className= "span-onedoc-texte1"><br/>{texte1}<br/></span>
                <span className= "span-onedoc-texte2">{texte2}<br/></span>
                <span className= "span-onedoc-texte3">sha256: {sha256}<br/></span>
                
            </>
        )



    }




    renderTexte(status, typefile, sha256, typesoftware=0)
    {


        if (typesoftware==1)
            return this.renderTexteVO(status, typefile, sha256)

        var titre="";
        var texte1="";
        var texte2="";
        switch (typefile)
        {
            case 0: // setup
            {
                titre = "Programme d'installation Cappella HD"
                if (status == 1)
                {
                    texte1 = "Programme d'installation de la dernière version de Cappella HD.";
                    texte2 = "VERSION RECOMMANDEE";

                }
                if (status == 2)
                {
                    texte1 = "Programme d'installation de la version stable précédente de Cappella HD.";
                }

                if (status == 3)
                {
                    texte1 = "Programme d'installation de la version en test non validée de Cappella HD.";
                }
            }
            break;

            case 1: // Documentation
            {
                titre = "Documentation Cappella HD"
                texte1 = "Documentation de la dernière version de Cappella HD.";
                texte2 = "Format PDF";

            }
            break;

            case 2: // Complement
            {
                titre = "Logiciel complémentaire"
                texte1 = "Programme d'installation de QUICK TIME PLAYER.";
                texte2 = "Installation obligatoire";
            }

            break;
        }

        return (
            <>
                <span className= "span-onedoc-titre">{titre}<br/></span>
                
                <span className= "span-onedoc-texte1"><br/>{texte1}<br/></span>
                <span className= "span-onedoc-texte2">{texte2}<br/></span>
                <span className= "span-onedoc-texte3">sha256: {sha256}<br/></span>
            </>
        )



    }

    render ()
    {

        var imagegauche = null;
        var datatip="";

        switch (this.props.datas.typefile)
        {
            case 0: imagegauche = setup; datatip="Télécharger Cappella HD";break;
            case 1: imagegauche = doc; datatip="Télécharger la documentation";break;
            case 2: imagegauche = complement; datatip="Télécharger le complément";break;
        }
        var datasButtons =
        {
            file: this.props.datas.file,
            fullpath: this.props.datas.fullpath,
            id: this.props.datas.id,
        }

        return (
            <>
            <div className ="div-onedoc-imagegauche">
                <img src={imagegauche} height="100%"/>
            </div>
            <div className="div-onedoc-imagegauchesuite">
                {this.renderStatus(this.props.datas.status, this.props.datas.typefile)}
            </div>
            <div className="div-onedoc-textecentre">
                <span className="span-onedoc-textecentre">
                {this.renderTexte(this.props.datas.status, this.props.datas.typefile,this.props.datas.sha256,this.props.datas.typesoftware)}
                </span>
            </div>
            <div className="div-onedoc-download">

                <table width="100%">
                    <tr >
                        <td width="15%" className="td-onedoc-titre">Date</td>
                        <td width="12%" className="td-onedoc-titre">Version</td>
                        <td width="58%" className="td-onedoc-titre">Fichier</td>
                        <td width="15%" className="td-onedoc-titre">&nbsp;</td>
                    </tr>


                    <tr>
                    <td>
                        {this.props.datas.madate}
                    </td>
                    <td>
                        {this.props.datas.version}
                    </td>
                    <td>
                        {this.props.datas.file}
                    </td>
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                    <span data-tip={datatip}>
                    <Bouton datas={datasButtons} image={download} 
                        width={48} height={48} onButtonClick={this.startDownLoad}/>
                    </span>
                    </tr>
                </table>
                
            </div>
            </>
        )
        

    }

}
export default OneSoft
