import React from "react";

import inconnu from "../../users/assets/inconnu.png"
import simpleuser from "../../users/assets/simpleuser.png"
import Bouton from "../bouton/bouton"

import priv1 from "../../users/assets/priv1.png" // administrateur
import priv2 from "../../users/assets/priv2.png" // Configuration
import priv4 from "../../users/assets/priv4.png" // licence
import priv8 from "../../users/assets/priv8.png" // telechargement
import priv16 from "../../users/assets/priv16.png" // telechargement cappellahd

import cappellaLogoSmalll from "../../cappella/assets/cappellaLogoSmalll.png"

import deconnect from "../../home/assets/deconnect.png"
import edituser from "../../users/assets/edituser.png"
import emissions from "../../telechargements/assets/cappellatelechargements.png"
import ReactToolTip from 'react-tooltip'

import LoginEditUser from "../loginedituser/loginedituser";
import { setUserData, getUserData } from '../../config';

import './styles.css';





class Login extends React.Component
{
    
    constructor(props)
    {
        super(props)

        this.state =
        {
                displayPopup: false,
                displayModif: false,
        }



        this.gereBouton = this.gereBouton.bind(this)
        this.onfinishedit = this.onfinishedit.bind(this)
    }


    displayPriv(n)
    {
        var retour=[]
        if(n & 1) n=255
        var tstyle="login-div-image"
        if (n & 1) retour.push(<img className={tstyle} src={priv1} data-tip="Administrateur"/>)
        if (n & 2) retour.push(<img className={tstyle} src={priv2} data-tip="Configurations"/>)
        if (n & 4) retour.push(<img className={tstyle} src={priv4} data-tip="Ajouter/Modifier licences"/>)
        if (n & 8) retour.push(<img className={tstyle} src={priv8} data-tip="Ajouter/Modifier téléchargements"/>)
        if (n & 16) retour.push(<img className={tstyle} src={priv16} data-tip="Télécharger CappellaHD"/>)

        return (retour)

    }


    gereBouton(event)
    {

        var dataUser = getUserData()
        if (event.detail.value)
        {
            switch(event.detail.button)
            {
                case "cappella":
                    if (this.props.navigate)
                    {
                        this.props.navigate ('/cappella')
                    }
                case "licences":
                    if (this.props.navigate)
                    {
                        this.props.navigate ('/licences')
                    }
                    break;
                case "telechargements":
                    if (this.props.navigate)
                    {
                        this.props.navigate ('/upload')
                    }
                    break;
                case "configuration":
                    if (this.props.navigate)
                    {
                        this.props.navigate ('/textes')
                    }
                    break;
                case "modif":
                    this.setState(
                        {
                            displayPopup: false,
                            displayModif: true,
                        })
                    break;
                case "deconnect":

                    if (this.props.navigate)
                    {
                        var dataUser = getUserData()
                        dataUser.user.id = 0
                        dataUser.user.privileges=0
                        dataUser.user.userLicence=false
                        dataUser.user.userLicence=0
                        dataUser.user.lastname=""
                        dataUser.user.email=""
                        dataUser.user.tauqueune="";

                        setUserData(dataUser)
                        this.props.navigate("/")
                    }

                    break;

                }
        }
    }

    onfinishedit(datas)
    {

        if (datas)
        {

            var dataUser = getUserData()
            dataUser.user.lastname = datas.lastname
            dataUser.user.firstname = datas.firstname
            dataUser.user.email = datas.email
            dataUser.user.image  = datas.image
            
            setUserData(dataUser)

        }
        this.setState(
            {
                displayPopup: false,
                displayModif: false,
            })
        console.log(datas)
    }

    displayBoutonPerso(privileges)
    {

        if (((privileges & 1) !=0 ) || ((privileges & 8) !=0))
        {
            return (
                <span data-tip="Gestion des téléchargements">
                <Bouton 
                    width="56" height="56" 
                    image={priv8} 
                    disable={false}
                    buttonName="telechargements"
                    onButtonClick={this.gereBouton}
                />
            </span>
            )
        }

        if ((privileges& 4) != 0)
        {
            return (
                <span data-tip="Gestion des licences">
                <Bouton 
                    width="56" height="56" 
                    image={priv4} 
                    disable={false}
                    buttonName="licences"
                    onButtonClick={this.gereBouton}
                />
            </span>
            )
        }

        if ((privileges& 2) !=0)
        {
            return (
                <span data-tip="Configurations...">
                <Bouton 
                    width="56" height="56" 
                    image={priv2} 
                    disable={false}
                    buttonName="configuration"
                    onButtonClick={this.gereBouton}
                />
            </span>
            )
        }

        if ((privileges& 16) !=0)
        {
            return (
                <span data-tip="Téléchargement Cappella...">
                <Bouton 
                    width="56" height="56" 
                    image={cappellaLogoSmalll} 
                    disable={false}
                    buttonName="cappalle"
                    onButtonClick={this.gereBouton}
                />
            </span>
            )
        }


    }

    displayModifUser()
    {
        if (this.state.displayModif)
        {
            return(<LoginEditUser {...this.props} onfinish={this.onfinishedit}/>)
        }   
        else
            return (null)
    }

    displayPopup()  
    {


        if (!this.state.displayPopup) return (null)

        var lastname="?"
        var firstname=""
        var email=""
        var image=""
        var id=0;
        var privileges=0;
        try{

            var dataUser = getUserData()

            id= dataUser.user.id
            lastname= dataUser.user.lastname
            firstname= dataUser.user.firstname
            email= dataUser.user.email
            image= dataUser.user.image 
            privileges= dataUser.user.priv
    
        }
        catch(e)
        {
            id=0;
        }

        if (id== 0)return (null)


        return(
            <>
            <div className="login-datas"
                
                onMouseEnter={this.onImgMouseEnter.bind(this)}
                onMouseLeave={this.onImgMouseLeave.bind(this)}
        
            >
                <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    
                <div className = "login-datas-text">{email}</div>
                <div className = "login-datas-text">{this.displayPriv(privileges)}</div>
                <div className = "login-datas-text">{lastname+" "+firstname}</div>
                <div className = "login-datas-text">
                <span data-tip="Déconnexion">
                    <Bouton 
                        width="56" height="56" 
                        image={deconnect} 
                        disable={false}
                        buttonName="deconnect"
                        onButtonClick={this.gereBouton}
                    />
                </span>
                <span data-tip="Mes informations">
                    <Bouton 
                        width="56" height="56" 
                        image={edituser} 
                        disable={false}
                        buttonName="modif"
                        onButtonClick={this.gereBouton}
                    />
                </span>
                {this.displayBoutonPerso(privileges)}
                </div>

            </div>
            
            
            </>

        )
    }

    onImgMouseEnter()
    {
        console.log("on mouse enter")
        this.setState( {displayPopup:true})
    }

    onImgMouseLeave()
    {
        console.log("on mouse over")
        this.setState( {displayPopup:false})
    }

    //{id: 0,firstname:"", lastname:"", email:"",image:null, priv:0},
    displayImageUser(id,image)
    {
        
        if (id == 0)
        {
            return (
                <>
                    <img src={inconnu} width={64} height={64} 
                    onMouseEnter={this.onImgMouseEnter.bind(this)}
                    onMouseLeave={this.onImgMouseLeave.bind(this)}/>
                </>
            )
        }
        if ((image==null ) || (image.length < 5))
        {
            return (
                <img src={simpleuser} width={64} height={64} 
                onMouseEnter={this.onImgMouseEnter.bind(this)}
                onMouseLeave={this.onImgMouseLeave.bind(this)}/>
            
            )
        }


        return(
        <img src={image} width={64} height={64} 
                style= {{marginLeft:"5px",borderStyle:"solid",borderWidth:"3px",borderColor:"#7B6591",borderRadius:"34px"}}
                onMouseEnter={this.onImgMouseEnter.bind(this)}
                onMouseLeave={this.onImgMouseLeave.bind(this)}/>
        )

    
    }

    render()
    {
        var displayName = "Connectez-vous";
        var id = 0
        var image= null;
        try
        {
            var dataUser = getUserData()
            id = dataUser.user.id
            displayName = dataUser.user.lastname
            image = dataUser.user.image
            
            
        }
        catch(e)
        {
            console.log("???")
        }
        
        return (
            <>
            <span>
            <div className="login-div">
            {this.displayImageUser(id,image)}
            <span className="login-name">{displayName}</span>
            </div>

            {this.displayPopup()}
            </span>
            {this.displayModifUser()}
            </>
        )
    }

}

export default Login

