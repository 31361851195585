import React from "react";

import './styles.css';

class Bouton extends React.Component {
  
    constructor(props)
    {
        super(props)
        // events
        this.onMouseEnter = this.onMouseEnter.bind(this)
        this.onMouseOut = this.onMouseOut.bind(this)
        this.onMouseDown = this.onMouseDown.bind(this)
        this.onMouseUp = this.onMouseUp.bind(this)


        this.elementImageBouton = null;
        this.setElementImageBouton = this.setElementImageBouton.bind(this)

        var caption="";
        var selected = false;
        var text = "";

        if ('caption' in this.props) caption = this.props.caption;
        if ('text' in this.props) text = this.props.text;
        if ('selected' in this.props) selected = this.props.selected;
    


        this.state = { 
                    altstate: this.props.alt,
                    pressed: false,
                    over: false,
                    caption: caption,
                    selected: selected,
                    text: text
                 };
    


    }

  render () {

        
        return (
            <span 
            onMouseDown = {this.onMouseDown}
            onMouseUp = {this.onMouseUp}
            onMouseEnter = {this.onMouseEnter}
            onMouseOut = {this.onMouseOut}

            >
            <ImageBouton
                disable={this.props.disable}
                image={this.props.image}
                altImage= {this.props.altImage}
                selected = {this.state.selected}
                caption = {this.state.caption}
                pressed={this.state.pressed}
                altstate= {this.props.alt} 
                over= {this.state.over}
                top={this.props.top}
                left={this.props.left}
                special = {this.props.special}
                width={this.props.width}
                height={this.props.height} 
                loaded = {this.state.loaded}
                
                ref={this.setElementImageBouton}
                >
            </ImageBouton>            
            </span>
        )
    }


    onMouseEnter (event)
    {
        
        if(!this.props.disable)
        {
            this.setState( {over : true})
        }
        
    
    }

    onMouseOut (event)
    {   
        
        if (!this.props.disable)
            this.setState( {over : false})
        
    }

    onMouseDown (event)
    {
        event.preventDefault()
        if(!this.props.disable)
        {
            
            this.setState( {pressed : true})
            if (this.props.onButtonClick)
            {

              if (this.state.caption)
              {
                const myevent = 
                    new CustomEvent("onButtonClick",
                    {  detail:  {value: true, button: this.props.buttonName, datas: this.state.caption}    }
                    );
                this.props.onButtonClick(myevent)
              }
              if (this.state.text)
              {
                const myevent = 
                    new CustomEvent("onButtonClick",
                    {  detail:  {value: true, button: this.props.buttonName, datas: this.state.text}    }
                    );
                this.props.onButtonClick(myevent)
              }
              else
              {
                const myevent = 
                    new CustomEvent("onButtonClick",
                    {  detail:  {value: true, button: this.props.buttonName, datas: this.props.datas}    }
                    );
                this.props.onButtonClick(myevent)
              }
            }

        }
    }

    onMouseUp (event)
    {
        event.preventDefault()
        
        if(!this.props.disable)
        {
            this.setState( {pressed : false})
            if (this.props.onButtonClick)
            {
              const myevent = 
                new CustomEvent("onButtonClick", {  detail:  {value: false, button: this.props.buttonName}    }
                );
              this.props.onButtonClick(myevent)
              
            }
        }

    }


    setElementImageBouton = element =>    { 
        
        this.elementImageBouton = element
        
    };


    componentWillUnmount()
    {
        
    }
    componentDidMount()
    {   
    }


  

}
//  ******************************************************************
//  * image bouton
//  ******************************************************************
class ImageBouton extends React.Component {
  
    constructor(props)
    {
        super(props)

        this.state = {onload: false};

        this.canvasElement = null;
        this.drawButton = this.drawButton.bind(this)
        this.images = new Image()
        this.images.src = this.props.image

        this.altImages = null

        if (this.props.altImage)
        {
            this.altImages = new Image()
            this.altImages.src = this.props.altImage
        }

        this.drawButton = this.drawButton.bind(this)

        setTimeout(this.drawButton,200)


    }


componentDidMount()
{
        this.drawButton()
}

componentDidUpdate()
{
        this.drawButton()
}

  render () 
  {

        var classe = "standard-button"
        if (this.props.top === undefined)
        {
                classe = "liste-button"
                if (this.props.special !== undefined)
                {
                    switch (this.props.special)
                    {
                        case "player":
                            classe = "player-button"
                            break;
                    }
                }
        }
        
        if (this.props.disable)
        {
            return (
                <span>
                    <canvas className={classe}
                    ref={this.setElementCanvas}
                    >
                </canvas></span>            
                )

        }
        else
        {

            return (
                <span><canvas className={classe}
                    ref={this.setElementCanvas}
                    >
                </canvas></span>
                )
            
                
        }   
            
        
    }

    setElementCanvas = element =>    { 
        
        this.canvasElement = element
        if (element)
        {
            if (this.props.top)
            {
                
                this.canvasElement.style.top = this.props.top;
                this.canvasElement.style.left = this.props.left;
            }
            var width = this.props.width
            var height = this.props.height
        
            this.canvasElement.width = width
            this.canvasElement.height = height


        }
    };


drawButton()
{
    if (!this.canvasElement) return;
        
    
    var dpr = Math.max(1, window.devicePixelRatio || 1);
    
    var ctx = this.canvasElement.getContext('2d');
    


        
    var width = this.props.width
    var height = this.props.height

    
    ctx.clearRect(0,0,width, height);


    

    var imagesx = width/1.4;
    var imagesy = height/1.4;
    var imagex = width/2 - imagesx / 2-1;
    var imagey = height/2 - imagesy / 2+1;



    ctx.beginPath();
    //ctx.scale(dpr,dpr);

    if (this.props.disable)
        ctx.strokeStyle = '#66009B';
    else
    {
        if (!this.props.over)
            ctx.strokeStyle = 'rgba(183, 147, 199,255)';
        else
            ctx.strokeStyle = 'rgba(255, 255, 255,255)';
    }
    
    if (width <= 32)
        ctx.lineWidth=1;
    else
    {
        if (width <= 48)
            ctx.lineWidth=2;
        else
            ctx.lineWidth=3;
    }
    ctx.arc(width/2, height/2, width/2 -1, 0, 2 * Math.PI);
    ctx.stroke();
    
    ctx.closePath();

    var MonImage;
    if (!this.props.altstate)
        MonImage = this.images
    else
        MonImage = this.altImages
    
    var deca = imagesx/32
    if (deca<2) deca=2
    

    if ((this.props.pressed) & (!this.props.disable))
        ctx.drawImage(MonImage,imagex+deca,imagey+deca,imagesx-2*deca, imagesy-2*deca);
    else
    {
        ctx.drawImage(MonImage,imagex,imagey,imagesx, imagesy);
        
    }


    if (this.props.caption !="")
    {
        ctx.font = "bold 15px Helvetica";
        ctx.fillStyle = "white";
        if (this.props.selected) ctx.fillStyle="black"

        var largeur  = ctx.measureText(this.props.caption).width;
        ctx.fillText(this.props.caption,width/2 - largeur/2, height/2 +12/2);

    }


    if (this.props.disable)
    {
        
        var percentage=0.4;
        var imagedata = ctx.getImageData (0,0,this.canvasElement.width,this.canvasElement.height);   
        var data = imagedata.data;


        for(var i = 0; i < data.length; i += 4) {
        // red
        data[i] = percentage * data[i];
        // green
        data[i + 1] = percentage * data[i + 1];
        // blue
        data[i + 2] =  percentage * data[i + 2] ;
        }

        // overwrite original image
        ctx.putImageData(imagedata, 0,0);
    }
}

}
export default Bouton
