import React from "react";
import PageTitle from "../gadgets/pagetitle/pagetitle"
import listeusers from "./assets/listusers128.png"
import Bouton from "../gadgets/bouton/bouton"

import priv1 from "./assets/priv1.png"
import priv2 from "./assets/priv2.png"
import priv4 from "./assets/priv4.png"
import priv8 from "./assets/priv8.png"
import priv16 from "./assets/priv16.png"

import stylo64 from "./assets/stylo64.png"
import poubelle from "./assets/poubelle.png"
import addUser from "./assets/adduser.png"

import undeletable from "./assets/undeletable.png"

import poubelle2 from "./assets/poubelle2.png"
import back from "./assets/back.png"
import valider from "./assets/valider.png"

import edituser from "./assets/edituser.png"
import deleteuser from "./assets/deleteuser.png"



import {config, getUserData, setUserData}  from '../config'

import ReactToolTip from 'react-tooltip'



import './styles/styles.css';




//  ****************************************************************************************
//  ListUsersHeader
//  ****************************************************************************************
class ListUsersHeader extends React.Component{
    constructor(props)
    {
        super(props);


    }

createAllHeaderColumns()
{
    var retour = []
    var styleh = "headerList"
    if (this.props.disable)
        styleh = "headerList-disable"
    for (var i=0; i<this.props.columms.length; i++)
    {
    var width = this.props.columms[i].width + "%"
    
    
    var key="userheader" + i;
      retour.push(
          
          <td width={width} className={styleh} key={key}>
          
              {this.props.columms[i].text}
          </td>)
    }
    return ( retour)
        
    
}


render()
{
    return (
        <>
                {this.createAllHeaderColumns()}
        </>
    )
}

}

//  ****************************************************************************************
//  ListUsersDatas
//  ****************************************************************************************


class ListUsersDatas extends React.Component{
    constructor(props)
    {
        super(props)
    }


    displayOneChampUser(iuser,ichamp)
    {
        var champ = this.props.columms[ichamp].zone

        if (champ == "privileges")
        {
            var retour=[]
            var n =this.props.datas[iuser]["privileges"]
            if(n & 1) n=255
            var tstyle = "userItemPriv"
            if (this.props.disable) tstyle = "userItemPrivDisable"
            
            if (n & 1) retour.push(<img className={tstyle} src={priv1} data-tip="Administrateur"/>)
            if (n & 2) retour.push(<img className={tstyle} src={priv2} data-tip="Configurations"/>)
            if (n & 4) retour.push(<img className={tstyle} src={priv4} data-tip="Ajouter/Modifier licences"/>)
            if (n & 8) retour.push(<img className={tstyle} src={priv8} data-tip="Ajouter/Modifier chargements"/>)
            retour.push(<img className={tstyle} src={priv16} data-tip="Télécharger Cappella HD"/>)

            if (this.props.datas[iuser].undeletable)
                    retour.push(<img className={tstyle} src={undeletable} data-tip="Utilisateur non supprimable"/>)
            return (retour)

        }
            

        if (champ == "action")
        {

            

            var retour = [];
            
            var datasButtons = 
            {
                user: iuser,
                iduser: this.props.datas[iuser]["id"],
            }

                
            var datatedit="Modifier l'utilisateur"
            if (this.props.disable) datatedit=""
            retour.push (
                    
                    <span  data-tip={datatedit}>
                    <Bouton 
                    width="40" height="40"
                    image={stylo64} 
                    disable={this.props.disable}
                    buttonName="bedit"
                    datas={datasButtons}
                    onButtonClick={this.props.onmodify}

                    />
                    </span>
                    
                    )

                    var userData = getUserData()
                    var id = userData.user.id;
                    if ((!this.props.datas[iuser]["undeletable"]) && (id!=this.props.datas[iuser]["id"]))
                    {
                        retour.push(
                            <span  data-tip="supprimer l'utilisateur">
                            <Bouton 
                            width="40" height="40" 
                            image={poubelle} 
                            disable={this.props.disable}
                            onButtonClick={this.props.ondelete}
                            datas={datasButtons}
                            buttonName="bSuppression"/>
                        </span>)
        
                    }
                        
            
            return retour;
        }

        
        
        return (this.props.datas[iuser][this.props.columms[ichamp].zone])
    }

    displayOneUserList(iuser)
    {
        var retour = []

        

        for (var i=0; i<this.props.columms.length; i++)
        {
        var width = this.props.columms[i].width + "%"
        var key="useritem" + iuser+"_"+ i;
        var useritemclass = "userItemList"
        if (iuser & 1)
            var useritemclass = "userItemList2"

        if (this.props.disable) var useritemclass = "userItemListDisable"
          retour.push(
              
              <td width={width}  className={useritemclass} key={key}>
                    <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    
                  {this.displayOneChampUser(iuser,i)}
              </td>)
        }
        return ( retour)
            
    }

    displayAllUserList()
    {



        var retour=[];
        for (var i=0; i < this.props.datas.length;i++)
        
        {
            {
                var key = "useritem" + i;
                var retl =  (<tr heightkey={key}>{this.displayOneUserList(i)} </tr>)
                retour.push(retl)
            }
        }
        return retour;
    }


    render()
    {
        
        return (
            <>{this.displayAllUserList()}</>
            )
    }
}
//  ****************************************************************************************
//  ListUsers
//  ****************************************************************************************


class ListUsers extends React.Component{
    constructor(props)
    {
        super(props);
        this.columns = [
            {text:"Nom", width:20, zone: "firstname"},
            {text:"Prénom", width:15, zone: "lastname"},
            {text:"Mail", width:20, zone: "email"},
            {text:"Télephone", width:10, zone: "phone"},
            {text:"Privilèges", width:18, zone: "privileges"},
            {text:"Actions", width:17, zone: "action"}
        ]
     
        
    }

render()
{

    return (
        <>
            <table className="tableList" cellSpacing={5} cellPadding={0}>
                
                
                <thead>
                <tr>
                <ListUsersHeader columms = {this.columns}  disable={this.props.disable}/>          
                </tr>
                </thead>
                <tbody>
                
                <ListUsersDatas columms = {this.columns} datas={this.props.liste} 
                        disable={this.props.disable}
                        onmodify={this.props.onmodify}    
                        ondelete={this.props.ondelete} />

                </tbody>
            </table>
        </>
        


    )
}

}

//  ****************************************************************************************
class Users extends React.Component {


    constructor(props)
    {
        super(props);
        this.state = { liste: [],
            listeDisable: false,
            action: {type: null, userdatas: null},
        }

        this.modifyUser = this.modifyUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.addUser = this.addUser.bind(this)
        this.cancelForm = this.cancelForm.bind(this)
        this.validateForm = this.validateForm.bind(this)

        this.createOneUser = this.createOneUser.bind(this)
        this.updateUser = this.updateUser.bind(this)
        this.deleteOneUser = this.deleteOneUser.bind(this)

    }

    modifyUser(event)
    {

        
        ReactToolTip.hide()
        if (event.detail.value)
        {
            console.log(event)
            this.setState( {listeDisable : true})
            this.setState(
                { action: {type: "update", 
                userdatas: this.state.liste[event.detail.datas.user]}})
        }

    }


    addUser(event)
    {
        ReactToolTip.hide()
        if (event.detail.value)
        {
            
            this.setState(  {listeDisable : true})
            this.setState(
            { action: {type: "create", 
            userdatas: null }})
    }
    }

    deleteUser(event)
    {
        
        if (event.detail.value)
        {

            ReactToolTip.hide()
            this.setState( {listeDisable : true})
            this.setState(
                { action: {type: "delete", 
                userdatas: this.state.liste[event.detail.datas.user]}})
                
        }
        
    }

    getUsers()
    {

        var tauqueune = getUserData().user.tauqueune;
        console.log("TOKEN ", tauqueune);

        var myHeaders = new Headers(
            {
                'Authorization': 'Bearer '+tauqueune,
                'Content-Type': 'application/x-www-form-urlencoded'
            }

        );

        
    
        var myInit = { method: 'GET',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default' };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "users"
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            this.setState ({liste : actualData.datas})
        })
        .catch((err) => {
          console.log(err);
        });
    
    }



    deleteOneUser(userid)
    {
        console.log("delete  ",userid)
        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'DELETE',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                        };


    
        var apiurl = config[process.env.NODE_ENV].api_url + "users" +"/" + userid ;
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            this.getUsers();
        })
        .catch((err) => {
          console.log(err);
        });

    }

    createOneUser(newUser)
    {

        console.log("update  ",newUser)
        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'POST',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                       body: JSON.stringify(newUser)
                        };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "users"
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            this.getUsers();
        })
        .catch((err) => {
          console.log(err);
        });
    
    }



    updateUser(id, newUser)
    {

        console.log( "update user", id, newUser)
        
        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'PUT',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                       body: JSON.stringify(newUser)
                        };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "users"+"/"+id
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            try{

                var userData = getUserData()
                var idg = userData.user.id
                if (id == idg)
                {
                    userData.user.lastname = newUser.lastname
                    userData.user.firstname = newUser.firstname
                    userData.user.email = newUser.email
                    userData.user.phone = newUser.phone
                    userData.user.priv = newUser.privilege
                    setUserData(userData)
                    

                }

            }
            catch(e)
            {
                console.log(e)
            }

            this.getUsers();
        })
        .catch((err) => {
          console.log(err);
        });
    
    }





    componentDidMount()
    {
        this.getUsers();
    }
    


    validateForm(action,datas)
    {   
        console.log(action,datas)

        switch (action.type)
        {
            case "create":
                this.createOneUser(datas)
                this.setState(  {listeDisable : false})
                this.setState( {action: null})
                break;
            case "update":
                this.updateUser (action.userdatas.id, datas)
                this.setState(  {listeDisable : false})
                this.setState( {action: null})
                break;
                
                
            case "delete":
                this.deleteOneUser (action.userdatas.id)
                this.setState(  {listeDisable : false})
                this.setState( {action: null})
                break;
        }
    }

    cancelForm()
    {
        this.setState(  {listeDisable : false})
        this.setState( {action: null})

    }
  
  render () {

        var userData = getUserData()
        var priv = userData.user.priv;
        if ((priv & 1)==0)
        {
            
            this.props.navigate ('/')
            return (null)
        }


        var tipAddUser = null;
        
        if (!this.state.listeDisable)
            tipAddUser = "Ajouter un utilisateur"

        
        return (
        <div className="div-container">
            <div className="div-entete">
                <div className="div-boutonup">
                <span data-tip={tipAddUser}>
                        <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>    

                    <Bouton 
                        width="96" height="96" 
                        image={addUser} 
                        disable={this.state.listeDisable}
                        onButtonClick={this.addUser}
                        buttonName="addUser"/>
                </span>
                </div>
                <div className="div-titre">
                    <PageTitle image={listeusers} title="Utilisateurs" {...this.props}/>
                </div>
            </div>
            <div className="div-body">
            {this.renderPaneLeft()}
            {this.renderPaneRight()}
            </div>

        </div>
        )

  }

renderPaneLeft()
{
    var classe = "div-liste-compress";
    if ((!this.state.listeDisable) || (this.state.action==null))
        classe = "div-liste-expand";

    return(
        <div className={classe}>
                <ListUsers 
                    liste={this.state.liste} 
                    disable={this.state.listeDisable}
                    onmodify={this.modifyUser}
                    ondelete={this.deleteUser}
                    
                    />            
        </div>
    )
    

}

renderPaneRight()
{

    
    if ((!this.state.listeDisable) || (this.state.action==null))
    {
        console.log("********* render pane disable", this.state.action)

        return (null);

    }


    
    return (



        <UserFormulaire 
        disable={!this.state.listeDisable} 
        action={this.state.action} 
        onvalidate = {this.validateForm}
        oncancel = {this.cancelForm}
        liste={this.state.liste}
        />

    )
}




}

//  *****************************************************************************
//  * users formulaires
//  *****************************************************************************

class UserFormulaire extends React.Component
{
    constructor(props)
    {
        super(props);

        var titreForm ="";
        var imageForm = null;
        var lastname="";
        var firstname="";
        var email="";
        var phone="";
        var priv1=false;
        var priv2=false;
        var priv4=false;
        var priv8=false;
        var mdp1="";
        var mdp2="";
        var undeletable=false;

        var imageValide = null;
        var tipValide=""

        switch(this.props.action.type)
        {
            
            case "create":
                console.log("creation user")
                titreForm="Creation d'un utilisateur"
                imageForm = addUser
                imageValide = valider
                tipValide="Créer l'utilisateur"
                break;

            case "update":
                titreForm="Modification d'un utilisateur"
                imageForm = edituser
                imageValide = valider
                tipValide="Modifier l'utilisateur"
                mdp1="password"
                mdp2="password"
                break;
            
            case "delete":
                titreForm="Suppresion d'un utilisateur"
                imageForm = deleteuser
                imageValide = poubelle2
                tipValide="Supprimer l'utilisateur"
                mdp1="password"
                mdp2="password"
                break;
                
        }

        console.log(this.props.action)
        switch(this.props.action.type)
        {
            case "update":
            case "delete":

            lastname=this.props.action.userdatas.lastname;
            firstname=this.props.action.userdatas.firstname;
            email=this.props.action.userdatas.email;
            phone=this.props.action.userdatas.phone;
            //mdp1=this.props.action.userdatas.password;            
            //mdp2=this.props.action.userdatas.password;     
            priv1 = (this.props.action.userdatas.privileges & 1) != 0;
            priv2 = (this.props.action.userdatas.privileges & 2) != 0;
            priv4 = (this.props.action.userdatas.privileges & 4) != 0;
            priv8 = (this.props.action.userdatas.privileges & 8) != 0;
            undeletable = this.props.action.userdatas.undeletable;
            if (this.props.action.userdatas.undeletable)
                priv1=true;
            
                break;

        }

        this.state = { 
            
            action: this.props.action,
            titreForm: titreForm,
            image: imageForm,
            imageValide: imageValide,
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone,
            priv1: priv1,
            priv2: priv2,
            priv4: priv4,
            priv8: priv8,
            mdp1: mdp1,
            mdp2: mdp2,
            firstChangePassword: true,
            tipValide: tipValide,
            errorMsg:"",
            undeletetable: undeletable,


        }
        console.log("action: ", this.state.action)
        this.handleCancel = this.handleCancel.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleValide = this.handleValide.bind(this)


        this.fisrtnameInput = React.createRef();
        this.lastnameInput = React.createRef();
        this.emailInput = React.createRef();
        this.phoneInput = React.createRef();
        this.mdp1Input = React.createRef();
        this.mdp2Input = React.createRef();
        

    }


    handleSubmit (event)
    {

        
        event.preventDefault();
        var privi = 0;
        if (this.state.priv1) privi+=1;
        if (this.state.priv2) privi+=2;
        if (this.state.priv4) privi+=4;
        if (this.state.priv8) privi+=8;

        if (privi == 0 ) privi = 16;

        


        var alldatas =
        {
            "firstname" : this.state.firstname,
            "lastname" : this.state.lastname,
            "email" : this.state.email,
            "phone" : this.state.phone,
            "privilege" : privi,
            "password" : this.state.mdp1,
        }

        if (this.state.action.type=="delete")
        {
            this.props.onvalidate(this.state.action, alldatas);
            return;
        }



        this.setState({errorMsg:""})
        if (this.state.firstname.trim()=="")  { this.fisrtnameInput.current.focus();
            this.setState({errorMsg:"Zone obligatoire."})
                return;}
        if (this.state.lastname.trim()=="")  { this.lastnameInput.current.focus();
            this.setState({errorMsg:"Zone obligatoire."})
            return;}
        if (this.state.email.trim()=="")  { this.emailInput.current.focus();
            this.setState({errorMsg:"Zone obligatoire."})
            return;}


        if (this.checkmail() != "")
        {
            this.setState({errorMsg:"Mail déja utilisé."})
            this.emailInput.current.focus();
            return;
        }

        if (!this.checkvalidemail())
        {
            this.setState({errorMsg:"Format mail incorrect."})
            this.emailInput.current.focus();
            return;

        }

        if (this.state.phone.trim()=="")  { this.phoneInput.current.focus();
            this.setState({errorMsg:"Zone obligatoire."})
            return;}
                        

        var mdp = this.state.mdp1;
        if ((this.state.action.type=="create") || (this.state.firstChangePassword == false))
        {
                if (this.state.mdp1.trim().length<8)  { this.mdp1Input.current.focus();
                    this.setState({errorMsg:"Mot de passe trop court."})
                        return;}
            
                if (this.state.mdp2 != this.state.mdp1)  { this.mdp2Input.current.focus();
                    this.setState({errorMsg:"Les mots de passe ne correspondent pas."})
                        return;}
                
                
        }
        else
            mdp=null;
        
        if (privi == 0)
        {
            this.setState({errorMsg:"Définissez au moins un privilège."})
            return;
        }
    
        var alldatas =
        {
            "firstname" : this.state.firstname,
            "lastname" : this.state.lastname,
            "email" : this.state.email,
            "phone" : this.state.phone,
            "privilege" : privi,
            "password" : mdp,
            "image": null,
        }

        this.props.onvalidate(this.state.action, alldatas);
            



    }

    handleValide (event)
    {
        if (event.detail.value)
        {
            
            this.handleSubmit(event)
        }
    }

    
    
    handleCancel (event)
    {
        event.preventDefault();

        if (event.detail.value)
        {
            this.props.oncancel();
            
        }

        
    }

    focusonpassword()
    {
        console.log("focus on password")
        if (this.state.firstChangePassword)
        {
            this.setState({
                firstChangePassword: false,
                mdp1: "",
                mdp2: "",
            })
        }
    }

    checkvalidemail()
    {
        var cmail = this.emailInput.current.value
        if (!cmail.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) {
            return false;
        }        
        return true;
    }

    checkmail()
    {
        
        var currentid = 0;
        if (this.props.action.type != "create")
            currentid = this.props.action.userdatas.id


        var cmail = this.emailInput.current.value
        console.log("checkmail", this.props.action.userdatas,cmail)
        var errMSG = ""
        for(var i=0; (i< this.props.liste.length) && (errMSG == ""); i++)
        {
            var email = this.props.liste[i].email
            var id = this.props.liste[i].id
            console.log(email,"==>",cmail)
            if ((email.toLowerCase() == cmail.toLowerCase()) &&  (id != currentid))
            {
                    errMSG="Mail déja utilisé."
            }
        }

        if (this.state.errorMsg != errMSG)
            this.setState({errorMsg: errMSG})

        return errMSG 

    }



    render()
    {

        

        console.log("render formulaire user")
        return (
            
            <form className="formUser-enabled" onSubmit={this.handleSubmit} >
            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
            <span className="formUser-titre">
            {this.state.titreForm}
            <img  className="formUser-titre-image" src={this.state.image} width="8%"/>
            </span>    

                <label className="formUser-label">
                    Nom
                    <input className="formUser-input" autoFocus={true}
                    disabled= {this.props.action.type=="delete"}
                    name="grandnom"
                    ref={this.fisrtnameInput}
                    type="text"
                    onChange={e => this.setState({firstname:e.target.value})}
                    value={this.state.firstname}
                    autoComplete={false}
                    required />
                </label>

                <label className="formUser-label">
                    Prénom
                    <input className="formUser-input"
                    disabled= {this.props.action.type=="delete"}
                    
                    name="petitnom"
                    type="text"
                    ref={this.lastnameInput}
                    onChange={e => this.setState({lastname:e.target.value})}
                    value={this.state.lastname}
                    required />
                </label>

                <label className="formUser-label">
                    Adresse Mail
                    <input className="formUser-input"
                    disabled= {this.props.action.type=="delete"}
                    name={"courrier" + Math.random().toString(36)}
                    
                    type="text"
                    ref={this.emailInput}
                    onChange={e => {
                        this.setState({email:e.target.value});
                        this.checkmail();
                            }
                        }
                    value={this.state.email}
                    autoComplete="nope"
                    required />
                </label>
                <label className="formUser-label">
                    téléphone
                    <input className="formUser-input"
                    disabled= {this.props.action.type=="delete"}
                    name="phone"
                    type="text"
                    ref={this.phoneInput}
                    onChange={e => this.setState({phone:e.target.value})}
                    value={this.state.phone}
                    required />
                </label>

                <label className="formUser-label">
                    Mot de passe
                    <input className="formUser-input"
                    disabled= {this.props.action.type=="delete"}
                    name="mdp1"
                    type="password"
                    ref={this.mdp1Input}
                    
                    onChange={e => this.setState({mdp1:e.target.value})}
                    onFocus= {this.focusonpassword.bind(this)}
                    value={this.state.mdp1}
                    required />
                </label>


                <label className="formUser-label">
                    Confirmez le mot de passe
                    <input className="formUser-input"
                    disabled= {this.props.action.type=="delete"}
                    name="mdp2"
                    type="password"
                    ref={this.mdp2Input}
                    onChange={e => this.setState({mdp2:e.target.value})}
                    onFocus= {this.focusonpassword.bind(this)}
                    value={this.state.mdp2}
                    
                    required />
                </label>


                <label className="formUser-label">
                    Privilèges
                </label>
                 <table width="100%">
                    <tbody>

                    <tr>
                        
                        <td className="formUser-td">
                            <label>
                        <input className="formUser-check"
                        disabled= {this.props.action.type=="delete" || this.state.undeletetable}
                        name="priv1"
                        type="checkbox"
                        onChange={e => this.setState({priv1:e.target.checked})}
                        checked={this.state.priv1}
                        required />
                        &nbsp;<img src={priv1} className="formUser-imgpriv"/>
                        &nbsp;Administrateur
                        </label>

                        </td><td  className="formUser-td">
                        <label>
                        <input className= "formUser-check"
                        disabled= {this.props.action.type=="delete"}
                        name="priv2"
                        type="checkbox"
                        onChange={e => this.setState({priv2:e.target.checked})}
                        checked={this.state.priv2}
                        required />
                        &nbsp;<img src={priv2} className="formUser-imgpriv"/>
                        &nbsp;Configurations
                        </label>
                        </td>
                    </tr>


                    <tr><td className="formUser-td">
                        <label>
                        <input className="formUser-check"
                        disabled= {this.props.action.type=="delete"}
                        name="priv4"
                        type="checkbox"
                        onChange={e => this.setState({priv4:e.target.checked})}
                        checked={this.state.priv4}
                        required />
                        &nbsp;<img src={priv4} className="formUser-imgpriv"/>
                        &nbsp;Licences Cappella
                        </label>
                    </td><td className="formUser-td">
                        <label>
                        <input className= "formUser-check"
                        disabled= {this.props.action.type=="delete"}
                        name="priv8"
                        type="checkbox"
                        onChange={e => this.setState({priv8:e.target.checked})}
                        checked={this.state.priv8}
                        required />
                        &nbsp;<img src={priv8} className="formUser-imgpriv"/>
                        &nbsp;Chargements
                        </label>
                    </td></tr>



                    </tbody></table>

                    

                    <table className="formUser-tablebutton"><tbody>
                    <tr>
                    <td width="10%">&nbsp;</td>
                    <td width="60%" className="formUser-errormsg">{this.state.errorMsg}&nbsp;</td>
                    <td width="12%">
                    <span data-tip="Abandon">
                    <Bouton 
                        width="72" height="72" 
                        image={back} 
                        disable={false}
                        buttonName="bback"
                        onButtonClick={this.handleCancel}
                    />
                    </span>
                    </td><td width="12%">
                    <span data-tip={this.state.tipValide}>
                    <Bouton 
                    width="72" height="72" 
                    image={this.state.imageValide} 
                    disable={false}
                    buttonName="bvalide"
                    onButtonClick={this.handleValide}
                    />
                    </span>
                    </td>
                    <td width="5%"></td></tr>
                    </tbody></table>

            </form>

        )
    }

}

export default Users



