import React from "react";




import './styles.css';

class OnePage extends React.Component 
{
constructor(props)
{
    super(props)

    var height=32;
    var width=32;
    var text="";
    if ('height' in this.props) height = this.props.height;
    if ('width' in this.props) width = this.props.width;


    this.fillStyle = "white";
    var selected = false;
    if ('selected' in this.props)
    {
        if (this.props.selected) 
        {
            selected=true;
            this.fillStyle="black";
        }
    }



    var tips="";
    switch (this.props.type)
    {
        case "last": tips="dernière page";break;
        case "first":  tips="première page";break;
        case "next":  tips="page suivante";break;
        case "previous":  tips="page précédente";break;
        default:
            tips="page "+this.props.type;
            text = this.props.type;
            break;
    }
    this.drawButton = this.drawButton.bind(this)    

    this.images = new Image();
    this.images.src = this.props.image;
    this.images.onload = this.drawButton;



    this.state = {
        height : height,
        width: width,
        tips: tips,
        over: false,
        pressed: false,
        selected: selected,
        text: text,
    }

    this.canvasElement = null;
    
    this.setElementCanvas = this.setElementCanvas.bind(this);


    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
    this.onMouseDown = this.onMouseDown.bind(this)
    this.onMouseUp = this.onMouseUp.bind(this)

    
    //setTimeout(this.drawButton,200)

}

onMouseEnter (event)
{
    
    if(!this.props.disable)
        this.setState( {over : true})

}

onMouseOut (event)
{   
    
    this.setState( {over : false})
    
}

onMouseDown (event)
{
    event.preventDefault()
    
    if(!this.props.disable)
    {
        
        this.setState( {pressed : true})
        if (this.props.onClick)
        {
            if (this.props.image !== undefined)
                this.props.onClick(this.props.type)
            else
                this.props.onClick(this.props.type)
        }

    }
}

onMouseUp (event)
{
    event.preventDefault()
    
    if(!this.props.disable)
    {
        this.setState( {pressed : false})
    }

}


componentDidMount()
{
        
}

componentDidUpdate()
{
        
}




drawButton()
{
    if (!this.canvasElement) return;
            
    //console.log("draw button ", this.props.type, this.state.selected, this.images)
        




    var dpr = Math.max(1, window.devicePixelRatio || 1);
    var ctx = this.canvasElement.getContext('2d');
    var width = this.state.width;
    var height = this.state.height;
    ctx.clearRect(0,0,width, height);

    try
    {
        if (this.state.pressed)
            ctx.drawImage(this.images,1,1,this.state.width-1, this.state.height-1);
        else
            ctx.drawImage(this.images,0,0,this.state.width, this.state.height);
    }
    catch
    {
        //setTimeout(this.drawButton,200);
        return;
    }
    if (this.props.disable)
    {
        
        
        var imagedata = ctx.getImageData (0,0,this.state.width,this.state.height);   
        var data = imagedata.data;


        for(var i = 0; i < data.length; i += 4) {
        // red

            var dx = data[i] + data[i+1] + data[i+2];

        data[i] = dx/6;
        // green
        data[i + 1] = dx/6;
        // blue
        data[i + 2] =  dx/6 ;
        }

        // overwrite original image
        ctx.putImageData(imagedata, 0,0);
    }
    
    
    
    
    if (this.state.over)
    {
        
        
        var imagedata = ctx.getImageData (0,0,this.state.width,this.state.height);   
        var data = imagedata.data;


        for(var i = 0; i < data.length; i += 4) {
        // red

        data[i] = data[i] + 30;
        // green
        data[i + 1] = data[i + 1] + 30;
        // blue
        data[i + 2] =  data[i + 2] + 30 ;
        }

        // overwrite original image
        ctx.putImageData(imagedata, 0,0);
    }


    if (this.state.text != "")
    {
        ctx.font = "bold 12px serif";
        ctx.fillStyle = this.fillStyle;
        if (this.props.disable)
            ctx.fillStyle = "gray";
        


        var largeur  = ctx.measureText(this.state.text).width;

        ctx.fillText(this.state.text,this.state.width/2 - largeur/2, this.state.height/2 +12/2);

    }
    



}

render()
{

    var tips=this.state.tips;
    if (this.props.disable) tips="";

    return (
        <span data-tip={tips}


            onMouseDown = {this.onMouseDown}
            onMouseUp = {this.onMouseUp}
            onMouseEnter = {this.onMouseEnter}
            onMouseOut = {this.onMouseOut}>


            <canvas className="standard-page"
                    ref={this.setElementCanvas} >
            </canvas>
            &nbsp;
        </span>
    )
}



setElementCanvas = element =>    
{

        
    this.canvasElement = element
    if (element)
    {
        var width = this.state.width
        var height = this.state.height
    
        this.canvasElement.width = width
        this.canvasElement.height = height

    }


};

}
export default OnePage
