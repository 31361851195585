import React from "react";

import Bouton from "../bouton/bouton"

import ReactToolTip from 'react-tooltip'

import back from "../../users/assets/back.png"
import valider from "../../users/assets/valider.png"

import licencevalide from "../../licences/assets/valider.png"
import licencenonvalide from "../../licences/assets/nonvalider.png"
import etudiant from "../../licences/assets/student.png"


import { config, getUserData } from "../../config";
import '../../users/styles/styles.css'
import './styles.css';



class LoginUserForm extends React.Component
{

    constructor(props)
    {
        super(props)

        var lastname=""
        var firstname=""
        var email=""
        var image=""
        var id=0;
        var phone="";
        var privileges=0;

        var licence = 0;
        var datefin ="";
        var status = 0;
        var etudiant = 0;
        var userLicence = false;

        var version = "";

        try{

            var userData = getUserData()

            console.log("userdata ", userData)
            id= userData.user.id
            lastname= userData.user.lastname
            firstname= userData.user.firstname
            email= userData.user.email
            image= userData.user.image 
            privileges= userData.user.priv
            phone= userData.user.phone


            //if (privileges & 16)
            if (userData.user.userLicence)
            {

                licence = userData.user.licence;
                datefin =  userData.user.datefin;
                status = userData.user.status;
                etudiant = userData.user.etudiant;
                version = userData.user.version;
                userLicence = true;
        
            }


        }





        catch(e)
        {
            id=0;
        }

        this.state = {
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone,

            mdp1:"password1",
            mdp2:"password2",
            privileges: privileges,

            image: image,
            id: id,
     
            firstChangePassword: true,


            licence: licence,
            datefin: datefin,
            status: status,
            etudiant: etudiant,
            userLicence : userLicence,
     
            version: version,

            errorMsg: "",
        }

        
        this.handleCancel = this.handleCancel.bind(this)
        this.handleValide = this.handleValide.bind(this)

        this.fisrtnameInput = React.createRef();
        this.lastnameInput = React.createRef();
        this.emailInput = React.createRef();
        this.phoneInput = React.createRef();
        this.mdp1Input = React.createRef();
        this.mdp2Input = React.createRef();
        this.iconInput = React.createRef();


    }   


    renderStatus()
    {


        var retour = [];


        
        if (this.state.status==1)
            retour.push(<img src={licencevalide} width="64px" height="64px" data-tip="Licence active"/>);
        else
            retour.push(<img src={licencenonvalide} width="64px" height="64px" data-tip="Licence non active"/>);

        if (this.state.etudiant==1)
            retour.push(<img src={etudiant} width="64px" height="64px" data-tip="Licence Etudiant"/>);


        
        return retour;

    }
    handleValide(event)
    {
        if (event.detail.value)
        {
   
    
    
   
            this.setState({errorMsg:""})
            if (this.state.firstname.trim()=="")  { this.fisrtnameInput.current.focus();
                this.setState({errorMsg:"Zone obligatoire."})
                    return;}
            if (this.state.lastname.trim()=="")  { this.lastnameInput.current.focus();
                this.setState({errorMsg:"Zone obligatoire."})
                return;}
            if (this.state.email.trim()=="")  { this.emailInput.current.focus();
                this.setState({errorMsg:"Zone obligatoire."})
                return;}
    
    
            if (this.checkmail() != "")
            {
                this.setState({errorMsg:"Mail déja utilisé."})
                this.emailInput.current.focus();
                return;
            }
    
            if (!this.checkvalidemail())
            {
                this.setState({errorMsg:"Format mail incorrect."})
                this.emailInput.current.focus();
                return;
    
            }
    
            if (this.state.phone.trim()=="")  { this.phoneInput.current.focus();
                this.setState({errorMsg:"Zone obligatoire."})
                return;}
                            
    
            var mdp = this.state.mdp1;
            if (this.state.firstChangePassword == false)
            {
                if (this.state.mdp1.trim().length<8)  { this.mdp1Input.current.focus();
                    this.setState({errorMsg: (<span>Mot de passe<br/>trop court.</span>)})
                        return;}
            
                if (this.state.mdp2 != this.state.mdp1)  { this.mdp2Input.current.focus();
                    this.setState({errorMsg: (<span>Les mots de passe<br/>ne correspondent pas.</span>)})
                        return;}
                    
                    
            }
            else
                mdp=null;

            var image = this.state.image;
            if ((image == null) || (image.length <5))
                image=null;
    
                
            
            var alldatas =
            {
                "firstname" : this.state.firstname,
                "lastname" : this.state.lastname,
                "email" : this.state.email,
                "phone" : this.state.phone,
                "privilege" : this.state.privileges,
                "password" : mdp,
                "image": image,
            }
    

            
            var myHeaders = new Headers();
            myHeaders.append('Accept','application/json');
            myHeaders.append('Content-Type','application/json');
            var myInit = { method: 'PUT',
                        headers: myHeaders,
                        mode: 'cors',
                        cache: 'default',
                        body: JSON.stringify(alldatas)
                            };
        
            var apiurl = config[process.env.NODE_ENV].api_url + "users"+"/"+this.state.id
            fetch(apiurl,myInit)
            .then((response) => {
            if (!response.ok) {
                throw new Error(
                `This is an HTTP error: The status is ${response.status}`
                );
            }
            
            return response.json();
            })
            .then((actualData) => {
        
                console.log("done")
                this.props.onfinish(alldatas)
            })
            .catch((err) => {
            console.log(err);
            });
        

    
        }
    }

    handleCancel(event)
    {
        if (event.detail.value)
        {
            this.props.onfinish(null)
        }
    }


    focusonpassword()
    {
        console.log("focus on password")
        if (this.state.firstChangePassword)
        {
            this.setState({
                firstChangePassword: false,
                mdp1: "",
                mdp2: "",
            })
        }
    }
    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
      }

    onimageloaded = async (event) => {
        const file = event.target.files[0]
        const base64 = await this.convertBase64(file)
        
        this.setState ( {image : base64})
        
        }

    checkvalidemail()
    {
        var cmail = this.emailInput.current.value
        if (!cmail.match(/[a-z0-9_\-\.]+@[a-z0-9_\-\.]+\.[a-z]+/i)) {
            return false;
        }        
        return true;
    }

    checkmail()
    {
        
        var currentid = this.state.id;
        var cmail = this.emailInput.current.value
        console.log("checkmail", currentid,cmail)
        var errMSG = ""
        for(var i=0; (i< this.props.liste.length) && (errMSG == ""); i++)
        {
            var email = this.props.liste[i].email
            var id = this.props.liste[i].id
            console.log(id,"==>",email)
            if ((email.toLowerCase() == cmail.toLowerCase()) &&  (id != currentid))
            {
                    errMSG="Mail déja utilisé."
                    console.log(id,currentid,email,cmail)
            }
        }

        if (this.state.errorMsg != errMSG)
            this.setState({errorMsg: errMSG})

        return errMSG 

    }
    

    //<form className="formUser-enabled" onSubmit={this.handleSubmit}>
    render()
    {

        console.log("state : ", this.state)
        

        if (this.state.id == 0)
        {
            return (null)
        }

        if (!this.state.userLicence)
        {

            return (
                <>
                <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>

                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Nom</div>
                        <input className="modal-edit-formUser-input" autoFocus={true}
                        name="firstname"
                        ref={this.fisrtnameInput}
                        type="text"
                        id = "userfirstname"
                        onChange={e => this.setState({firstname:e.target.value})}
                        value={this.state.firstname}
                        required />
                    </label>

                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Prénom</div>
                        <input className="modal-edit-formUser-input"
                        name="lastname"
                        id = "userlastname"
                        type="text"
                        ref={this.lastnameInput}
                        onChange={e => this.setState({lastname:e.target.value})}
                        value={this.state.lastname}
                        required />
                    </label>

                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Adresse Mail</div>
                        <input className="modal-edit-formUser-input"
                        name="email"
                        type="text"
                        id = "usermail"
                        ref={this.emailInput}
                        onChange={e => {
                            this.setState({email:e.target.value});
                            this.checkmail();
                                }
                            }
                        value={this.state.email}
                        required />
                    </label>
                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Téléphone</div>
                        <input className="modal-edit-formUser-input"
                        name="phone"
                        type="text"
                        ref={this.phoneInput}
                        onChange={e => this.setState({phone:e.target.value})}
                        id = "userphone"
                        value={this.state.phone}
                        required />
                    </label>

                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Mot de passe</div>
                        <input className="modal-edit-formUser-input"
                        name="mdp1"
                        type="password"
                        ref={this.mdp1Input}
                        id = "userpwd1"
                        onChange={e => this.setState({mdp1:e.target.value})}
                        onFocus= {this.focusonpassword.bind(this)}
                        value={this.state.mdp1}
                        required />
                    </label>


                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Confirmez le mot de passe</div>
                        <input className="modal-edit-formUser-input"
                        name="mdp2"
                        type="password"
                        ref={this.mdp2Input}
                        id = "userpwd2"
                        onChange={e => this.setState({mdp2:e.target.value})}
                        onFocus= {this.focusonpassword.bind(this)}
                        value={this.state.mdp2}
                        required />
                    </label>


                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Image</div>
                        <input className="modal-edit-formUser-input"


                        name="icon"
                        type="file"
                        ref={this.iconInput}

                        onChange={e=> this.onimageloaded(e)}
                        id = "userimage"
                        
                        required />
                    </label>
                    <img className = "modal-edit-formUser-icon"
                        ref={this.iconImage}
                        src={this.state.image}
                    />


                    <table className="modal-edit-formUser-tablebutton"><tbody>
                    <tr>
                    <td width="3%">&nbsp;</td>
                    <td width="60%" className="formUser-errormsg">{this.state.errorMsg}&nbsp;</td>
                    <td width="20%">
                    <span data-tip="Abandon">
                    <Bouton 
                        width="72" height="72" 
                        image={back} 
                        disable={false}
                        buttonName="bback"
                        onButtonClick={this.handleCancel}
                    />
                    </span>
                    </td><td width="12%">
                    <span data-tip="Enregistrer">
                    <Bouton 
                    width="72" height="72" 
                    image={valider} 
                    disable={false}
                    buttonName="bvalide"
                    onButtonClick={this.handleValide}
                    />
                    </span>
                    </td>
                    <td width="5%"></td></tr>
                    </tbody></table>

                
                </>



            )
        }
        else
        {
            return (
                <>
                <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>

                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Nom</div>
                        <input className="modal-edit-formUser-input" autoFocus={true}
                        name="firstname"
                        type="text"
                        id = "userfirstname"
                        value={this.state.firstname}
                        readOnly ={true}
                        />
                    </label>
                    

                    <label className="modal-edit-formUser-label">
                    <div className="modal-edit-formUser-div">Prénom</div>
                        <input className="modal-edit-formUser-input"
                        name="lastname"
                        id = "userlastname"
                        type="text"
                        value={this.state.lastname}
                        readOnly ={true}
                        />
                    </label>

                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Adresse Mail</div>
                        <input className="modal-edit-formUser-input"
                        name="email"
                        type="text"
                        id = "usermail"
                        value={this.state.email}
                        readOnly ={true}
                        />
                    </label>

                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Numéro de licence</div>
                        <input className="modal-edit-formUser-input"
                        name="licence"
                        type="text"
                        id = "licence"
                        value={this.state.licence}
                        readOnly ={true}
                        />
                    </label>

                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Status de la licence</div>

                        <div className="modal-edit-formUser-status">
                        {this.renderStatus()}
                        </div>
                    </label>


                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Date de fin de validité</div>
                        <input className="modal-edit-formUser-input"
                        name="datefin"
                        type="text"
                        id = "datefin"
                        value={this.state.datefin}
                        readOnly ={true}
                        />
                    </label>
                    <label className="modal-edit-formUser-label">
                        <div className="modal-edit-formUser-div">Version Logiciel Cappella HD</div>
                        <input className="modal-edit-formUser-input"
                        name="version"
                        type="text"
                        id = "version"
                        value={this.state.version}
                        readOnly ={true}
                        />
                    </label>

                    <div className="modal-edit-formUser-div2">pour toutes modifications sur ces informations, contactez VSI Paris</div>
                    <br/>





                
                </>



            )
        }
    }
}



class LoginEditUser extends React.Component
{

    constructor(props)
    {
        super(props)

        this.state ={
            userliste : [],
        }
        this.onfinish = this.onfinish.bind(this)
    }

    onfinish()
    {
        this.props.onfinish(null)
    }
    componentDidMount()
    {

        if (!this.state.userLicence)
            this.getUsers();
    }

    render()
    {
        return (
            <>
            <div className="modal-edit-darkBG"/>
            <div className="modal-edit-centered">
                <div className="modal-edit-modal">
                <div className="modal-edit-modalHeader">
                    <h5 className="modal-edit-heading">{"Mes informations"}</h5>
                </div>
                <button className="modal-edit-closeBtn" onClick={this.onfinish}>
                    x
                </button>
                <div className="modal-edit-modalContent" >
                    <LoginUserForm {...this.props} liste={this.state.userliste}
                        
                        />
                </div>
                
                </div>
            </div>
            </>      
    

        )
    }



    getUsers()
    {
        var myHeaders = new Headers();

        var myInit = { method: 'GET',
                    headers: myHeaders,
                    mode: 'cors',
                    cache: 'default' };

        var apiurl = config[process.env.NODE_ENV].api_url + "users"
        fetch(apiurl,myInit)
        .then((response) => {
        if (!response.ok) {
            throw new Error(
            `This is an HTTP error: The status is ${response.status}`
            );
        }
        
        return response.json();
        })
        .then((actualData) => {

            this.setState ({userliste : actualData.datas})
        })
        .catch((err) => {
        console.log(err);
        });

}

}
export default LoginEditUser

