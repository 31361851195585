import React from "react";

import Login from "../login/login";
import './styles.css';



class PageTitle extends React.Component {


    constructor(props)
    {
        super(props);

    }
//
  //

  renderTextLicence()
  {


    if (this.props.licence=="yes")
    {
      return(
        <div className="pagetitle-div-licence">
        Rappel:<br/>
        <span style={{color:"#C0C0C0"}}>
        - Autorisation d'utilisation du logiciel durant 30 jours hors connexion.<br/>
        - Tentative de vérification de la validité de la license à chaque lancement du logiciel, si hors connexion le compteur 30 jours fait foi.<br/>
        - Alerte à chaque ouverture du logiciel durant les 5 jours précédents une connexion Internet obligatoire.<br/>
        - Pas de caractères spéciaux dans les noms et prénoms des auteurs.<br/>
        - Alerte quotidienne par mail durant les quinze jours précédents la fin de validité de la licence.<br/>
        </span>
        </div>
      )
    }
    else
      return (null)

  }


  render () {
        
    
    
        return (
          <>
            {this.renderTextLicence()}
            <div className="pagetitle">
            
              <img  className="pagetitle-picture" src={this.props.image} />
              <span className="pagetitle-text">{this.props.title}</span>
              
              <Login {...this.props}/>            

              
            </div>
          </>
        )
  }


}

export default PageTitle