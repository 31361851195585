import React from "react";

import ReactToolTip from 'react-tooltip'
import Bouton from "../gadgets/bouton/bouton"
import search from "./assets/search.png"
import balais from "./assets/balais.png"
import searchplus from "./assets/searchplus.png"
import alerte from "./assets/alerte.png"

//  ****************************************************************************************
//  Search Licences
//  ****************************************************************************************


class SearchLicences extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            //firstname: this.props.firstname,
            firstname: this.props.firstname,
            detail: this.props.detail,
            status: this.props.status,
            type: this.props.type,
            version: this.props.version,
            tri: this.props.tri,
            nb: this.props.nb,
        }



        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handleValide = this.handleValide.bind(this)
        this.handleValidePlus = this.handleValidePlus.bind(this)
        this.handleAlerte = this.handleAlerte.bind(this)

        this.fisrtnameInput = React.createRef();
        this.statusInput = React.createRef();
        this.typeInput = React.createRef();
        this.versionInput = React.createRef();
        this.triInput = React.createRef();
        this.nbPageInput = React.createRef();


        this.handleChangeNbPage = this.handleChangeNbPage.bind(this);

    }


    handleChangeDetail(e)
    {
        this.setState({detail:e.target.checked});
        this.props.onChangeDetail(e.target.checked)

    }

    handleChangeNbPage(e)
    {
        this.setState({nb: e.target.value});
        


        this.props.onChangePage(e.target.value)
    }

    handleAlerte(event)
    {
        var searchParams = 
        {
            firstname: "",
            status: "1",
            type: "1",
            tri: "1",
            version:"1",

        }
        this.props.onAlert();
        
        this.setState(
            {
            firstname: "",
            status: "1",
            type: "1",
            tri: "1",
            version:"1",
            }
        );

    }



    handleSubmit (event)
    {
        event.preventDefault();
        var searchParams = 
        {
            firstname: this.state.firstname,
            status: this.state.status,
            type: this.state.type,
            version: this.state.version,
            tri: this.state.tri,

        }
        this.props.onSearch(searchParams,false);
}

    handleReset(event)
    {
        if (event.detail.value)
        {

            var searchParams = 
            {
                firstname: "",
                status: "1",
                type: "1",
                tri: "1",
                version: "1",

            }
            this.props.onSearch(searchParams);
            
            this.setState(
                {
                firstname: "",
                status: "1",
                type: "1",
                tri: "1",
                version: "1",
                }
            );
        }

    }
    handleValide(event)
    {
        if (event.detail.value)
        {

            var searchParams = 
            {
                firstname: this.state.firstname,
                status: this.state.status,
                type: this.state.type,
                tri: this.state.tri,
                version: this.state.version,

            }
            this.props.onSearch(searchParams,false);
        }
    }

    handleValidePlus(event)
    {
        if (event.detail.value)
        {

            var searchParams = 
            {
                firstname: this.state.firstname,
                status: this.state.status,
                type: this.state.type,
                tri: this.state.tri,
                version: this.state.version,

            }
            this.props.onSearch(searchParams,true);
        }
    }

    render()
    {
        return (

            <>
            <form className="formSearchLicence" onSubmit={this.handleSubmit} autoComplete="off">

            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
            <span className="formSearch-titre">
            <img   src={search} height="20px"/>&nbsp;&nbsp;Filtres et tri
            </span>    

                <label className="formSearch-label">
                    Nom
                    <input className="formSearch-input" autoFocus={true}
                    disabled= {this.props.disabled}
                    autoComplete="false"
                    name="searchname"
                    ref={this.fisrtnameInput}
                    type="text"
                    onChange={e => this.setState({firstname:e.target.value})}
                    value={this.state.firstname}
                    />
                </label>
                
                <label className= "formSearch-label">
                        <br/>Status licence :
                        <select className = "formSearch-select-status"
                            ref={this.statusInput}
                            name="status"
                            disabled= {this.props.disabled}
                            onChange={e => this.setState({status:e.target.value})}
                            value={this.state.status}  >
                            <option value={1}>Toutes les licences</option>
                            <option value={2}>Licences actives</option>
                            <option value={3}>Licences inactives</option>
                            <option value={4}>Licences actives et date non dépassée</option>
                            <option value={5}>Licences actives et date dépassée</option>

                        </select>
                        
                </label>

                <label className= "formSearch-label">
                        <br/>Type de licence :
                        <select className = "formSearch-select-status"
                            ref={this.typeInput}
                            name="typelic"
                            disabled= {this.props.disabled}
                            onChange={e => this.setState({type:e.target.value})}
                            value={this.state.type}  >
                            <option value={1}>Toutes les licences</option>
                            <option value={2}>Licences Normales</option>
                            <option value={3}>Licences Etudiant</option>

                        </select>
                </label>


                <label className= "formSearch-label">
                        <br/>Version CappellaHD :
                        <select className = "formSearch-select-status"
                            ref={this.triInput}
                            name="version"
                            disabled= {this.props.disabled}
                            onChange={e => this.setState({version:e.target.value})}
                            value={this.state.version}  >
                            <option value={1}>Toutes versions</option>
                            <option value={2}>Cappella HD à jour</option>
                            <option value={3}>Cappella HD non à jour</option>

                        </select>
                </label>



                <label className= "formSearch-label">
                        <br/>Tri :
                        <select className = "formSearch-select-status"
                            ref={this.triInput}
                            name="tri"
                            disabled= {this.props.disabled}
                            onChange={e => this.setState({tri:e.target.value})}
                            value={this.state.tri}  >
                            <option value={1}>N° de licence</option>
                            <option value={2}>Nom</option>
                            <option value={3}>Date de fin</option>
                            <option value={4}>Date de connexion</option>
                            <option value={5}>Version Cappella HD</option>

                        </select>
                </label>


                <table className="formUser-tablebutton"><tbody>
                    <tr>
                    <td width="42px">
                    <span data-tip="Annuler tous les critères">
                    <Bouton 
                        width="34" height="34" 
                        image={balais} 
                        disable={this.props.disabled}
                        buttonName="breset"
                        onButtonClick={this.handleReset}
                    />
                    </span>
                    </td>
                    <td width="42px">
                    <span data-tip="Lancer la recherche">
                    <Bouton 
                    width="34" height="34" 
                    image={search} 
                    disable={this.props.disabled}
                    buttonName="bvalide"
                    onButtonClick={this.handleValide}
                    />
                    </span>
                    </td>
                    <td width="42px">
                    <span data-tip="Lancer la recherche et sélectionner">
                    <Bouton 
                    width="34" height="34" 
                    image={searchplus} 
                    disable={this.props.disabled}
                    buttonName="bvalideplus"
                    onButtonClick={this.handleValidePlus}
                    />
                    </span>
                    </td>
                    <td width="42px">
                    <span data-tip="Licence arrivant à échéance et sélection">
                    <Bouton 
                    width="34" height="34" 
                    image={alerte} 
                    disable={this.props.disabled}
                    buttonName="balerte"
                    onButtonClick={this.handleAlerte}
                    />
                    </span>
                    </td>

                    <td width="2%"></td>
                    </tr>
                    </tbody></table>


            </form>
            
            <div className="formSearch-check">
            <input 
                        
                        disabled= {this.props.disabled}
                        name="detail"
                        type="checkbox"
                        onChange={e => this.handleChangeDetail(e)}
                        
                        checked={this.state.detail}
                        required />
                        &nbsp;Afficher les détails<br /><br />
            </div>
            
                <div className= "formSearch-label">
                        Licences par page :
                        <br />
                        <select className="formSearch-select-status"
                            ref={this.nbPageInput}
                            name="nbperpage"
                            disabled= {this.state.disabled}
                            onChange={e => this.handleChangeNbPage(e)}
                            value={this.state.nb}  >
                            <option value={10}>10 par pages</option>
                            <option value={20}>20 par pages</option>
                            <option value={30}>30 par pages</option>

                        </select>
                </div>

            </>


        )
    }

}

/*
                            <option value={40}>30 par pages</option>
                            <option value={50}>50 par pages</option>
                            <option value={"all"}>Toutes</option>

*/
export default SearchLicences