import React from "react";

import {config, getUserData,getListTypeMail}  from '../config'
import ReactToolTip from 'react-tooltip'

import Bouton from "../gadgets/bouton/bouton"


import '../users/styles/styles.css';
import './styles/styles.css';

import editemail from "./assets/editemail.png"



class ListTypeMail extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state =
        {
            disablelist: false,
            liste: getListTypeMail(),
        }
    }


    renderListMail()
    {

        var retour = [];

        for (var i=0; i<this.state.liste.length;i++)
        {

            var st1="mailseditor_listheaderBodyC";
            var st2="mailseditor_listheaderBody";
            if ((i == this.props.editindex) && (this.props.disable))
            {
                st1="mailseditor_listheaderBodyC_selected";
                st2="mailseditor_listheaderBody_selected";
    
            }

            retour.push(
                <tr>
                <td className={st1}>
                    &nbsp;&nbsp;{this.state.liste[i].id}
                </td>   
                <td className={st2}>
                &nbsp;&nbsp;{this.state.liste[i].lib}
                </td> 

                
                <td className="mailseditor_listheaderBodyC">
                <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                    <span data-tip="Modifier le texte du mail">
                    <Bouton 
                    width="32" height="32" top="0"
                    image={editemail} 
                    disable= {this.props.disable}
                    key = {"edit"+this.state.liste[i].id}
                    onButtonClick={this.props.onedit}
                    datas={{id:this.state.liste[i].id, index:i,lib:this.state.liste[i].lib}}
                    buttonName="bModif"/>
                    </span>
                </td>
            </tr>  
        )


        }
        return retour;


    }
    render ()
    {
        return(
        <>
        <div className="mailseditor_list">
        

            <table width="100%">
                <thead style={{position:"sticky", top:0, zIndex:1}}>
                <tr>

                <td width="10%" className="mailseditor_listheaderC">
                        &nbsp;&nbsp;ID
                </td>
                <td width="75%" className="mailseditor_listheader">
                        &nbsp;&nbsp;Type de mail.

                </td>
                <td width="15%" className="mailseditor_listheaderC">
                    
                        Action
                    
                </td>

                </tr>
                </thead>
                <tbody>
                    {this.renderListMail()}

                </tbody>



            </table>
            </div>

        </>
        )
    }

}

export default ListTypeMail
