import React from "react";
import back from "./assets/back.png"
import ReactToolTip from 'react-tooltip'
import Bouton from "../gadgets/bouton/bouton"
import { getActionLib } from "../config";

//  ****************************************************************************************
//  liste activity
//  ****************************************************************************************


class ListeActivity extends React.Component
{
    constructor(props)
    {
        super(props)
    }
    render()
    {

        var liste=[];


        for (var i=0; i< this.props.liste.length; i++)
        {
            var action= getActionLib(this.props.liste[i].action);
                liste.push(
                <tr >
                    <td className="activity-itemList">
                        {this.props.liste[i].to_char}
                    </td>
                    <td className="activity-itemList">
                        {this.props.liste[i].ip}
                    </td>
                    <td className="activity-itemList">
                        {this.props.liste[i].serial}
                    </td>
                    <td className="activity-itemList">
                        {action}
                    </td>
                    <td className="activity-itemList">
                        {this.props.liste[i].username}
                    
                    </td>
                </tr>
            )
        }
        return(
        <>
            <div className="activity-top">
            Activité licence n°{this.props.licence}
            </div>
            <div className="activity-body">

            <table  cellSpacing={5} cellPadding={0} className="tableList">
            <thead>
            <tr >
                <td width='15%' className="activity-headerList">Date</td>
                <td width='15%'className="activity-headerList">IP</td>
                <td width='15%'className="activity-headerList">Serial</td>
                <td width='45%'className="activity-headerList">Action</td>
                <td width='10%'className="activity-headerList">User</td>

            </tr>
            </thead>
                

            <tbody>
                {liste}
            </tbody>

            </table>

            </div>
            <div className="activity-bottom">
            <span data-tip="Masquer l'activité">
            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/> 
            <Bouton 
                    width="32" height="32" 
                    image={back} 
                    onButtonClick={this.props.onclose}
                    buttonName="back"/>
                </span>
            </div>
        
        </>
        )
    }

}
export default ListeActivity
