import React from "react";
import PageTitle from "../gadgets/pagetitle/pagetitle"


import logo from '../home/assets/pluginLOGO.png'

import cappellaLogoSmalll from "./assets/cappellaLogoSmalll.png"


import {config, getUserData}  from '../config'
import ReactToolTip from 'react-tooltip'

import OneSoft from "./onesoft";


import '../users/styles/styles.css';

import "./styles/styles.css"







class Plugin extends React.Component
{

constructor(props)
{
    super(props)


    this.state = {
        indexSoftwareNew : -1,
        indexSoftwareStable : -1,
        indexDoc : -1,
        indexComp : -1,
        liste:[],
    }

}


componentDidMount()
{

    this.getDownload()

}

componentDidUpdate()
{
    
}


getDownload()
{
    var datas = {};
    datas.typesoftware = '1';
    console.log("Search upload with ", datas)
    var myHeaders = new Headers();

    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
               headers: myHeaders,
               mode: 'cors',
               cache: 'default',
               body: JSON.stringify(datas)
                };

    var apiurl = config[process.env.NODE_ENV].api_url + "download"
    console.log(apiurl);
    console.log(datas);
    fetch(apiurl,myInit)
    .then((response) => {
        if (!response.ok) 
        {
            throw new Error(`This is an HTTP error: The status is ${response.status}`  );
        }
  
    return response.json();
    })
    .then((actualData) => {

        console.log("Actual datas:", actualData, actualData.datas.length)

        var nbrows = actualData.datas.length;


        var indexSoftwareNew = -1;
        var indexSoftwareStable = -1;
        var indexDoc = -1;
        var indexComp = -1;


        var liste = actualData.datas;

        for (var i=0; i < nbrows ; i++)
        {

            if (liste[i].status == 1) // ' its new
            {
                if (liste[i].typefile == 0) indexSoftwareNew = i;
                if (liste[i].typefile == 1) indexDoc = i;
                if (liste[i].typefile == 2) indexComp = i;
            }
            if (liste[i].status == 2) // ' its stable 
            {
                if (liste[i].typefile == 0) indexSoftwareStable = i;
            }

        }
        
        this.setState(
            {
                liste: liste,
                indexSoftwareNew: indexSoftwareNew,
                indexSoftwareStable: indexSoftwareStable,
                indexDoc : indexDoc,
                indexComp : indexComp,
            }
        )

    
    })
    .catch((err) => {
    this.setState({errorMsg:"Mot de passe ou email inconnu."})
    console.log(err);
    });

}


renderVersionNew()
{

    if (this.state.indexSoftwareNew == -1)
    {
        return (null);
    }

    return (
        <div className="div-cappella-onedownload">
            <OneSoft key="software" datas ={this.state.liste[this.state.indexSoftwareNew]}/>
        </div>
    )

}

renderVersionStable()
{

    if (this.state.indexSoftwareStable == -1)
    {
        return (null);
    }

    return (
        <div className="div-cappella-onedownload">
            <OneSoft key="stable" datas ={this.state.liste[this.state.indexSoftwareStable]}/>
        </div>
    )

}

renderDoc()
{

    if (this.state.indexDoc == -1)
    {
        return (null);
    }

    return (
        <div className="div-cappella-onedownload">
            <OneSoft key="doc" datas ={this.state.liste[this.state.indexDoc]}/>
        </div>
    )

}

renderComp()
{

    if (this.state.indexComp == -1)
    {
        return (null);
    }

    return (
        <div className="div-cappella-onedownload">
            <OneSoft key="comp" datas ={this.state.liste[this.state.indexComp]}/>
        </div>
    )

}



render()
{

    return (
        <div className="div-container">
            <div className="div-entete">
                <div className="div-titre">
                    <PageTitle image={cappellaLogoSmalll} title="Cappella Plugin Voice Over Téléchargement"  {...this.props}/>
                </div>
            </div>
            <div className="div-bodylicences">

                <div className="div-cappella-logo">
                    <img src={logo} width="100%"/>
                </div>
                <div className="div-cappella-download">
                    {this.renderVersionNew()}
                    {this.renderVersionStable()}
                    {this.renderDoc()}
                    {this.renderComp()}
                </div>
                
            </div>
    
    
        </div>
        
        )
    

}

}


export default Plugin