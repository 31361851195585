import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { setUserData, getUserData } from './config';
import {config}  from './config'



//var datas = { 
//  user: 
//    {id: 19,firstname:"Riquart", lastname:"Dominique", email:"driquart@hotmail.fr",image:null, priv:1, phone:"0760340202"},
//}
//setUserData(datas)


const root = ReactDOM.createRoot(document.getElementById('root'));

/*
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
root.render(
    <App />
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
