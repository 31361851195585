import React from "react";


import logo from './assets/cappellaLOGO.png'
import vsi from './assets/vsi-paris-logo.png'
import imgUser from "../users/assets/simpleuser.png"
import imgEnter from "./assets/enter.png"
import imgDeconnec from "./assets/deconnect.png"
import {config}  from '../config.js'
import ReactToolTip from 'react-tooltip'

import Bouton from "../gadgets/bouton/bouton";


import HomeDownload from "./homedownload.js";
import { ReactSession } from 'react-client-session';

import { setUserData, getUserData } from '../config.js';

import './styles/styles.css';



class Home extends React.Component {
  showSettings (event) 
  {
    event.preventDefault();
  }

  constructor(props)
  {
    super(props)

    ReactSession.setStoreType("localStorage");


      var username = ReactSession.get("username")
      var memoid = ReactSession.get("memoid")



      console.log("username ",username)
      console.log("memoid ",memoid)

      if((!memoid) || (memoid==false))
      {
          memoid = false;
          username = "";
      }
      else
      {
          memoid=true;
          if (!username)
            username="";
      }

      var userData = getUserData()

      var id=0;
      try
      {
          id = userData.user.id
      }
      catch(e)
      {
        id=0;
      }

      this.state ={
          email:username,
          mdp:"",
          errorMsg: "",
          memoid:memoid,
          id: id,
      }

      

      this.onvalide = this.onvalide.bind(this)
      this.ondeconnect = this.ondeconnect.bind(this)
      this.formSubmit = this.formSubmit.bind(this)
      this.onkeyPress = this.onkeyPress.bind(this)

      this.mdpInput = React.createRef()
      this.memoid = React.createRef()
      this.emailInput = React.createRef()

     
  }

  renderUsers()
  {


    //return (this.renderConnect());

      if(this.state.id == 0)
        return (this.renderConnect())
      return (this.renderUnconnect())

  }

  onkeyPress(e)
  {
    
    if ((e.code == "Enter") || (e.code == "NumpadEnter"))
    {
      e.preventDefault()
      this.formSubmit()
    }
  }
  formSubmit()
  {
    this.setState({errorMsg:""})
    if (this.state.email.trim()=="")  { this.emailInput.current.focus();
        this.setState({errorMsg:"Zone obligatoire."})
            return;}
    /*
    if (this.state.mdp.trim()=="")  { this.mdpInput.current.focus();
        this.setState({errorMsg:"Zone obligatoire."})
        return;}
    */

    var datas = {
            email: this.state.email,
            pwd: this.state.mdp,
            }
    var myHeaders = new Headers();

    myHeaders.append('Accept','application/json');
    myHeaders.append('Content-Type','application/json');
    var myInit = { method: 'POST',
                   headers: myHeaders,
                   mode: 'cors',
                   cache: 'default',
                   body: JSON.stringify(datas)
                    };

    var apiurl = config[process.env.NODE_ENV].api_url + "login"
    console.log(apiurl);
    console.log(datas);
    fetch(apiurl,myInit)
    .then((response) => {
      if (!response.ok) {
        throw new Error(
          `This is an HTTP error: The status is ${response.status}`
        );
      }
      
      return response.json();
    })
    .then((actualData) => {

      this.setState({errorMsg:""})
        console.log("Actual datas:", actualData)
        if (actualData.datas.length != 0)
        {
          ReactSession.set("username",this.state.email)
          ReactSession.set("memoid",this.state.memoid)

          var userData = { 
            user: 
              {id: actualData.datas.id,
                firstname: actualData.datas.firstname, 
                lastname:actualData.datas.lastname, 
                email:this.state.email,
                image:actualData.datas.image, 
                priv:actualData.datas.privileges, 
                phone:actualData.datas.phone,
                userLicence: actualData.datas.userLicence,
                status: actualData.datas.status,
                licence: actualData.datas.licence,
                etudiant: actualData.datas.etudiant,
                datefin: actualData.datas.datefin,
                version: actualData.datas.version,
                tauqueune: actualData.datas.token,

              
              
              },
          }
        



          if (actualData.datas.userLicence == true) userData.user.priv = 16;

          console.log("userData ", userData)

          setUserData(userData)
          this.setState({id:actualData.datas.id})

          if (this.props.navigate)
          {

              var priv = userData.user.priv;
              if (priv & 1)
                this.props.navigate ('/upload')
              else
                  if (priv & 4)
                      this.props.navigate ('/licences')
                  else
                      if (priv & 8)
                          this.props.navigate ('/upload')
                      else
                        if (priv & 2)
                            this.props.navigate ('/emails')
                        else
                            if (priv & 16)
                                this.props.navigate ('/cappella')
              

          }

        }
        
      //user: 
      //  {id: 0,firstname:"", lastname:"", email:"",image:null, priv:0},
        
        
    })
    .catch((err) => {
      this.setState({errorMsg:"Mot de passe ou email inconnu."})
      console.log(err);
    });

}

  onvalide(event)
  {
      if (event.detail.value)
        this.formSubmit()
  }


  ondeconnect(event)
  {
    if (event.detail.value)
    {

        var userData = { 
          user: 
            {id: 0,firstname:"", lastname:"", email:"",image:null, priv:0, phone:"", tauqueune: ""},
        }
        setUserData(userData)

        this.setState({id:0, mdp:""})
        


    }
  }
  renderUnconnect()
  {
       return (
            <>
            <div className="div-logout">
            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
            <div data-tip="Se déconnecter" >
                <Bouton image={imgDeconnec} width={128} height={128} onButtonClick={this.ondeconnect}/>
              </div>
              <ReactToolTip/>
            </div> 
            </>
       )
  }



  renderConnect()
  {
       return (


            <>
            <div className="div-login">
            <form className="formLogin" >
            
            <span className="formUser-titre">
            {"Connectez-vous !"}
            <img  className="formUser-titre-image" src={imgUser} width="20%" />
            </span>


            <label className="formUser-label">
                    Adresse e-mail
                    <input className="formUser-input"   
                    name="email"
                    type="text"
                    autoComplete="new-password"
                    onKeyPress={this.onkeyPress}
                    ref={this.emailInput}
                    onChange={e => this.setState({email:e.target.value})}
                    value={this.state.email}
                    required />
              </label>

              
                    <label className="formUser-label">
                    <input 
                    name="memoid"
                    type="checkbox"
                    
                    ref={this.memoidInput}
                    onChange={e => this.setState({memoid:e.target.checked})}
                    
                    
                    checked={this.state.memoid}
                    required />
                    <span style={{fontSize:"13px"}}>
                    mémoriser mon identifiant
                    </span>
                    
                    
                    </label>


              <label className="formUser-label">
                    Mot de passe ou N° de licence
                    <input className="formUser-input"
                    name="mdp"
                    type="password"
                    ref={this.mdpInput}
                    onChange={e => this.setState({mdp:e.target.value})}
                    onKeyPress={this.onkeyPress}
                    value={this.state.mdp}
                    required />
                </label>
              
              <label className="formUser-label-red">
                {this.state.errorMsg}
              </label>
              <label className="formUser-label-red">
              </label>
              <div>
              
              <div data-tip="Se connecter"  className="div-login-button">
                <Bouton image={imgEnter} width={72} height={72} onButtonClick={this.onvalide}/>
              </div>
              <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
              </div>
              
            </form>
            </div> 
            </>

       )
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
        return (
          <>
            <div className="imagelogo">
            <img  src={logo} alt="cappellaHD" style={{width:"100%"}}/>
            </div>
            
            <div className="home-zonecappella">
              <HomeDownload software="cappella" user={this.state.id} navigate={this.props.navigate}/>
            </div>

            <div className="home-zoneplugin">
            <HomeDownload software="plugin" user={this.state.id} navigate={this.props.navigate}/>
            </div>

            <div className="imagevsi">
            <img  src={vsi} alt="vsi" style={{width:"100%"}}/>
            </div>
            {this.renderUsers()}
          </>

            )
  }

}
export default Home
