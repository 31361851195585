import React from "react";
import { scaleRotate as Menu } from 'react-burger-menu'

import logo from './assets/cappellaLogoSmalll.png'

// images menu
import imagetriangleRight from "./assets/triangle-right.png"
import imagetriangleDown from "./assets/triangle-down.png"
import imageConfiguration from "./assets/configuration.png"
import imageTelechargement from "./assets/telechargement.png"
import imageAdmin from "./assets/admin.png"
import imageHome from "./assets/home.png"

import imageRond from "./assets/rond-rond.png"
import imageRondDisable from "./assets/rond-rond-disable.png"

import { setUserData, getUserData } from './config';

class OneOptionMenu extends React.Component
{
  constructor (props)
  {
    super(props);
    this.state = {mouseover: false,
    }
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onMouseClick = this.onMouseClick.bind(this)
  }

  render ()
  {


    if (!this.props.enable) return false;
    var classname = "submenu-menu"
        if (this.state.mouseover)
          classname = "submenu-menu-over"
    return (
    
    <div className={classname} 
        onMouseLeave={this.onMouseLeave}
        onMouseEnter={this.onMouseEnter}
        onClick={this.onMouseClick}
      
    >

    <img className="header-menu-picture" 
        
      src={imageRond} width={20} height={20}/>&nbsp;{this.props.menutext}
    </div>
    );

  }

  onMouseEnter()
  { 
      this.setState({mouseover: true});
  }
  onMouseLeave()
  {
    this.setState({mouseover: false});
    
  }

  onMouseClick()
  {
      this.props.onClick(this.props.menucmd)
  }

}

class ListOptionMenu extends React.Component
{

    constructor (props)
    {
      super(props);

     }

    render()
    {


      if (this.props.nosubmenu) return (null);
      if (!this.props.isopen) return (null);
      if (!this.props.enable) return (null);
      
      var retour=[];
      for (var i=0; i<this.props.submenulist.length; i++)
      {
        retour.push(
          
          <OneOptionMenu  enable={this.props.submenulist[i].enable} onClick={this.props.onClick} menutext={this.props.submenulist[i].name} menucmd={this.props.submenulist[i].cmd} key={this.props.submenulist[i].cmd}/>
          );
      }

      return ( retour)

      

      

    }

}


class HeaderOptionMenu extends React.Component
{
    constructor(props)
    {
      super(props)
      this.state = {mouseover: false,
                  }


      this.onMouseEnter = this.onMouseEnter.bind(this)
      this.onMouseLeave = this.onMouseLeave.bind(this)

    }

    onMouseEnter()
    { 
        this.setState({mouseover: true});
    }
    onMouseLeave()
    {
      this.setState({mouseover: false});
      
    }

    TriangleHeader() {

      if (!this.props.enable)
      return (
        <img className="header-menu-picture" src={imageRondDisable} width={20} height={20}/>
        )


      if (this.props.nosubmenu) 
        return (
        <img className="header-menu-picture" 
        onMouseLeave={this.onMouseLeave} onMouseEnter={this.onMouseEnter}
          src={imageRond} width={20} height={20}/>
        )

      if (!this.props.isopen)
      return (
        <img className="header-menu-picture" onMouseLeave={this.onMouseLeave} onMouseEnter={this.onMouseEnter} onClick={this.props.onTriangle} src={imagetriangleRight} width={20} height={20}/>
      )
      else
      return (
        <img className="header-menu-picture" onMouseLeave={this.onMouseLeave} onMouseEnter={this.onMouseEnter} onClick={this.props.onTriangle} src={imagetriangleDown} width={20} height={20}/>
      )

    }


    TextHeader()
    {

        var classname = "header-menu-disable";
        if (this.props.enable) 
        {
          classname = "header-menu"
          if (this.state.mouseover) classname = "header-menu-over"
        }

        if (!this.props.enable)
            return (<span className={classname}>{this.props.caption}</span>);
        else
        {

           if (this.props.nosubmenu)
              return (<span className={classname} 
                onMouseLeave={this.onMouseLeave}
                onMouseEnter={this.onMouseEnter}
                onClick={this.props.onClick}>{this.props.caption}</span>);
           else
            return  (<span className={classname} 
              onMouseLeave={this.onMouseLeave}
              onMouseEnter={this.onMouseEnter}
            onClick={this.props.onTriangle}>{this.props.caption}</span>);
        }

    }


    render()
    {
      var iconWidth=32;
      var styledisable = "header-menu-picture-disable";
      if (this.props.enable)
          styledisable = "header-menu-picture";
      return (
        <div className="header-menu">
          <p></p>
          <img className={styledisable} src={this.props.image} width={iconWidth} height={iconWidth}/>
          &nbsp;&nbsp;{this.TriangleHeader()}&nbsp;
          {this.TextHeader()}
        </div>
      )
    }

}





//******************************************************************
// Menu parameters
//******************************************************************

class MenuConfiguration extends React.Component
{
    constructor(props)
    {
      super(props)
      this.state = { 
                    
                     menuOpen: false};

    this.handleClickTriangle = this.handleClickTriangle.bind(this)        
    this.handleClickHead = this.handleClickHead.bind(this)

    }

    render()
    {   

      
      if (!this.props.enable)
      {
          return (null) ;
      }



        var submenulist = 
        [
          {name:"Licences", cmd: "licences", enable : this.props.licences},
          {name:"Fichiers", cmd:"upload",enable : this.props.upload},
          {name:"Emails", cmd:"emails", enable : this.props.pages},
          {name:"Cron", cmd:"cron", enable : false},

        ]
        return(

            <div>
            <HeaderOptionMenu  onTriangle={this.handleClickTriangle} enable={this.props.enable} nosubmenu={false}  image={imageConfiguration} caption="Configuration" isopen={this.state.menuOpen}/>
            <ListOptionMenu onClick={this.props.onClick} enable={this.props.enable} submenulist = {submenulist} nosubmenu={false} isopen={this.state.menuOpen}/>
            <p></p>
          </div>
        )
    }

    handleClickTriangle(event)
    {
        event.stopPropagation()
        this.setState({menuOpen: !this.state.menuOpen});
    }
    handleClickHead()
    {

    }

}


//******************************************************************
// Menu Telechargement
//******************************************************************
 


class MenuTelechargement extends React.Component
{
    constructor(props)
    {
      super(props)
      this.state = { 
                    
                     menuOpen: false};

    this.handleClickTriangle = this.handleClickTriangle.bind(this)        
    this.handleClickHead = this.handleClickHead.bind(this)

    }

    render()
    {   
        
        var submenulist = 
        [
          {name:"Cappella HD...", cmd:"downloadcappella", enable: this.props.cappella},
          {name:"Cappella Word Plugin...", cmd:"downloadplugin", enable: this.props.plugin},

        ]
        return(

            <div>
            <HeaderOptionMenu  onTriangle={this.handleClickTriangle} enable={this.props.enable} nosubmenu={false} image={imageTelechargement} caption="Téléchargements" isopen={this.state.menuOpen}/>
            <ListOptionMenu onClick={this.props.onClick} enable={this.props.enable}  submenulist = {submenulist} nosubmenu={false} isopen={this.state.menuOpen}/>
            <p></p>
          </div>
        )
    }

    handleClickTriangle(event)
    {
        event.stopPropagation()
        this.setState({menuOpen: !this.state.menuOpen});
    }
    handleClickHead()
    {

    }

}





//******************************************************************
// Menu Admin
//******************************************************************
 


class MenuAdmin extends React.Component
{
    constructor(props)
    {
      super(props)
      this.state = { 
                    
                     menuOpen: false};

    this.handleClickTriangle = this.handleClickTriangle.bind(this)        
    this.handleClickHead = this.handleClickHead.bind(this)

    }

    render()
    {   
        
      if (!this.props.enable)
      {
          return (null) ;
      }


      var submenulist = 
        [
          {name:"Utilisateurs...", cmd:"UserPage", enable:true},
          {name:"Activité...", cmd:"ActivityPage", enable:true},
          {name:"pgadmin4...", cmd:"pgadmin4", enable:true},

        ]
        return(

            <div>
            <HeaderOptionMenu  onTriangle={this.handleClickTriangle} enable={this.props.enable} nosubmenu={false} image={imageAdmin} caption="Administration" isopen={this.state.menuOpen}/>
            <ListOptionMenu onClick={this.props.onClick} enable={this.props.enable} submenulist = {submenulist} nosubmenu={false} isopen={this.state.menuOpen}/>
            <p></p>
          </div>
        )
    }

    handleClickTriangle(event)
    {
        event.stopPropagation()
        this.setState({menuOpen: !this.state.menuOpen});
    }
    handleClickHead()
    {

    }

}


//******************************************************************
// Menu home accueil
//******************************************************************
 


class MenuHome extends React.Component
{
    constructor(props)
    {
      super(props)
      this.state = { 
                    
                     menuOpen: false};

    this.handleClickTriangle = this.handleClickTriangle.bind(this)        
    this.handleClickHead = this.handleClickHead.bind(this)

    }

    render()
    {   
        
        return(

            <div>
            <HeaderOptionMenu  onClick={this.handleClickHead} enable={this.props.enable} nosubmenu={true}  image={imageHome} caption="Accueil" isopen={this.state.menuOpen}/>
            <p></p>
          </div>
        )
    }

    handleClickTriangle(event)
    {
        this.setState({menuOpen: !this.state.menuOpen});
    }
    handleClickHead()
    {
        this.props.onClick();
    }
    handleClickItem()
    {

    }

}


//******************************************************************

class SideBar extends React.Component {


    constructor(props)
    {
        super(props)
        this.state = { menuOpen: false,
        };


        this.clickOnMenu = this.clickOnMenu.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.handleClickAbout = this.handleClickAbout.bind(this);
        this.handleClickHome = this.handleClickHome.bind(this);
        this.handleClickSubMenu = this.handleClickSubMenu.bind(this);

    }

    handleClickAbout() 
    {
        this.props.navigate("/about")
        this.setState({menuOpen:false});
    }


    handleClickHome() 
    {
        this.props.navigate("/")
        this.setState({menuOpen:false});
    }


    handleClickSubMenu(page)
    {
        switch (page)
        {

            case "pgadmin4":
              window.location.href = 'http://91.134.90.108:3000/pgadmin4/';
              break;

            case "UserPage":
              this.props.navigate("/users")
              break;

            case "ActivityPage":
              this.props.navigate("/activity")
              break;

            case "licences":
                this.props.navigate("/licences")
                break;
    
            case "upload":
                this.props.navigate("/upload")
                break;

            case "emails":
                this.props.navigate("/emails")
                break;

            case "textes":
              this.props.navigate("/textes")
              break;
    
            case "downloadcappella":
              this.props.navigate("/cappella")
              break;

            case "downloadplugin":
              this.props.navigate("/plugin")
              break;
  
            default:
                this.props.navigate("/")
                break;
  
    

  
        }


        this.setState({menuOpen:false});
    }

  render () {


        var userData = getUserData()

        
        var priv = userData.user.priv;
        var enabledHome = true; // retour home


        // 1 = Administrateur
        // 2 = Configuration
        // 4 = Licences
        // 8 = Upload


        var enabledTelechargement = true;
        var enabledPlugin = true;
        var enabledCappella =  (priv  & 1) || (priv & 2) || (priv & 4) || (priv & 8) || (priv & 16)
        
        
        var enabledConfigution = (priv & 1) || (priv & 2) || (priv & 4)

        var enabledPage = (priv & 1) || (priv & 2);
        var enabledLicences = (priv & 1) || (priv & 4);

        var enabledUpload = (priv & 1) || (priv & 8)

        var enabledAdmin = (priv & 1) != 0;
        return (
            
            <>
            
            <Menu  {...this.props} 
            isOpen={this.state.menuOpen} onStateChange={this.onStateChange}>


              <img className="logo-menu" src={logo} />
              <br></br>
              <br></br>

              <MenuHome enable={enabledHome} 
                onClick={this.handleClickHome}
                 />

              <MenuTelechargement enable = {enabledTelechargement} cappella={enabledCappella} plugin={enabledPlugin}
                onClick={this.handleClickSubMenu}/>



              <MenuConfiguration enable={enabledConfigution} 
                licences = {enabledLicences} 
                pages={enabledPage}
                upload={enabledUpload}
                onClick={this.handleClickSubMenu} />

              <MenuAdmin enable={enabledAdmin}
              onClick={this.handleClickSubMenu}/>

            </Menu>
            </>
            );
  }


  

  onStateChange(state)
  {
    this.setState({menuOpen:state.isOpen})
  }
  clickOnMenu()
  {
        this.setState({menuOpen:false});
  }
}

export default SideBar