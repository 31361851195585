// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.standard-button {
  position: relative;

    
  }
  
.liste-button{
  padding-left: 6px;
  padding-top: 5px;
  
}


.player-button{
  padding-left: 3px;
  padding-top: 5px;
  
}

  `, "",{"version":3,"sources":["webpack://./src/gadgets/bouton/styles.css"],"names":[],"mappings":";;AAEA;EACE,kBAAkB;;;EAGlB;;AAEF;EACE,iBAAiB;EACjB,gBAAgB;;AAElB;;;AAGA;EACE,iBAAiB;EACjB,gBAAgB;;AAElB","sourcesContent":["\n\n.standard-button {\n  position: relative;\n\n    \n  }\n  \n.liste-button{\n  padding-left: 6px;\n  padding-top: 5px;\n  \n}\n\n\n.player-button{\n  padding-left: 3px;\n  padding-top: 5px;\n  \n}\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
