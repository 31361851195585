import React from "react";
import { 
    BtnBold,
    BtnItalic,
    BtnUndo,
    BtnRedo,
    BtnUnderline,
    BtnStyles,
    Editor,
    EditorProvider,
    Toolbar
  } from 'react-simple-wysiwyg';


import {config, getUserData,getListTypeMail}  from '../config'
import ReactToolTip from 'react-tooltip'



import Bouton from "../gadgets/bouton/bouton"

import TestMail from "./testmail";

import imgback from "../licences/assets/back.png"
import imgvalide from "../licences/assets/valider.png"
import imgquit from "./assets/imgquit.png"

import '../users/styles/styles.css';
import './styles/styles.css';




class EditMail extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state = {
            lastread: -1,
            text: "",
            lasttext: "",
            emailtest:"",
        }
        this.onChange = this.onChange.bind(this)
        this.undoEdit = this.undoEdit.bind(this)
        this.validEdit = this.validEdit.bind(this)
        this.onsend = this.onsend.bind(this)

    }        



    componentDidMount()
    {
        //editindex, editlib, editid;


        if ((this.props.id >=10) && (this.state.lastread != this.props.id) && (!this.props.disable))
        {

            this.readMail()
        }

    }

    componentDidUpdate()
    {
        if ((this.props.id >=10) && (this.state.lastread != this.props.id) && (!this.props.disable))
        {

            this.readMail()
        }

    }

    readMail()
    {
        var myHeaders = new Headers();
    
        var myInit = { method: 'GET',
                        headers: myHeaders,
                        mode: 'cors',
                        cache: 'default' };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/"+this.props.id
        fetch(apiurl,myInit)
        .then((response) => {
            if (!response.ok) {
            throw new Error(
                `This is an HTTP error: The status is ${response.status}`
            );
            }
            
            return response.json();
        })
        .then((actualData) => {
    
            console.log("Get Param Email 2: ",actualData)


            if (actualData.datas.length > 0)
            {
            
                this.setState(
                    {
                    text: actualData.datas[0].charparam,
                    lasttext: actualData.datas[0].charparam,
                    lastread: this.props.id,

                    })
            }
            else
            {
                this.setState(
                    {
                    text: "",
                    lasttext: "",
                    lastread: this.props.id,

                    })

            }
                
            
        })
        .catch((err) => {
            console.log(err);
        });


    }

    validEdit(event)
    {

        if (event.detail.value)
        {
            


            var intparam=0;

            var datas =
            {
                id:this.props.editid, //
                intparam: intparam,
                charparam: this.state.text
            }
    
            var myHeaders = new Headers();
            myHeaders.append('Accept','application/json');
            myHeaders.append('Content-Type','application/json');
            var myInit = { method: 'PUT',
                            headers: myHeaders,
                            mode: 'cors',
                            cache: 'default',
                            body: JSON.stringify(datas)
                            };
      
            var apiurl = config[process.env.NODE_ENV].api_url + "mailparams"+"/"+this.props.id
            fetch(apiurl,myInit)
            .then((response) => {
                if (!response.ok) {
                throw new Error(
                    `This is an HTTP error: The status is ${response.status}`
                );
                }
                
                return response.json();
            })
            .then((actualData) => {
      
                try{
                    this.props.oncancel(event)
                    
                }
                catch(e)
                {
                    console.log(e)
                }
    
            })
            .catch((err) => {
                console.log(err);
            });
      
    






        }


    }

    undoEdit(event)
    {
        if (event.detail.value)
        {
            this.setState({text:this.state.lasttext});
        }
    }


    onsend(datas)
    {

        var datasemail={
            id: 0, // le texte est dans les datas
            text: this.state.text,
            dest: datas.email,
            licence: 299999,
            nom: "Célère",
            prenom: "Jacques",
            datefin: "31/12/2024",
            status: 0,
            etudiant: 0,
            email: "jacques-celere@gmail.com",
        }

        

        var myHeaders = new Headers();
        myHeaders.append('Accept','application/json');
        myHeaders.append('Content-Type','application/json');
        var myInit = { method: 'POST',
                       headers: myHeaders,
                       mode: 'cors',
                       cache: 'default',
                       body: JSON.stringify(datasemail)
                        };
    
        var apiurl = config[process.env.NODE_ENV].api_url + "sendmail"
        fetch(apiurl,myInit)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `This is an HTTP error: The status is ${response.status}`
            );
          }
          
          return response.json();
        })
        .then((actualData) => {
    
            
        })
        .catch((err) => {
          console.log(err);
        });





    }


    onChange(e)
    {
        this.setState({text: e.target.value})
    }

    

    renderEditor()
    {
        if (this.props.disable)
            return (null)
        else
            return(
                
                    <EditorProvider>
                    <Editor 
                        value={this.state.text} 
                        onChange={this.onChange}
                        
                    >
                    <Toolbar>
                    <BtnBold />
                    <BtnItalic />
    
                    <BtnUndo/>
                    <BtnRedo/>
                    <BtnUnderline/>
                    <BtnStyles/>
    
                    </Toolbar>
                    </Editor>
                </EditorProvider>
    
                )
    }



    render ()
    {




        if (this.props.disable)
            return (<>
            <div className="editmail-all-center">
                Sélectionner un email au dessus pour le modifier.
            </div>    
            </>)


        return(
        <div className="editmail-all">
            <div className="editmail-body">
                {this.renderEditor()}
            </div>
            <div className="editmail-right">
            <ReactToolTip delayShow={250} border backgroundColor='#8b75a1' place='bottom'/>
                    <span data-tip="Quitter l'éditeur">
                    <Bouton 
                            width="40" height="40" top="0"
                            image={imgquit} 
                            disable= {false}
                            key = {"abort"}
                            onButtonClick={this.props.oncancel}
                            buttonName="bAbort"/>
                    </span>
                    &nbsp;&nbsp;
                    <span data-tip="Annuler les modifications">
                    <Bouton 
                            width="40" height="40" top="0"
                            image={imgback} 
                            disable= {false}
                            key = {"undo"}
                            onButtonClick={this.undoEdit}
                            buttonName="bUndo"/>
                    </span>
                    &nbsp;&nbsp;
                    <span data-tip="Valider le texte du mail">

                    <Bouton 
                            width="40" height="40" top="0"
                            image={imgvalide} 
                            disable= {false}
                            key = {"valide"}
                            onButtonClick={this.validEdit}
                            buttonName="bValid"/>
                    </span>
                    <br/>
                    <table width="100%">
                            <tr><td>&nbsp;</td></tr>
                            <tr><td>&nbsp;</td></tr>
                            <tr><td className="tdA">[licence]</td><td className="tdB">N° Licence</td></tr>
                            <tr><td className="tdA">[nom]</td><td className="tdB">Nom</td></tr>
                            <tr><td className="tdA">[prenom]</td><td className="tdB">Prénom</td></tr>
                            <tr><td className="tdA">[datefin]</td><td className="tdB">Date de fin</td></tr>
                            <tr><td className="tdA">[email]</td><td className="tdB">Mail de l'auteur</td></tr>
                            <tr><td className="tdA">[status]</td><td className="tdB">Active/Inactive</td></tr>
                            <tr><td className="tdA">[type]</td><td className="tdB">Normal/Etudiant</td></tr>
                            <tr><td className="tdA">[version]</td><td className="tdB">Version Cappella</td></tr>
                        
                    </table>

                
            </div>
            <div className="editmail-bottom">
                <TestMail
                    onsend = {this.onsend}

                />
            </div>



            
        </div>
        )
    }

}

export default EditMail
