import React from "react";

import {config, getUserData}  from '../config'
import ReactToolTip from 'react-tooltip'

import ListTypeMail from "./listtypemail";
import ImageMail from "./imagemail";
import EditMail from "./editmail";

import '../users/styles/styles.css';
import './styles/styles.css';


class MailsEditor extends React.Component
{

    constructor(props)
    {
        super(props)
        this.state =
        {
            disable: false,
            editindex: -1,
            editid: -1,
            editlib: "",

        }
        this.onEditMail = this.onEditMail.bind(this);
        this.oncancel = this.oncancel.bind(this)
    }

    oncancel(event)
    {
        if (event.detail.value)
        {
            this.setState(
                {
                    disable: false,
                    editindex: -1,
                    editid: -1,
                    editlib: "",
            
                })
        }

    }

    onEditMail(event)
    {

        //datas={{id:this.state.liste[i].id, index:i,lib:this.state.liste[i].lib}}
        if (event.detail.value)
        {
            
            console.log(event.detail.datas)
            this.setState(
            {
                editindex: event.detail.datas.index,
                editlib: event.detail.datas.lib,
                editid: event.detail.datas.id,
                disable:true,

            }
            )
        }



    }


    render ()
    {
        return(
        <>
        <div className="mailseditor_top">
            <ListTypeMail 
            editindex = {this.state.editindex}
            disable={this.state.disable} 
            onedit={this.onEditMail} />
        </div>
        <div className="mailseditor_center">
            <EditMail
                onvalid ={this.onvalid}
                oncancel={this.oncancel}
                disable = {!this.state.disable}
                id ={this.state.editid}
                lib = {this.state.editlib}
                index = {this.state.editindex}
            />



        </div>
        <div className="mailseditor_bottom_left">
            <ImageMail id="2" titre="Image entête envoyée dans les emails"/>
        </div>
        <div className="mailseditor_bottom_right">
            <ImageMail id="3" titre="Image signature envoyée dans les emails"/>
        </div>
            


        </>
        )
    }

}

export default MailsEditor
